import React from "react";
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, Legend, YAxis } from "recharts";
import styled from "styled-components";
import colors from "../../common/colors";
import renderLegend from "./Legend";

const Wrapper = styled.div`
  width: 100%;
  height: 150px;
  .recharts-cartesian-axis-line,
  .recharts-cartesian-axis-tick-line {
    stroke: ${colors.gray600};
  }
  .recharts-cartesian-axis-tick-value {
    font-size: 14px;
    fill: ${colors.gray900};
  }
  .recharts-default-tooltip {
    font-size: 14px;
  }
  .recharts-tooltip-label {
    font-size: 14px;
  }
`;

function Chart(props) {
  const chartId = props.date && props.date.split(" ").join("-");
  return (
    <Wrapper style={{ width: "100%", height: 150 }}>
      <ResponsiveContainer>
        <ComposedChart width={500} height={60} data={props.data} aria-labelledby={chartId}>
          <title id={chartId}>Spitsverwachting voor: {props.date}</title>
          <XAxis dataKey="time" interval="preserveStartEnd" />
          <YAxis hide domain={[0, 100]} />
          <Legend content={renderLegend} />
          <Line
            type="linear"
            dataKey="average"
            dot={false}
            strokeWidth={6}
            stroke={colors.hemelsblauw}
          />
          <Bar dataKey="expected" barSize={6} fill={props.color} />
        </ComposedChart>
      </ResponsiveContainer>
    </Wrapper>
  );
}

export default Chart;
