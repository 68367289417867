import { useEffect, useRef, useState } from "react";
import { breakpoints } from "../common/utils";
import isEqual from "lodash.isequal";

// this wrapper compares dependencies in a better way.
// The original useEffect thinks that {} and {} are not the same and thus cause unexpected effects.
// line 21 is disabled from linting because `deps` is a variable.

export function useDeepEffect(fn, deps) {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

    if (isFirst.current || !isSame) {
      fn();
    }

    isFirst.current = false;
    prevDeps.current = deps;
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useMobileDetection() {
  const mq = window.matchMedia(`(min-width: ${breakpoints.get("desktop")}px)`);
  const [isMobile, setMobile] = useState(!mq.matches);

  useEffect(() => {
    const handleResize = () => {
      setMobile(!mq.matches);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mq.matches]);

  return isMobile;
}
