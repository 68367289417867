import React from "react";
import styled from "styled-components";
import colors from "../../common/colors";
import gaugeGray from "./images/trafficinfo-gray.svg";
import gaugeGreen from "./images/trafficinfo-green.svg";
import gaugeYellow from "./images/trafficinfo-yellow.svg";
import gaugeOrange from "./images/trafficinfo-orange.svg";
import gaugeRed from "./images/trafficinfo-red.svg";
import { format } from "date-fns";
import { nl } from "date-fns/locale/nl";

const Container = styled.div`
  display: flex;
  font-size: 20px;
  color: ${colors.logoblauw};
`;

const Date = styled.time`
  font-size: 13px;
  color: ${colors.gray700};
  margin-top: 4px;
  display: block;
`;

const Image = styled.img`
  margin-right: 13px;
`;

export const getGaugeSource = km => {
  if (km >= 350) {
    return gaugeRed;
  }
  if (km >= 250) {
    return gaugeOrange;
  }
  if (km >= 150) {
    return gaugeYellow;
  }
  if (km >= 3) {
    return gaugeGreen;
  }
  return gaugeGray;
};

function Gauge(props) {
  const { count, length, date } = props;

  return (
    <Container>
      <Image src={getGaugeSource(length)} alt="" />
      <div>
        {count === 0 ? (
          "Er zijn momenteel geen files."
        ) : (
          <>
            <strong>
              {count} file{count === 1 ? null : "s"}
            </strong>{" "}
            van totaal <strong>{length} km</strong>
          </>
        )}
        {date && (
          <Date dateTime={date.toISOString()}>
            {format(date, "d LLLL yyyy, HH:mm", { locale: nl })}
          </Date>
        )}
      </div>
    </Container>
  );
}

export default Gauge;
