const actionTypes = {
  LOAD: "cameras/load",
  LOAD_SUCCESS: "cameras/load_success",
  LOAD_FAILED: "cameras/load_failed",
};

const initialState = {
  loading: false,
  loaded: false,
  error: {},
  items: [],
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        error: {},
        items: action.payload,
        loading: false,
        loaded: true,
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export const loadCameras = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: "/cameras/",
});

export const getCameras = store => {
  const {
    cameras: { items, loading, loaded, error = {} },
  } = store;
  return {
    shouldLoad: !loading && !loaded && Object.keys(error).length === 0,
    items,
  };
};

export const getCamera = (store, id) => {
  const {
    cameras: { items, loading, loaded },
  } = store;

  const camera = items.find(x => x.id.toString() === id.toString());

  return {
    shouldLoad: !loading && !loaded && items.length === 0,
    loading,
    camera,
  };
};
