import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import LocationField from "../LocationField";
import Datepicker from "../Datepicker";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { removeFormFieldError } from "../Route/redux";
import { format } from "date-fns";
import { rewriteTargetToFrontendURL } from "./RewriteWidgetUrl";

export const makeSearchUrl = (fromObj, toObj, dateObj = new Date()) => {
  const from = encodeURIComponent(fromObj);
  const to = encodeURIComponent(toObj);
  const via = "_"; // Ibera API (where this called is proxied to) requires a 'via' value to always be present, hence why this _ value is hardcoded.
  const date = format(dateObj, "yyyy-MM-dd");
  return `/route/${from}/${to}/${via}/${date}`;
};

function RouteForm(props) {
  const dispatch = useDispatch();
  let { loading, error = {} } = useSelector(state => state.route);
  const [from, setFrom] = useState(props.from);
  const [to, setTo] = useState(props.to);
  const [fromError, setFromError] = useState(error.from);
  const [toError, setToError] = useState(error.to);
  const [date, setDate] = useState(props.date || new Date());
  const [submitting, toggleSubmitting] = useState(loading);

  const handleSubmit = evt => {
    evt.preventDefault();
    if (!from) {
      setFromError({ error: "Er is geen 'van' locatie ingevuld." });
    }
    if (!to) {
      setToError({ error: "Er is geen 'naar' locatie ingevuld." });
    }
    if (from && to) {
      const searchUrl = makeSearchUrl(from, to, date);
      if (props.target) {
        const targetUrl = `${props.target}${searchUrl}`;

        // Rewrite the route's target URL to use the frontend domain instead of the api domain.
        let rewrittenTargetURL = rewriteTargetToFrontendURL(targetUrl);

        if (props.newWindow) {
          window.open(rewrittenTargetURL);
        } else {
          window.location.href = rewrittenTargetURL;
        }
      } else {
        props.history.push(searchUrl);
      }
    }
  };

  useEffect(() => {
    if (from) {
      setFromError(error.from);
    }
    if (to) {
      setToError(error.to);
    }
  }, [from, to, error]);

  // the following three effects miss the `error.[field]` dependency
  // because the field error shouldn't be removed when the error changes,
  // ONLY when the value changes AND there is an error.
  // So eslint has been disabled for these three.
  useEffect(() => {
    if (from && error.from) {
      dispatch(removeFormFieldError("from"));
    }
    // eslint-disable-next-line
  }, [from, dispatch]);
  useEffect(() => {
    if (to && error.to) {
      dispatch(removeFormFieldError("to"));
    }
    // eslint-disable-next-line
  }, [to, dispatch]);
  useEffect(() => {
    toggleSubmitting(loading);
  }, [loading]);

  const switchFromTo = () => {
    setFrom(to);
    setTo(from);
  };

  return (
    <form onSubmit={handleSubmit}>
      <LocationField
        label="Van"
        showCurrentLocation={Boolean(navigator?.geolocation)}
        value={from}
        error={fromError}
        onChange={setFrom}
        submitting={submitting}
        onSwitch={switchFromTo}
      />
      <LocationField
        label="Naar"
        value={to}
        error={toError}
        onChange={setTo}
        submitting={submitting}
      />
      <Datepicker label="Datum" onChange={setDate} value={date} />
      <Button large type="submit" style={{ marginTop: "1.5rem" }} disabled={submitting}>
        {submitting ? "Bezig met zoeken..." : "Zoeken"}
      </Button>
    </form>
  );
}

export default withRouter(RouteForm);
