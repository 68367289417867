import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Results from "../components/Search/Results";
import { findRoute, getObstructionsFromSelectedRoute } from "../components/Route/redux";
import { setMapDisplay, getMapDisplay } from "../components/Map/redux";
import { useDeepEffect } from "../utils/hooks";
import isEqual from "lodash.isequal";
import { parseISO } from "date-fns";

function makeHash(str) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash.toString();
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
}

const getPayloadFromUrl = params => {
  const { from, to, date } = params;
  return {
    from: decodeURIComponent(from),
    to: decodeURIComponent(to),
    date: parseISO(date),
  };
};

function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Route(props) {
  const {
    location: { pathname },
    match: { params },
  } = props;

  const mapDisplay = useSelector(getMapDisplay);
  const dispatch = useDispatch();
  const [queryKey, setQueryKey] = useState(makeHash(pathname));
  const [payload, setPayload] = useState(getPayloadFromUrl(params));

  const searching = useSelector(state => state.route.loading);

  const prevPayload = usePrevious(payload);

  useDeepEffect(() => {
    if (!searching && !isEqual(prevPayload, payload)) {
      dispatch(findRoute(payload, queryKey));
    }
  }, [queryKey, dispatch, payload, searching]);

  useEffect(() => {
    if (mapDisplay !== "route") {
      dispatch(setMapDisplay("route"));
    }
  }, [mapDisplay, dispatch]);

  const obstructions = useSelector(store => getObstructionsFromSelectedRoute(store, queryKey));

  useEffect(() => {
    setQueryKey(makeHash(pathname));
  }, [pathname, setQueryKey]);

  useEffect(() => {
    setPayload(getPayloadFromUrl(params));
  }, [params, setPayload]);

  const resultsRef = useRef();

  useEffect(() => {
    if (resultsRef && resultsRef.current) {
      resultsRef.current.scrollIntoView();
    }
  }, [pathname]);

  return (
    <Results
      ref={resultsRef}
      {...props}
      payload={payload}
      obstructions={obstructions}
      title={`${payload.from} naar ${payload.to} ${
        Boolean(payload.via) ? `via ${payload.via}` : ""
      }`}
    />
  );
}

export default Route;
