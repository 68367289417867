import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import colors from "../common/colors";
import { RWSContext } from "../context";
import { breakpoints } from "../common/utils";
import "photoswipe/dist/photoswipe.css";

const Content = styled.div`
  color: ${colors.gray900};
  h2 {
    font-size: 24px;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  h3 {
    font-size: 20px;
    margin-bottom: 1rem;
  }
  figure {
    margin: 0;
    figcaption {
      font-size: 16px;
      line-height: 21px;
      font-style: italic;
      color: ${colors.gray700};
      padding-top: 1rem;
      padding-bottom: 1.5rem;
    }
  }
  img {
    width: 100%;
    height: auto;
    cursor: zoom-in;
  }
  .lead {
    font-size: 20px;
  }
  blockquote {
    font-size: 22px;
    line-height: 34px;
    border-left: 6px solid ${colors.geel};
    font-style: italic;
    padding-left: 20px;
    margin: 0;
  }
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
  }
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  a {
    color: ${colors.hemelsblauw};
    text-decoration: underline;
  }
  ol,
  ul {
    counter-reset: bullet;
    line-height: 26px;
    padding: 0 0 0 1rem;
    font-size: 18px;
    li {
      list-style: none;
      list-style-type: none !important;
      margin-left: 0;
      margin-bottom: 5px;
      position: relative;
      &::before {
        position: absolute;
        left: -1rem;
      }
    }
  }
  ol li::before {
    counter-increment: bullet;
    content: counter(bullet) ".";
  }
  ul li::before {
    content: "•";
    font-size: 25px;
  }
  p {
    font-size: 18px;
    line-height: 1.6;
  }
  div[data-oembed-url] {
    div {
      max-width: 100% !important; /* Override the inline max-width provided by the CKEditor */
    }
  }
  .responsive-object {
    position: relative;
  }
  .responsive-object iframe,
  .responsive-object object,
  .responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* fallback styling for twitter embeds, taken from: https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/css-for-embedded-tweets */
  blockquote.twitter-tweet {
    display: inline-block;
    font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    border-color: #eee #ddd #bbb;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    margin: 10px 5px;
    padding: 0 16px 16px 16px;
    max-width: 468px;
  }

  blockquote.twitter-tweet p {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }

  blockquote.twitter-tweet a {
    color: inherit;
    font-weight: normal;
    text-decoration: none;
    outline: 0 none;
  }

  blockquote.twitter-tweet a:hover,
  blockquote.twitter-tweet a:focus {
    text-decoration: underline;
  }

  .lightbox-enlarge-image {
    position: relative;
    display: block;
    width: 100%;
    max-width: 480px;
  }

  .lightbox-enlarge-image::after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23ffffff' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6' /%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: center;
    display: block;
    background-color: rgba(0, 0, 0, 0.3);
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: zoom-in;
  }

  .localfocusvisual {
    height: 500px;
  }

  @media (max-width: ${breakpoints.get("tablet")}px) {
    .localfocusvisual {
      height: 400px;
    }
  }

  @media (max-width: ${breakpoints.get("mobile")}px) {
    .localfocusvisual {
      height: 300px;
    }
  }
`;

async function initializePhotoSwipe() {
  // Everything runs perfectly fine when starting or building but somehow testing
  // won't work because 'photoswipe' only supports ES Modules, and CRA doesn't.
  // So this method won't be called when NODE_ENV is 'test'.
  // More info: https://github.com/facebook/create-react-app/issues/12063
  if (process.env.NODE_ENV === "test") {
    return;
  }
  const { default: Lightbox } = await import("photoswipe/lightbox");
  const lightbox = new Lightbox({
    gallery: "#rich-text",
    children: "a.lightbox-enlarge-image",
    pswpModule: () => import("photoswipe"),
  });
  lightbox.init();
}

export function fixImagePath(content, baseUrl) {
  return content.replace(
    /<img([^>]*)\ssrc=(['"])(\/[^\2*([^\2\s<]+)\2/gi,
    `<img$1 src=$2${baseUrl}$3$2`,
  );
}

function RichText(props) {
  const { apiUrl } = useContext(RWSContext);

  useEffect(() => {
    if (window && document) {
      let script = require("scriptjs");
      script("https://platform.twitter.com/widgets.js", "twitter-embed", () => {
        if (window.twttr && window.twttr.widgets && window.twttr.widgets.load) {
          window.twttr.widgets.load();
        }
      });
    }
  }, []);

  // This creates an usable URL in the data attribute of the image
  useEffect(() => {
    document.querySelectorAll("a.lightbox-enlarge-image").forEach(a => {
      const originalAttribute = a.href;
      const newUrl = originalAttribute.replace(window.location.origin, apiUrl);
      a.href = newUrl;
    });
    initializePhotoSwipe();
  }, [apiUrl]);

  const html = props.content
    ? {
        dangerouslySetInnerHTML: { __html: fixImagePath(props.content, apiUrl) },
      }
    : {};
  return (
    <Content {...html} id="rich-text">
      {props.children}
    </Content>
  );
}

export default RichText;
