const rgba = (hex, alpha) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const cleanHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(cleanHex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}, ${alpha})`
    : hex;
};

export { rgba };

export default {
  white: "#ffffff",
  hemelsblauw: "#0073bf",
  lichtgeel: "#fcf29a",
  geel: "#f9e11e",
  donkergeel: "#E0C805",
  logoblauw: "#154273",
  lichthemelsblauw: "#d9ebf7",
  felblauw: "#1aaaff",

  gray100: "#f3f3f3",
  gray200: "#e6e6e6",
  gray300: "#dddddd",
  gray400: "#cccccc",
  gray500: "#a9a9a9",
  gray600: "#999999",
  gray700: "#696969",
  gray800: "#535353",
  gray900: "#222222",

  overlay: "rgba(0,0,0,.3)",

  signaalkleuren: {
    groen: "#39870c",
    donkergeel: "#ffb612",
    oranje: "#ff8f1f",
    lichtoranje: "#FF9F40",
    donkeroranje: "#d18800",
    rood: "#d52b1e",
    violet: "#a90061",
  },
  weeralarm: {
    geel: "#f9e11e",
    oranje: "#ef5d12",
    rood: "#ce1f00",
  },
};
