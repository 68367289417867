import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import { useOutsideClick } from "rooks";
import styled, { css } from "styled-components";
import colors from "../common/colors";
import { ReactComponent as Microphone } from "../common/icons/microphone.svg";
import { ReactComponent as Newspaper } from "../common/icons/newspaper.svg";
import { ReactComponent as VideoIcon } from "../common/icons/video.svg";
import { mq } from "../common/utils";
import { getMenuItems } from "../components/MenuItems/redux";
import slugify from "../utils/slugify";
import { ReactComponent as ChevronRight } from "./Datepicker/arrow-right.svg";
import TrafficInfo from "./TrafficInfo";

const listStyling = `&:focus,
&:hover,
&.active {
  background: linear-gradient(
    0deg,
    ${colors.hemelsblauw} 3px,
    ${colors.lichtgeel} 4px,
    ${colors.lichtgeel} 100%
  );
  color: ${colors.gray900};
}`;

const linkStyling = `
  padding: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  height: 2.75rem;
  font-size: 1rem;
  ${mq("largeDesktop")`
      font-size: 1.1rem;
      padding-left: 19px;
      padding-right: 19px;
    `}
  color: ${colors.gray900};
  text-decoration: none;
`;

const MenuLink = styled(NavLink)`
  ${linkStyling}
  ${listStyling}
`;

const HoverLink = styled.button`
  ${linkStyling}
  border: none;
  background: transparent;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 6px;
    margin-left: 8px;
    transform: rotate(90deg);
  }
`;

const SubMenuLink = styled(NavLink)`
  text-decoration: none;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  color: ${colors.logoblauw};
  &:hover {
    background: ${colors.gray100};
  }
  svg {
    max-width: 15px;
    max-height: 15px;
    margin-right: 0.5rem;
  }
`;

export const Navigation = styled.nav`
  display: none;
  background: ${colors.geel};
  max-height: 2.75rem;
  min-width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  position: relative;
  transform: none;
  align-items: center;
  z-index: 3;
  ${mq("desktop")`
      display: flex;
    `}
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
`;

const SubList = styled.ul.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})`
  position: absolute;
  margin: 0;
  padding: 0;
  background: ${colors.white};
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(-200%);
  ${props =>
    props.isOpen &&
    css`
      :focus-within {
        transform: translateY(0);
      }
    `}
  z-index: -10;
`;

const ListItem = styled.li.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})`
  list-style: none;
  ${props =>
    props.isOpen &&
    css`
      .navigation-sublist {
        transform: translateY(0);
      }
    `}
  ${listStyling}
`;

function ContentMenuItems(props) {
  const result = useSelector(getMenuItems);
  const { loading, results } = result;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef(null);

  function clickOutside() {
    setDropdownOpen(false);
  }

  useOutsideClick(menuRef, clickOutside);

  const { pathname } = useLocation();

  const [subItemIsActive, setSubItemIsActive] = useState(false);

  useEffect(() => {
    const isActive = (results || []).some(item => {
      const slug = `/${item?.meta?.slug || item?.slug}/`;
      return pathname.startsWith(slug);
    });
    setSubItemIsActive(isActive);
  }, [results, pathname]);

  if (loading) {
    return null;
  }
  if (!results) {
    return null;
  }

  return (
    <ListItem
      isOpen={dropdownOpen}
      tabIndex="0"
      ref={menuRef}
      className={subItemIsActive ? "active" : ""}
    >
      <HoverLink
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-label={dropdownOpen ? "Submenu inklappen" : "Submenu uitklappen"}
      >
        Achtergrond <ChevronRight />
      </HoverLink>
      <SubList isOpen={dropdownOpen} className="navigation-sublist">
        {results.map((item, i) => {
          const slug = `/${item?.meta?.slug || item?.slug}/`;

          return (
            <ListItem key={`custom${i}`}>
              <SubMenuLink
                onClick={() => setDropdownOpen(false)}
                activeClassName="active"
                to={slug}
              >
                {item.menu_icon === "newspaper" && <Newspaper aria-hidden="true" />}
                {item.menu_icon === "microphone" && <Microphone aria-hidden="true" />}
                {item.menu_icon === "video" && <VideoIcon aria-hidden="true" />}
                {item.title}
              </SubMenuLink>
            </ListItem>
          );
        })}
      </SubList>
    </ListItem>
  );
}

function DesktopMenu(props) {
  const { items = [] } = props;
  return (
    <Navigation>
      <List>
        {items.map((item, i) => (
          <React.Fragment key={i}>
            {i === 3 && <ContentMenuItems {...props} />}
            <ListItem>
              <MenuLink
                exact={item.exact}
                activeClassName="active"
                to={Boolean(item.url) ? item.url : `/${slugify(item.title)}/`}
                onClick={props.onLinkClick}
              >
                {item.title}
              </MenuLink>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
      <TrafficInfo />
    </Navigation>
  );
}

export default withRouter(DesktopMenu);
