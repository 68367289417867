import { makeDefaultReducer } from "../../common/utils";

const actionTypes = {
  LOAD: "weatheralerts/load",
  LOAD_SUCCESS: "weatheralerts/load_success",
  LOAD_FAILED: "weatheralerts/load_failed",
};

export const reducer = makeDefaultReducer(actionTypes);

export const loadWeatherAlerts = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: `/weatheralerts/`,
});

export const getWeatherAlert = state => {
  const {
    weatheralert: { items, loading, loaded, error, count },
  } = state;
  const visibleItems = Object.keys(items).map(key => items[key]);
  return {
    items: visibleItems,
    loading,
    loaded,
    error,
    count,
  };
};
