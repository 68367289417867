const actionTypes = {
  LOAD: "contactpage/load",
  LOAD_SUCCESS: "contactpage/load_success",
  LOAD_FAILED: "contactpage/load_failed",
};

export function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload.items[0],
        loading: false,
        loaded: true,
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export const loadContactPage = page => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: "/api/v2/pages/",
  payload: {
    type: "website.ContactPage",
    fields: "*",
  },
});

export const getContactPage = state => {
  const { contactpage } = state;
  const { loading, loaded, error = {}, ...page } = contactpage;
  const shouldLoad = !loading && !loaded && Object.keys(error).length === 0;

  return {
    loading,
    shouldLoad,
    page,
  };
};
