import React, { useRef } from "react";
import { Map, ScaleControl, TileLayer, ZoomControl } from "react-leaflet";
import L, { CRS } from "leaflet";
import "leaflet-rd";
import styled, { css } from "styled-components";
import Filters from "./Filters";
import { mq } from "../../common/utils";
import colors, { rgba } from "../../common/colors";
import Legend from "./Legend";
import { useMobileDetection } from "../../utils/hooks";
import { geographicalDistance } from "./utils";

if (CRS && CRS.RD) {
  CRS.RD.distance = geographicalDistance;
}

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['wide'].includes(prop),
})`
  position: fixed;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  ${props => mq("desktop")`
    top: 132px;
    height: auto;
    left: 480px;
    transition: left 0.22s ease-in-out;
    ${props.wide &&
      css`
        left: 0;
      `}
  `}
  .leaflet-right .leaflet-control {
    margin-right: 1.5rem;
    margin-bottom: 4.25rem;
    border: 0;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  }
  .leaflet-touch .leaflet-bar a {
    line-height: 26px; /* visually center button content */
    color: ${colors.gray900};
    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &.leaflet-disabled {
      color: #bbb;
    }
  }
  .markercluster {
    border-radius: 100%;
    background: ${colors.signaalkleuren.donkergeel};
    background-clip: padding-box;
    border: 1px solid rgba(255, 255, 255, 0.75);
    box-shadow: 0 0 0 5px ${rgba(colors.signaalkleuren.donkergeel, 0.5)};
    display: flex;
    .number {
      color: ${colors.gray900};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      flex: 1;
      font-family: var(--eov-font);
    }
    &--orange {
      background: ${colors.signaalkleuren.oranje};
      box-shadow: 0 0 0 5px ${rgba(colors.signaalkleuren.oranje, 0.5)};
    }
    &--red {
      background: ${colors.signaalkleuren.rood};
      box-shadow: 0 0 0 5px ${rgba(colors.signaalkleuren.rood, 0.5)};
      .number {
        color: ${colors.white};
      }
    }
    &--camera {
      background: ${colors.logoblauw};
      box-shadow: 0 0 0 5px ${rgba(colors.logoblauw, 0.5)};
      .camera {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 13px;
        font-weight: bold;
        width: 100%;
        color: ${colors.white};
        line-height: 1;
        svg {
          width: 15px;
        }
      }
    }
  }
  .letter-marker {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${colors.felblauw};
    color: ${colors.white};
    font-size: 13px;
    font-weight: bold;
    box-shadow: 0 0 3px 0px ${colors.logoblauw};
  }
`;

const MapContainer = styled.div`
  flex: 1;
  position: relative;
  ${mq("desktop")`
    height: 100%;
  `}
`;

function MapLayout(props) {
  const { wide, center } = props;
  // Restrict panning a little bit to mostly prevent displaying 'white' tiles.
  const mapBounds = new L.LatLngBounds(new L.LatLng(55.5, -1), new L.LatLng(49, 12));
  const wrapperRef = useRef();
  const mapRef = useRef();
  const isMobile = useMobileDetection();

  const updateMapSize = evt => {
    if (evt.target === wrapperRef.current) {
      mapRef.current.leafletElement.invalidateSize({ animate: true, duration: 0.8 });
    }
  };

  return (
    <Wrapper wide={wide} onTransitionEnd={updateMapSize} ref={wrapperRef}>
      {!Boolean(props.hideFilters) && !(isMobile && !props.fullscreen) && (
        <Filters transparent={false} />
      )}
      <MapContainer>
        <Map
          animate={true}
          className="markercluster-map"
          center={center}
          zoom={props.zoomLevel}
          continuousWorld={true}
          useFlyTo={true}
          crs={CRS.RD}
          attributionControl={false}
          zoomControl={false}
          style={{
            backgroundColor: "#d6f1e8",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
          }}
          trackResize
          maxBounds={mapBounds}
          maxBoundsViscosity={0.8}
          ref={mapRef}
          preferCanvas={true}
        >
          <ScaleControl imperial={false} maxWidth={200} position="bottomleft" />
          <ZoomControl position={isMobile ? "bottomleft" : "bottomright"} />
          <TileLayer
            url={
              "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:28992/{z}/{x}/{y}.png"
            }
            attribution=""
            minZoom={3}
            maxZoom={13}
            continuousWorld={true}
            reuseTiles={true}
            unloadInvisibleTiles={true}
            updateWhenIdle={true}
            maxClusterRadius={40}
            detectRetina={false}
          />
          {props.children}
        </Map>
        <Legend />
      </MapContainer>
    </Wrapper>
  );
}

export default MapLayout;
