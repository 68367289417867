import externalSlugify from "slugify";

const slugify = str => {
  if (!str) {
    return "";
  }
  const options = {
    lower: true,
    remove: /[*+~.()'"!:@]/g,
  };
  return externalSlugify(str.toString(), options);
};

export default slugify;
