import React from "react";
import styled from "styled-components";
import { ReactComponent as IconCross } from "../../common/icons/close_filter.svg";
import { RoundButton } from "../Button";

const Button = styled(RoundButton)`
  position: absolute;
  top: 80px;
  left: 16px;
  pointer-events: auto;
`;

function CloseButton(props) {
  return (
    <Button title="Sluiten" type="button" onClick={props.onClick}>
      <IconCross title={props.title} />
    </Button>
  );
}

export default CloseButton;
