export const geographicalDistance = function(latlng1, latlng2) {
  /* based on https://www.geodatasource.com/developers/javascript */
  const { lat: lat1, lng: lng1 } = latlng1;
  const { lat: lat2, lng: lng2 } = latlng2;
  if (lat1 === lat2 && lng1 === lng2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lng1 - lng2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515 * 1.609344 * 1000;
    return dist;
  }
};
