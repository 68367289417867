import React from "react";
import styled from "styled-components";
import colors from "../../common/colors";
import slugify from "../../utils/slugify";
import ListCard from "../List/Card";
import { ReactComponent as IconCamera } from "../../common/icons/camera.svg";

const Near = styled.div`
  font-size: 14px;
  line-height: 1.35;
`;
const Description = styled.div`
  font-size: 13px;
  color: ${colors.gray700};
  line-height: 1.25;
`;

function CameraCard(props) {
  return (
    <ListCard
      to={`/cameras/${props.id}/${slugify(`${props.road} ${props.near}`)}`}
      icon={<IconCamera />}
      roadNumber={props.road}
      title={"Verkeerscamera"}
      onClick={props.onClick}
    >
      <Near>t.h.v. {props.near}</Near>
      <Description>{props.location_description}</Description>
    </ListCard>
  );
}

export default CameraCard;
