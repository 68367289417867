import React, { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useRouteMatch } from "react-router-dom";
import colors from "../common/colors";
import Tooltip from "./Tooltip";
import { withRouter } from "react-router";
import { useMobileDetection } from "../utils/hooks";

const StyledInner = styled.div`
  width: 480px;
  ${props =>
    props.$hasOverflow &&
    css`
      overflow-y: scroll;
    `}
  background: ${colors.white};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const SidebarContext = React.createContext({
  setScrollTop: () => {},
  detectScrollTop: () => {},
});

const Inner = props => {
  const [scrollTop, setScrollTop] = useState(0);
  const containerRef = React.useRef();

  const detectScrollTop = useCallback(() => {
    setScrollTop(containerRef?.current?.scrollTop ?? 0);
  }, [containerRef]);

  const isDetailRoute = useRouteMatch({
    path: "/(hinder|cameras)/:id",
    strict: true,
    sensitive: true,
  });

  useEffect(() => {
    if (!isDetailRoute && scrollTop > 0) {
      containerRef.current.scrollTop = scrollTop;
    }
  }, [isDetailRoute, scrollTop]);

  return (
    <SidebarContext.Provider
      value={{
        setScrollTop: (num = 1) => setScrollTop(num),
        detectScrollTop,
      }}
    >
      <StyledInner {...props} ref={containerRef} />
    </SidebarContext.Provider>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  transition: all 0.22s ease-in-out;
  will-change: transform;
  transform: translateX(0%);
  overflow: hidden;

  ${props =>
    props.$hasOverflow &&
    css`
      overflow: visible;
    `}

  ${props =>
    !props.open &&
    css`
      transform: translateX(-100%);
    `}
`;

const SidebarToggle = styled.button`
  height: 56px;
  width: 24px;
  position: absolute;
  top: 80px;
  right: 0;
  transform: translateX(100%);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 7px 2px 11px 0 rgba(0, 0, 0, 0.15);
  border: 0;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  &::after {
    content: "";
    display: inline-block;
    border-width: 6px 6px 6px 0;
    border-style: solid;
    border-color: transparent ${colors.gray700} transparent transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s ease-in-out;
    transform-origin: center;
    ${props =>
      !props.open &&
      css`
        transform: translate(-50%, -50%) scaleX(-1);
      `}
  }
  &:hover {
    ${Tooltip} {
      opacity: 1;
    }
  }
`;

function Sidebar(props) {
  const isMobile = useMobileDetection();
  if (isMobile) {
    return props.children;
  }

  return (
    <Wrapper $hasOverflow={true} open={props.open}>
      <Inner $hasOverflow={true}>{props.children}</Inner>
      <SidebarToggle onClick={props.onToggle} open={props.open}>
        <Tooltip>{props.open ? "Zijvenster inklappen" : "Zijvenster uitklappen"}</Tooltip>
      </SidebarToggle>
    </Wrapper>
  );
}

export default withRouter(Sidebar);
