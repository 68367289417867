import React from "react";
import styled from "styled-components";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import NotFoundMessage from "../components/NotFoundMessage";
import { Helmet } from "react-helmet-async";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
`;

const Wrapper = styled.div`
  padding: 2rem 1rem;
  flex: 1;
`;

function PageNotFound() {
  return (
    <Container>
      <Helmet>
        <title>Pagina niet gevonden</title>
      </Helmet>
      <SubHeader />
      <Wrapper>
        <NotFoundMessage
          title="Pagina niet gevonden"
          message="De pagina die je zocht kon niet worden gevonden."
        />
      </Wrapper>
      <Footer />
    </Container>
  );
}

export default PageNotFound;
