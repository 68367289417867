import React from "react";
import styled, { css } from "styled-components";
import { mq } from "../common/utils";
import colors from "../common/colors";
import BackButton from "../components/BackButton";
import Loading from "../components/Loading";
import Badge from "../components/Badge";
import { useMobileDetection } from "../utils/hooks";
import convertDotSpaceToLineBreak from "../common/convertDotSpaceToLineBreak";

const Panel = styled.div`
  z-index: 1000;
  background: white;
  overflow: auto;
  pointer-events: auto;
  height: 100%;
  ${props =>
    props.mobile &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 6px 6px 0 0;
      box-shadow: 0 -1px 8px 0 ${colors.gray700};
    `}
`;

const Body = styled.div`
  padding: 1rem;
  padding-top: 0.5rem;
  background: white;
  border-radius: 15px 15px 0 0;
  ${mq("desktop")`
    border-radius: 0;
    padding-top: 2rem;
  `}
`;

const Map = styled.div`
  background: ${colors.hemelsblauw};
`;

const Badges = styled.div`
  margin-bottom: 1rem;
`;

const Subtitle = styled.div`
  color: ${colors.logoblauw};
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 4px;
`;

const Subsubtitle = styled.div`
  color: ${colors.gray700};
  margin-bottom: 24px;
  font-size: 14px;
`;

const Title = styled.h1`
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1.3;
  ${mq("desktop")`
    font-size: 31px;
  `}
`;

function ResultPanel(props) {
  const { loading } = props;
  const isMobile = useMobileDetection();

  if (loading) {
    return (
      <Panel mobile={isMobile}>
        <Loading text="Bezig met laden..." fullHeight />
      </Panel>
    );
  }

  return (
    <React.Fragment>
      <BackButton history={props.history} />
      <Panel mobile={isMobile}>
        {props.map && <Map style={{ height: "240px" }}>{props.map}</Map>}
        <Body>
          <Badges>
            {Boolean(props.road) && (
              <Badge road color={props.road.indexOf("A") === 0 ? "red" : "yellow"}>
                {props.road}
              </Badge>
            )}
            {Boolean(props.hmFrom) && <Badge color="green">{props.hmFrom}</Badge>}
            {Boolean(props.hmTo) && props.hmFrom !== props.hmTo && (
              <Badge color="green">{props.hmTo}</Badge>
            )}
          </Badges>

          <Title>{props.title}</Title>
          <Subtitle>{props.subtitle}</Subtitle>
          <Subsubtitle>{convertDotSpaceToLineBreak(props.subsubtitle)}</Subsubtitle>

          {props.children}
        </Body>
      </Panel>
    </React.Fragment>
  );
}

ResultPanel.defaultProps = {
  map: true,
};

export default ResultPanel;
