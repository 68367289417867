import React from "react";
import axios from "axios";
import Autocomplete from "../Autocomplete";
import Here from "../../api/here";

export const getItems = async query => {
  const url = Here.AutocompleteUrl(query);
  try {
    const response = await axios.get(url);
    const { items: suggestions } = response.data;

    // no suggestions matching the query? empty the result set.
    if (!suggestions || suggestions.length === 0) {
      return [];
    }

    const formattedResults = suggestions
      .map(suggestion => {
        // PDOK results converted to HereAPI format (/api/autocomplete/) have a display_name
        if (suggestion.display_name) {
          return `${suggestion.display_name}`;
        }
        // Result doesn't match our expectation
        return null;
      })
      .filter(item => item);

    // remove duplicate results
    const items = Array.from(new Set([...formattedResults]));
    return items;
  } catch (error) {
    // TODO: handle errors properly?
    let {
      message,
      response: { status },
    } = error;
    if (status === 401) {
      message = "Er is iets foutgegaan. Probeer het later opnieuw.";
    }
    throw message;
  }
};

function LocationField(props) {
  return <Autocomplete {...props} dataSource={getItems} />;
}

export default LocationField;
