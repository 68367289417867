import React, { useContext, useLayoutEffect, useRef } from "react";
import { SidebarContext } from "./Sidebar";
import Button from "./Button";
import styled from "styled-components";
import colors from "../common/colors";
import ObstructionCard from "./Obstruction/Card";
import Loading from "./Loading";
import List from "./List";
import { setListExpanded, getListIsExpanded } from "./List/redux";
import { useDispatch, useSelector } from "react-redux";

const Wrapper = styled.div`
  padding: 2rem 1rem;
  border-bottom: 1px solid ${colors.gray200};
  background: ${colors.white};
`;

const H2 = styled.h2`
  font-weight: normal;
  margin-bottom: 1rem;
  color: ${colors.logoblauw};
`;

function ObstructionList(props) {
  const { items, count, loaded, error = {}, limited } = props;

  const { detectScrollTop, setScrollTop } = useContext(SidebarContext);

  const expanded = useSelector(getListIsExpanded);
  const dispatch = useDispatch();

  const sixthListItem = useRef();

  const onExpandClick = () => {
    dispatch(setListExpanded(!expanded));
    setScrollTop(expanded ? 1 : 0);
  };

  // when expanding the list, focus on the first revealed item.
  useLayoutEffect(() => {
    if (expanded && sixthListItem?.current) {
      sixthListItem.current.focus();
    }
  }, [expanded]);

  return (
    <Wrapper style={props.style}>
      {loaded ? (
        <>
          <List>
            {items.map((obstruction, index) => (
              <ObstructionCard
                key={obstruction.obstructionId}
                {...obstruction}
                onClick={detectScrollTop}
                ref={index === 5 ? sixthListItem : undefined}
              />
            ))}
            {items.length === 0 && Object.keys(error).length === 0 && (
              <>
                <H2>Geen resultaten gevonden</H2>
                <p>Pas je filters of zoekopdracht aan.</p>
              </>
            )}
          </List>
          {count > 5 && limited && (
            <Button outline block onClick={onExpandClick}>
              {expanded ? "Lijst inklappen" : <>Alle resultaten ({count})</>}
            </Button>
          )}
        </>
      ) : Object.keys(error).length ? (
        <p>Data kon niet geladen worden.</p>
      ) : (
        <Loading text="Bezig met laden..." />
      )}
    </Wrapper>
  );
}

ObstructionList.defaultProps = {
  limited: true,
  count: 0,
};

export default ObstructionList;
