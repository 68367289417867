import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadTraffic, reloadTraffic } from "./redux";

export const useShouldLoadTraffic = (shouldLoad, timeout = 0) => {
  const dispatch = useDispatch();
  const timer = useRef();

  useEffect(() => {
    if (shouldLoad) {
      timer.current = setTimeout(() => {
        dispatch(loadTraffic());
      }, timeout);
    } else {
      clearTimeout(timer.current);
    }
  }, [shouldLoad, dispatch, timeout]);

  useTrafficReloader(dispatch);
};

export const useTrafficReloader = dispatch => {
  const { dateLoaded = new Date(), loading } = useSelector(state => state.traffic);

  useEffect(() => {
    const interval = setInterval(() => {
      const delta = new Date().getTime() - dateLoaded.getTime();
      if (delta > 30000 && !loading) {
        dispatch(reloadTraffic());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [dateLoaded, loading, dispatch]);
};
