import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import colors from "../common/colors";
import { ReactComponent as Chevron } from "../common/icons/chevron.svg";
import { mq } from "../common/utils";
import TrafficInfo from "./TrafficInfo";

const ChevronIcon = styled(Chevron)`
  margin-right: 10px;
  margin-top: -2px;
`;

const Label = styled(Link)`
  text-decoration: none;
  color: ${colors.gray900};
  display: flex;
  align-items: center;
  font-size: 18px;
`;

const SubHeaderWrapper = styled.div`
  display: flex;
  background: ${colors.geel};
  height: 3.5rem;
  min-height: 3.5rem;
  padding: 1rem;
  justify-content: space-between;
  ${mq("desktop")`
      display: none;
  `}
`;

function SubHeader(props) {
  return (
    <SubHeaderWrapper>
      <Label to="/">
        {props.location.pathname !== "/" && <ChevronIcon />}
        Actuele verkeersinfo
      </Label>
      <TrafficInfo />
    </SubHeaderWrapper>
  );
}

export default withRouter(SubHeader);
