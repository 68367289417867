import { Wrapper as InputWrapper } from "../Input";
import styled from "styled-components";
import colors from "../../common/colors";
import "react-datepicker/dist/react-datepicker.css";

const Wrapper = styled(InputWrapper)`
  .react-datepicker-wrapper {
    flex: 1;
  }
  .react-datepicker-popper {
    z-index: 3;
    /* otherwise datepicker-popper will lay under news */
  }
  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 4px;
    margin-left: -46px;
  }
  .react-datepicker__input-container {
    display: block;
  }
  .react-datepicker {
    font-family: var(--eov-font);
    border: 1px solid ${colors.gray300};
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  }
  .react-datepicker__day {
    border: 1px solid ${colors.gray300};
    width: 40px;
    height: 40px;
    color: ${colors.gray900};
    margin: 0 -1px -1px 0; /* overlap borders */
    line-height: 40px;
    border-radius: 0;
    font-size: 16px;
    &:hover {
      border-radius: 0;
      background: ${colors.gray100};
    }
    &--outside-month {
      border-color: transparent;
      color: ${colors.gray800};
    }
    &--disabled {
      color: ${colors.gray500};
      cursor: not-allowed;
      &:hover {
        border-radius: 0;
        background: transparent;
      }
    }
    &--selected {
      color: ${colors.white};
      font-weight: bold;
      background: ${colors.hemelsblauw};
      &:hover {
        background: ${colors.hemelsblauw};
      }
    }
    &--keyboard-selected {
      background-color: ${colors.gray300};
    }
  }
  .react-datepicker__header {
    background: transparent;
    border: 0;
    border-radius: 0;
    padding-top: 20px;
  }
  .react-datepicker__day-names {
    padding-left: 20px;
    padding-right: 20px;
  }
  .react-datepicker__day-name {
    font-weight: bold;
    font-size: 14px;
    color: ${colors.gray700};
    width: 39px; /* substract 1px non-existent border space */
    margin: 0;
  }
  .react-datepicker__month {
    margin: 0;
    padding: 0 20px 20px;
  }
  .react-datepicker__current-month {
    font-size: 16px;
    color: ${colors.gray900};
    padding-bottom: 10px;
  }
  .react-datepicker__navigation-icon::before {
    content: unset;
  }
  .react-datepicker__navigation {
    text-indent: 0;
    border: 0;
    overflow: auto;
    color: ${colors.hemelsblauw};
    width: 24px;
    height: 24px;
    top: 18px;
    line-height: 1;
    svg {
      width: 8px;
      height: 14px;
    }
  }
  .react-datepicker__navigation--previous {
    left: 4px;
  }
  .react-datepicker__navigation--next {
    right: 10px;
  }
`;

export default Wrapper;
