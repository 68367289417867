import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const Svg = styled.svg`
  animation: ${rotate} 2s linear infinite;
  z-index: 2;
  width: 50px;
  height: 50px;
  margin-bottom: 0.5rem;
  .path {
    stroke: hsl(203, 100%, 39%);
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: ${props => (props.$fullHeight ? "100%" : "100px")};
`;

function Loading(props) {
  return (
    <Container $fullHeight={props.fullHeight}>
      <Svg viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </Svg>
      {props.text || "Bezig met laden..."}
    </Container>
  );
}

export default Loading;
