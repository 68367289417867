import { makeDefaultReducer } from "./makeDefaultReducer";
import { processPayload } from "./processPayload";

const devices = ["mobile", "tablet", "desktop", "largeDesktop"] as const;
type DeviceType = typeof devices[number];

const breakpoints = new Map<DeviceType, number>();
breakpoints.set("mobile", 576);
breakpoints.set("tablet", 768);
breakpoints.set("desktop", 1024);
breakpoints.set("largeDesktop", 1170);

const mq = (device: DeviceType) => {
  return (strings: TemplateStringsArray, ...expr: string[]) => {
    const components: string[] = [];
    strings.forEach((str: string, index) => {
      components.push(str);
      if (index < expr.length) {
        components.push(expr[index]);
      }
    });
    const content = components.join("");
    const breakpoint = breakpoints.get(device);

    return `
      @media(min-width: ${breakpoint}px) {
        ${content}
      }
    `;
  };
};

export { mq, breakpoints, makeDefaultReducer, processPayload };
