import { combineReducers } from "redux";
import { reducer as peakexpectation } from "../components/PeakExpectation/redux";
import { reducer as weatheralert } from "../components/WeatherAlert/redux";
import { reducer as news } from "../components/News/redux";
import { reducer as obstructions } from "../components/Obstruction/redux";
import { reducer as traffic } from "../components/Traffic/redux";
import { reducer as roadworks } from "../components/Roadworks/redux";
import { reducer as map } from "../components/Map/redux";
import { reducer as route } from "../components/Route/redux";
import { reducer as roads } from "../components/Road/redux";
import { reducer as cameras } from "../components/Camera/redux";
import { reducer as disclaimer } from "../components/Disclaimer/redux";
import { reducer as contactpage } from "../components/ContactPage/redux";
import { reducer as list } from "../components/List/redux";
import { reducer as menuitems } from "../components/MenuItems/redux";
import { reducer as categoryoverviewpage } from "../components/CategoryOverviewPage/redux";
import { reducer as categories } from "../components/Category/redux";

export default combineReducers({
  peakexpectation,
  weatheralert,
  news,
  obstructions,
  roadworks,
  traffic,
  map,
  route,
  roads,
  cameras,
  disclaimer,
  contactpage,
  list,
  menuitems,
  categoryoverviewpage,
  categories,
});
