import React from "react";
import colors from "../common/colors";
import styled from "styled-components";
import Loading from "./Loading";
import ExternalLink from "./ExternalLink";

const Wrapper = styled.div`
  padding: 1rem;
  background: ${colors.white};
  // Temporary hide twitter timeline, because it's not working correctly.
  display: none;
`;

const Title = styled.h2`
  font-weight: normal;
  margin: 0;
  margin-bottom: 1.5rem;
`;

const EmbedContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Error = styled.div`
  font-size: 15px;
`;
class TwitterTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };

    this.embedContainer = React.createRef();
  }

  renderWidget(options) {
    if (!this.isMountCanceled && window?.twttr?.widgets?.createTimeline && this.embedContainer) {
      window.twttr.widgets
        .createTimeline(
          {
            sourceType: "profile",
            screenName: this.props.screenName,
          },
          this.embedContainer.current,
          options,
        )
        .then(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  componentDidMount() {
    if (window && document) {
      let script = require("scriptjs");
      script("https://platform.twitter.com/widgets.js", "twitter-embed", () => {
        if (!window.twttr) {
          this.setState({ cannotLoad: true, isLoading: false });
          return;
        }

        const options = {
          tweetLimit: 1,
          linkColor: colors.hemelsblauw,
          lang: "nl",
          chrome: ["noheader", "nofooter", "noscrollbar", "noborders", "transparent"].join(" "),
        };

        this.renderWidget(options);
      });
    }
  }

  componentWillUnmount() {
    this.isMountCanceled = true;
  }

  render() {
    const { isLoading, cannotLoad } = this.state;
    return (
      <React.Fragment>
        {isLoading && <Loading text="Bezig met laden..." fullHeight />}
        {cannotLoad && (
          <Error>
            De laatste tweet kon niet geladen worden. <br />
            Ga naar&nbsp;
            <ExternalLink href={`https://twitter.com/${this.props.screenName}`}>
              @{this.props.screenName}
            </ExternalLink>
            &nbsp;voor de laatste tweets.
          </Error>
        )}
        <div ref={this.embedContainer} />
      </React.Fragment>
    );
  }
}

function RecentTweet() {
  return (
    <Wrapper>
      <Title>Laatste tweet</Title>
      <EmbedContainer>
        <TwitterTimeline screenName="RWSverkeersinfo" autoHeight />
      </EmbedContainer>
    </Wrapper>
  );
}

export default RecentTweet;
