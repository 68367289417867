import React from "react";
import styled, { css } from "styled-components";
import colors from "../../common/colors";
import { ReactComponent as Arrow } from "../../common/icons/arrow-back.svg";

const Box = styled.div`
  height: 80px;
  border: 1px solid ${colors.gray300};
  border-radius: 5px;
  color: ${colors.logoblauw};
  margin-bottom: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Status = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: 24px;
  height: 24px;
  background: ${colors.gray100};
  border-radius: 50%;
  color: ${colors.signaalkleuren.rood};
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
  svg {
    width: 12px;
  }
  ${props =>
    props.decreasing &&
    css`
      color: ${colors.signaalkleuren.groen};
      transform: rotate(270deg);
    `}
`;

const Large = styled.div`
  font-weight: 700;
  font-size: 24px;
`;

function Fact(props) {
  const { status, number, label } = props;
  let title = "";
  if (status === 1) {
    title = `De ${label.toLowerCase()} neemt toe`;
  }
  if (status === 3) {
    title = `De ${label.toLowerCase()} is aan het afnemen`;
  }

  return (
    <Box>
      {Boolean(status) && (
        <Status decreasing={status === 3}>
          <Arrow title={title} />
        </Status>
      )}
      <Large>{number}</Large>
      <div>{label}</div>
    </Box>
  );
}

export default Fact;
