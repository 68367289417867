import React, { useState } from "react";
import styled, { css } from "styled-components";
import colors from "../../common/colors";
import RouteForm from "./RouteForm";
import RoadNumberForm from "./RoadNumberForm";

const Container = styled.div.attrs(() => ({ className: "eov-search-container" }))`
  background: ${colors.gray100};
  padding: 32px 16px;
  border-bottom: 1px solid ${colors.gray200};
`;

const Inner = styled.div.attrs(() => ({ className: "eov-search-inner" }))`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  display: block;
  h2 {
    font-weight: normal;
    margin-bottom: 16px;
  }
`;

const TabList = styled.div.attrs(() => ({ className: "eov-search-tablist" }))`
  display: flex;
  margin-bottom: 24px;
`;

const Tab = styled.button.attrs(() => ({ type: "button" }))`
  flex: 1;
  text-align: center;
  border: 1px solid ${colors.hemelsblauw};
  background: ${props => (props.selected ? colors.hemelsblauw : "transparent")};
  color: ${props => (props.selected ? colors.white : colors.hemelsblauw)};
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.leftRadius &&
    css`
      border-radius: 3px 0 0 3px;
    `}
  ${props =>
    props.rightRadius &&
    css`
      border-radius: 0 3px 3px 0;
    `}
`;

const Title = styled.h2.attrs(props => ({
  as: props.elem,
  className: "eov-search-title",
}))``;

const SearchFormText = styled.p`
  padding-top: 24px;
  color: ${colors.gray900};
  font-size: 14px !important;
  line-height: 1.4;
  margin: 0;
`;

const TextBelowTitle = styled.p`
  color: #154273;
  a, a:visited {
    color: #154273;
    text-decoration: underline;
  }
  margin-bottom: 28px;
`;

function Search(props) {
  const [tab, setTab] = useState(props.route ? "route" : "wegnummer");
  const { containerRef } = props;

  return (
    <Container ref={containerRef}>
      <Inner>
        <Title elem={props.titleElement || "h2"}>Hinder op je route?</Title>
        <TextBelowTitle>
          Check voor vertrek informatie over de <a target="_blank" rel="noopener noreferrer" href="https://www.rijkswaterstaat.nl/wegen/wegenoverzicht">snelwegen en de diverse N-wegen</a> van Rijkswaterstaat.
        </TextBelowTitle>
        <TabList>
          <Tab
            leftRadius
            onClick={() => setTab("route")}
            selected={tab === "route"}
            aria-pressed={tab === "route" ? "true" : "false"}
          >
            Route
          </Tab>
          <Tab
            rightRadius
            onClick={() => setTab("wegnummer")}
            selected={tab === "wegnummer"}
            aria-pressed={tab === "wegnummer" ? "true" : "false"}
          >
            Wegnummer
          </Tab>
        </TabList>
        {tab === "route" && <RouteForm {...props} />}
        {tab === "wegnummer" && <RoadNumberForm {...props} />}
        {props.showRwsText && (
          <SearchFormText>
            Deze informatie wordt verstrekt door Rijkswaterstaat Verkeersinformatie.&nbsp;
            {props.newWindow && <span>De resultaten worden in een nieuw venster geopend.</span>}
          </SearchFormText>
        )}
      </Inner>
    </Container>
  );
}

export default React.forwardRef((props, ref) => {
  return <Search {...props} containerRef={ref} />;
});
