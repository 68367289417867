import React from "react";

const convertDotSpaceToLineBreak = inputString => {
  if (inputString) {
    const subsubtitleArray = inputString.split(". ");
    return subsubtitleArray.map((item, i, { length }) => (
      <React.Fragment key={i}>
        {item}
        {length - 1 !== i && (
          <>
            .<br />
          </>
        )}
      </React.Fragment>
    ));
  }
};

export default convertDotSpaceToLineBreak;
