import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Overlay from "./FullscreenToggleOverlay";
import CloseButton from "./CloseButton";
import Filters from "./Filters";
import { useMobileDetection } from "../../utils/hooks";

const MobileMapWindow = styled.div`
  max-height: 220px;
  height: 100%;
  position: relative;
  transition: max-height 0.22s ease-in-out;
  ${props =>
    props.expanded &&
    css`
      max-height: 100%;
    `}
`;

function useFullscreen(wrapper, defaultValue) {
  const [fullscreen, setFullscreen] = useState(defaultValue);
  const [originalScrollPosition, saveOriginalScrollPosition] = useState(window.pageYOffset);

  useEffect(() => {
    // When fullscreen is turned on, the map-window gets 100% height.
    // This effect is used to scroll the document up and actually show the map fullscreen.
    // When fullscreen is turned off, the document gets scrolled back to its original position.
    const scrollOptions = {
      top: originalScrollPosition,
      left: 0,
      behavior: "smooth",
    };
    if (fullscreen) {
      const domElement = wrapper.current;
      if (domElement) {
        const boundingRect = domElement.getBoundingClientRect();
        const top = boundingRect.top + window.pageYOffset;
        saveOriginalScrollPosition(window.pageYOffset);
        scrollOptions.top = top;
      }
    }

    const isSmoothScrollSupported = "scrollBehavior" in document.documentElement.style;
    if (isSmoothScrollSupported) {
      window.scrollTo(scrollOptions);
    } else {
      window.scrollTo(scrollOptions.left, scrollOptions.top);
    }
  }, [fullscreen, wrapper, originalScrollPosition]);
  return [fullscreen, setFullscreen];
}

function MapWindow(props) {
  const window = React.useRef();
  const [fullscreen, setFullscreen] = useFullscreen(window, props.isFullscreen);
  const { onMapFullscreen } = props;
  const isMobile = useMobileDetection();

  useEffect(() => {
    document.body.classList.toggle("fullscreen", fullscreen);
    onMapFullscreen(fullscreen);
  }, [fullscreen, onMapFullscreen]);

  if (!isMobile) {
    return null;
  }

  return (
    <React.Fragment>
      {!fullscreen && <Filters />}
      <MobileMapWindow expanded={fullscreen} ref={window}>
        {fullscreen ? (
          <CloseButton onClick={() => setFullscreen(false)} />
        ) : (
          <Overlay onClick={() => setFullscreen(true)} />
        )}
      </MobileMapWindow>
    </React.Fragment>
  );
}

MapWindow.defaultProps = {
  onMapFullscreen: () => {},
  isFullscreen: false,
};

export default MapWindow;
