import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colors from "../common/colors";
import cars from "../common/icons/cars.svg";
import { getTrafficInfo } from "./Traffic/redux";
import { useShouldLoadTraffic } from "./Traffic/hooks";

const TrafficInfoWrapper = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const CarsIcon = styled.img`
  width: 1.5rem;
  margin-right: 0.5rem;
  display: block;
  float: left;
`;

const TrafficData = styled.span`
  padding-top: 4px;
  display: inline-block;
`;

const TrafficLink = styled(Link)`
  color: ${colors.gray900};
`;

function TrafficInfo() {
  const result = useSelector(getTrafficInfo);
  const { numberOfJams, km, loading, loaded, shouldLoad, error = {} } = result;

  useShouldLoadTraffic(shouldLoad, 500);
  return (
    !Object.keys(error).length && (
      <TrafficInfoWrapper>
        <TrafficLink to="/files/">
          <CarsIcon src={cars} alt="" />
          <TrafficData>
            {loading && !loaded ? (
              "Bezig met laden..."
            ) : (
              <>
                {`${numberOfJams} file${numberOfJams !== 1 ? "s" : ""} `} &bull; {km} km
              </>
            )}
          </TrafficData>
        </TrafficLink>
      </TrafficInfoWrapper>
    )
  );
}

export default TrafficInfo;
