import { createGlobalStyle, css } from "styled-components";
import "./fonts/rijksoverheid.css";
import colors from "./colors";
import { mq } from "./utils";

const fontFamily = `'Rijksoverheid Sans Web Text', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`;

export { fontFamily };

const GlobalStyle = createGlobalStyle`
  :root {
    --eov-font: ${fontFamily};
  }
  html, body {
    font-family: var(--eov-font);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-size: 16px;
    height: 100%;
    min-height: 100%;
  }
  *, *::before, *::after {
    box-sizing: border-box;
    font-family: var(--eov-font);
  }
  input, select, button, textarea {
    font-family: var(--eov-font);
  }
  *:focus {
    outline: 1px dotted ${colors.gray900};
  }
  ${props =>
    !props.storybook &&
    css`
      #root {
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100);
        display: flex;
        flex-direction: column;
        .container {
          flex: 1;
          display: flex;
          z-index: 2;
          ${mq("desktop")`
            z-index: unset;
          `}
        }
      }
    `}
  h1, h2, h3, h4, h5, h6 {
    color: ${colors.logoblauw};
    margin: 0 0 1.1em;
    line-height: 1.3;
  }
  h1 {
    font-size: 31px;
    font-weight: 400;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
    margin-top: 0;
  }
  body.fullscreen {
    overflow: hidden;
    .container {
      pointer-events: none;
    }
    ${mq("desktop")`
      overflow: unset;
      .container {
        pointer-events: unset;
      }
    `}

  }
`;

export default GlobalStyle;
