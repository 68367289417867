import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import Search from "../components/Search";
import Disclaimer from "../components/Disclaimer";
import Footer from "../components/Footer";
import { setLegendaIsCollapsed } from "../components/Map/redux";
import { useMobileDetection } from "../utils/hooks";

function SearchPage() {
  const dispatch = useDispatch();
  const isMobile = useMobileDetection();

  useEffect(() => {
    dispatch(setLegendaIsCollapsed(isMobile));
  }, [dispatch, isMobile]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Is er hinder op jouw route?</title>
        <meta
          name="description"
          content="Bekijk hier de hindernissen op jouw route. Zo ga je zonder verrassingen over de snelweg van A naar B."
        />
      </Helmet>

      <Search route titleElement="h1" />
      <Disclaimer />
      <Footer />
    </React.Fragment>
  );
}

export default SearchPage;
