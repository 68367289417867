import React from "react";
import NewsListItem from "../NewsListItem";
import styled from "styled-components";
import colors from "../../common/colors";

const H3 = styled.h3`
  font-size: 24px;
  font-weight: normal;
  margin-top: 40px;
  margin-bottom: 1rem;
`;

const NewsListing = styled.ul`
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${colors.gray200};
  list-style: none;
`;

function RelatedNews({ news, title = "Meer nieuws" }) {
  if ((news || []).length === 0) {
    return null;
  }
  return (
    <>
      <H3 as="h2">{title}</H3>
      <NewsListing>
        {news.map(item => (
          <NewsListItem showLead key={item.id} {...item} titleTag="h3" />
        ))}
      </NewsListing>
    </>
  );
}

export default RelatedNews;
