import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CardList from "./CardList";
import News from "./News/Card";
import { loadNews, getHomeNews } from "./News/redux";

function NewsList() {
  const result = useSelector(getHomeNews);
  const { shouldLoad } = result;
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldLoad) {
      dispatch(loadNews());
    }
  }, [shouldLoad, dispatch]);

  return (
    <CardList
      title="Laatste nieuws"
      link="/nieuws"
      linkText="Meer nieuws"
      card={News}
      desktopGrid
      tabletGrid
      mobileGrid={false}
      newsList
      {...result}
    />
  );
}

export default NewsList;
