import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import styled from "styled-components";
import GlobalStyle from "../common/GlobalStyle";
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import SidebarWithMap from "./SidebarWithMap";
import PeakExpectation from "../pages/PeakExpectation";
import ContactPage from "../pages/ContactPage";
import CategoryOverviewPage from "../pages/CategoryOverviewPage";
import News from "../pages/News";
import Sitemap from "../pages/Sitemap";
import Menu from "../components/Menu";
import Overlay from "../components/Overlay";
import Header from "../components/Header";
import Map from "../components/Map";
import Skiplink from "../components/Skiplink";
import DesktopMenu from "../components/DesktopMenu";
import { RWSContext } from "../context";
import { connect } from "react-redux";
import {
  getCategoryOverviewPage,
  loadCategoryOverviewPage,
} from "../components/CategoryOverviewPage/redux";
import { getCategories, loadCategories } from "../components/Category/redux";
import Loading from "../components/Loading";
import { Wrapper } from "../pages/ContactPage";
import CategoryPage from "../pages/CategoryPage";
import { PageViews } from "@piwikpro/react-piwik-pro";

function AppRoutes(props) {
  const location = useLocation();
  React.useEffect(() => {
    PageViews.trackPageView();
  }, [location]);
  return props.children;
}

const menuItems = [
  {
    title: "Actuele verkeersinfo",
    url: "/",
    exact: true,
  },
  {
    title: "Files",
  },
  {
    title: "Nieuws",
  },
  {
    title: "Spitsverwachting",
  },
  {
    title: "Wegwerkzaamheden",
  },
];

const Container = styled.div.attrs(() => ({
  className: "container",
}))`
  max-width: 100%;
`;

const Main = styled.main`
  flex: 1;
  display: flex;
  max-width: 100%;
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
      sidebarOpen: true,
      mapFullscreen: false,
      categoryOverviewPage: {},
      categories: [],
      loading: true,
      menuItems: menuItems,
    };
  }

  componentDidMount() {
    const { config = {}, loadCategoryOverviewPage, categoryOverview, loadCategories } = this.props;
    this.setViewportHeight();
    if (window) {
      window.addEventListener("resize", this.setViewportHeight);
    }
    // load category Overview if it's not already loaded
    if (categoryOverview.shouldLoad) {
      loadCategoryOverviewPage();
    }
    loadCategories();
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener("resize", this.setViewportHeight);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // set category overview page to state when new props are being received
    const { categoryOverview, categories } = nextProps;

    if (
      categoryOverview.page !== prevState.categoryOverviewPage ||
      JSON.stringify(categories.categories) !== JSON.stringify(prevState.categories)
    ) {
      return {
        categoryOverviewPage: categoryOverview.page,
        loading: categoryOverview.shouldLoad || categoryOverview.loading,
        categories: categories.categories,
      };
    }
    if (categoryOverview.loading) {
      return {
        loading: true,
      };
    }
    if (categoryOverview.error) {
      return {
        loading: false,
      };
    }
    return null;
  }

  setViewportHeight = () => {
    if (window && document) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  };

  toggleNavigation = () => {
    this.setState(state => ({
      navOpen: !state.navOpen,
    }));
  };

  hideNavigation = () => {
    this.setState({ navOpen: false });
  };

  toggleSidebar = () => {
    this.setState(state => ({ sidebarOpen: !state.sidebarOpen }));
  };

  onMapFullscreen = isFullscreen => {
    this.setState(state => ({ mapFullscreen: isFullscreen }));
  };

  render() {
    const {
      navOpen,
      sidebarOpen,
      mapFullscreen,
      categoryOverviewPage,
      categories,
      loading,
    } = this.state;

    return (
      <HelmetProvider>
        <Skiplink />
        <Helmet
          titleTemplate="%s | Rijkswaterstaat Verkeersinformatie"
          defaultTitle="Rijkswaterstaat - Verkeersinformatie"
        >
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content={`${this.props.config.apiUrl}/static/images/twittercard.png`}
          />
        </Helmet>
        <GlobalStyle />
        <RWSContext.Provider value={this.props.config}>
          <Router>
            <LastLocationProvider>
              <Overlay isOpen={navOpen} onClick={this.toggleNavigation} />
              <Header onHamburgerClick={this.toggleNavigation} navOpen={navOpen} />
              <DesktopMenu items={menuItems} />
              <Menu
                onLinkClick={this.toggleNavigation}
                isOpen={navOpen}
                hideNavigation={this.hideNavigation}
                items={menuItems}
              />
              <Main id="content">
                <AppRoutes>
                  <Switch>
                    <Route
                      path="/nieuws/"
                      render={props => (
                        <Container>
                          <News {...props} />
                        </Container>
                      )}
                    />
                    {categoryOverviewPage?.meta && (
                      <Route path={`/${categoryOverviewPage.meta.slug}/`}>
                        <Container>
                          <CategoryOverviewPage />
                        </Container>
                      </Route>
                    )}
                    {categoryOverviewPage?.meta &&
                      categories.map(category => (
                        <Route
                          key={category.id}
                          path={`/${category.slug}/`}
                          render={props => (
                            <Container>
                              <CategoryPage
                                category={category}
                                categoryOverviewSlug={`/${categoryOverviewPage.meta.slug}/`}
                                {...props}
                              />
                            </Container>
                          )}
                        />
                      ))}

                    <Route path="/contact/">
                      <Container>
                        <ContactPage />
                      </Container>
                    </Route>
                    <Redirect exact strict path="/spitsverwachting" to="/spitsverwachting/" />
                    <Route strict path="/spitsverwachting/">
                      <Container>
                        <PeakExpectation />
                      </Container>
                    </Route>
                    <Route path="/sitemap/">
                      <Container>
                        <Sitemap items={menuItems} />
                      </Container>
                    </Route>
                    {loading ? (
                      <Wrapper>
                        <Loading fullHeight />
                      </Wrapper>
                    ) : (
                      <Route>
                        <Container>
                          <SidebarWithMap
                            onToggle={this.toggleSidebar}
                            isOpen={sidebarOpen}
                            onMapFullscreen={this.onMapFullscreen}
                            mapFullscreen={mapFullscreen}
                          />
                        </Container>
                        <Map wide={!sidebarOpen} fullscreen={mapFullscreen} />
                      </Route>
                    )}
                  </Switch>
                </AppRoutes>
              </Main>
            </LastLocationProvider>
          </Router>
        </RWSContext.Provider>
      </HelmetProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    categoryOverview: getCategoryOverviewPage(state),
    categories: getCategories(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCategoryOverviewPage: () => dispatch(loadCategoryOverviewPage()),
    loadCategories: () => dispatch(loadCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
