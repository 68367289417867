import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import L from "leaflet";
import { Marker } from "react-leaflet";
import slugify from "../../utils/slugify";
import CameraMarkerIcon from "./icons/cameramarker.svg";
export function CameraMarker(props) {
  const { history } = props;
  const onMarkerClick = () => {
    const cameraUrl = `/cameras/${props.id}/${slugify(`${props.road} ${props.near}`)}`;
    // When currently showing a camera, replace the history. Otherwise add a new entry.
    // This ensures the back button on the camera works as intended.
    if (history.location.pathname.startsWith("/cameras/")) {
      history.replace(cameraUrl);
    } else {
      history.push({
        pathname: cameraUrl,
      });
    }
  };

  const icon = L.icon({
    iconUrl: CameraMarkerIcon,
    iconSize: [46, 46],
    iconAnchor: [23, 23],
  });

  return (
    <Marker
      key={props.id}
      position={[props.latitude, props.longitude]}
      icon={icon}
      data={props}
      onClick={props.clickable ? onMarkerClick : null}
      interactive={props.clickable}
    />
  );
}

CameraMarker.defaultProps = {
  clickable: true,
};

CameraMarker.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
};

export default withRouter(CameraMarker);
