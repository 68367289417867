import React from "react";
import { Redirect } from "react-router-dom";
import { parse } from "date-fns";
import { makeSearchUrl as makeRouteSearchUrl } from "./Search/RouteForm";
import { makeSearchUrl as makeRoadSearchUrl } from "./Search/RoadNumberForm";

export const hashToObject = str => {
  const afterQuestionMarkString = str.split("?").find(x => x.indexOf("=") >= 0);
  if (!afterQuestionMarkString) {
    return {};
  }
  const splitted = afterQuestionMarkString.split("&").map(x => x.split("="));
  const objects = splitted.map(x => ({ [x[0]]: x[1] }));
  return objects.reduce((result, current) => Object.assign(result, current), {});
};

function WidgetRedirect(props) {
  const { location: { hash } = {} } = props;
  if (Boolean(hash)) {
    const obj = hashToObject(hash);
    if (Object.keys(obj).length < 1) {
      return null;
    }
    const { van, naar, start, wegnummer } = obj;
    const date = parse(start, "dd-MM-yyyy", new Date());
    if (!van && !naar) {
      return <Redirect to={makeRoadSearchUrl(wegnummer, date)} />;
    }
    return <Redirect to={makeRouteSearchUrl(van, naar, date)} />;
  }
  return null;
}

export default WidgetRedirect;
