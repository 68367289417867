import React from "react";
import styled, { css } from "styled-components";
import logoDesktop from "./rijkswaterstaat-desktop.svg";
import logo from "./rijkswaterstaat.svg";
import colors from "../../common/colors";
import { Link } from "react-router-dom";
import SecondaryMenu from "../SecondaryMenu";
import { breakpoints } from "../../common/utils";
import { mq } from "../../common/utils";

const HamburgerStripe = styled.span`
  height: 2px;
  background: ${colors.gray900};
  margin-bottom: 5px;
  display: block;
  transition-property: transform, top;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  width: 25px;
  position: absolute;
`;

export const MenuLabel = styled.div`
  position: absolute;
  top: 44px;
  left: 16px;
  font-size: 11px;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  z-index: 1004; /* Give menulabel higher z-index than map component */
`;

const HamburgerButtonWrapper = styled.button.withConfig({
  shouldForwardProp: (prop) => !['navOpen', 'open'].includes(prop),
})`
  padding: 0;
  padding-left: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: transparent;
  border: 0;
  width: 60px;
  height: 79px;
  ${mq("desktop")`
    display: none;
  `}
  span:nth-child(1) {
    top: 24px;
    transform-origin: left center;
  }
  span:nth-child(2) {
    top: 31px;
    transform-origin: center center;
  }
  span:nth-child(3) {
    top: 38px;
    transform-origin: left center;
  }
  ${props =>
    props.navOpen &&
    `
    ${mq("desktop")`
      display: block;
    `}
  `}
  ${props =>
    props.open &&
    `
    height: 65px;
    span:nth-child(1) {
      transform: translate(4px, -2px) rotate(45deg);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: translate(4px, 2px) rotate(-45deg);
    }
  `}
`;

const Wrapper = styled.div`
  background: ${colors.white};
  height: 80px;
  border-bottom: 1px solid ${colors.gray400};
  text-align: center;
  position: relative;
  z-index: 3;
  ${mq("tablet")`
    height: 88px;
  `}
  ${mq("desktop")`
    border-bottom: none;
  `}
`;

const Picture = styled.picture``;

const LogoLink = styled(Link)`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-22px); /* visually center logo */
`;

function Logo() {
  return (
    <LogoLink style={{ display: "block" }} title="Naar homepage van RWS Verkeersinfo" to="/">
      <Picture>
        <source srcSet={logoDesktop} media={`(min-width: ${breakpoints.get("tablet")}px)`} />
        <img
          src={logo}
          alt="Rijkswaterstaat, Ministerie van infrastructuur en waterstaat"
          // width="255"
          // height="76"
        />
      </Picture>
    </LogoLink>
  );
}

const HeaderWrapper = styled.header.withConfig({
  shouldForwardProp: (prop) => !['open'].includes(prop),
})`
  ${mq("desktop")`
    z-index: 4;
  `}
  ${props =>
    props.open &&
    css`
      ${MenuLabel} {
        opacity: 0;
      }
      ${HamburgerButtonWrapper} {
        height: 65px;
        position: fixed;
        span:nth-child(1) {
          transform: translate(4px, -2px) rotate(45deg);
        }
        span:nth-child(2) {
          opacity: 0;
        }
        span:nth-child(3) {
          transform: translate(4px, 2px) rotate(-45deg);
        }
      }
    `}
`;

export function HamburgerButton(props) {
  return (
    <HamburgerButtonWrapper {...props}>
      <HamburgerStripe />
      <HamburgerStripe />
      <HamburgerStripe />
      {props.open ? null : <MenuLabel>Menu</MenuLabel>}
    </HamburgerButtonWrapper>
  );
}

function Header(props) {
  return (
    <HeaderWrapper open={props.navOpen}>
      <HamburgerButton
        aria-label={props.navOpen ? "Sluit menu" : "Open menu"}
        onClick={props.onHamburgerClick}
        navOpen={props.navOpen}
      />
      <Wrapper>
        <Logo />
      </Wrapper>
      <SecondaryMenu />
    </HeaderWrapper>
  );
}

export default Header;
