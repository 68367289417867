const widgetElement = document.getElementById("rwsverkeersinfowidget");
const rootElement = document.getElementById("root");

function getApiUrlStrategy() {
  if (widgetElement) {
    return "widget";
  }
  if (rootElement) {
    return "app";
  }

  return "unknown";
}

const apiUrlStrategy = getApiUrlStrategy();

function getWidgetApiUrl() {
  if (widgetElement) {
    return widgetElement.dataset.baseUrl;
  }
  return null;
}

function getAppApiUrl() {
  if (rootElement) {
    return rootElement.getAttribute("api-url");
  }
  return null;
}

function getApiUrl() {
  switch (apiUrlStrategy) {
    case "widget":
      return getWidgetApiUrl();
    case "app":
      return getAppApiUrl();
    default:
      console.log("Unknown API URL strategy");
      return null;
  }
}

export function getHereAutoCompleteUrl(query) {
  const apiUrl = getApiUrl();

  return `${apiUrl}/api/hereapi/autocomplete/?in=countryCode:NLD&lang=nl&q=${encodeURIComponent(
    query,
  )}`;
}

const Here = {
  // TODO Rename "here" (from HereAPI) to "pdok"
  // the trailing slash before the `?` avoids a Django redirect
  ReverseGeocodeUrl: (lat, lng) => `${getApiUrl()}/api/reverse_geocode/?at=${lat},${lng}`,
  AutocompleteUrl: query => `${getApiUrl()}/api/autocomplete/?q=${encodeURIComponent(query)}`,
  GeocodeUrl: query => `${getApiUrl()}/api/geocode_free/?q=${encodeURIComponent(query)}`,
};

export default Here;
