import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LazyLoad from "react-lazyload";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import ObstructionList from "../components/ObstructionList";
import Search from "../components/Search";
import NewsList from "../components/NewsList";
import WeatherAlert from "../components/WeatherAlert";
import Disclaimer from "../components/Disclaimer";
import PeakExpectationList from "../components/PeakExpectationList";
import RecentTweet from "../components/RecentTweet";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import { getCurrentObstructions } from "../components/Traffic/redux";
import { useShouldLoadTraffic } from "../components/Traffic/hooks";
import {
  setMapDisplay,
  setFilters,
  getFilters,
  setLegendaIsCollapsed,
} from "../components/Map/redux";
import MapWindow from "../components/Map/MapWindow";
import { useMobileDetection } from "../utils/hooks";
import { useLastLocation } from "react-router-last-location";
import { getFiltersBasedOnLastLocation } from "../utils/filters";
import { getListIsExpanded } from "../components/List/redux";

const HiddenH1 = styled.h1`
  visibility: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: 0;
`;

function Home(props) {
  const allVisible = useSelector(getListIsExpanded);
  const result = useSelector(store => getCurrentObstructions(store, allVisible));
  const dispatch = useDispatch();
  const initialFilters = ["traffic", "incidents", "laneclosed"];
  const activeFilters = useSelector(getFilters);
  const lastLocation = useLastLocation();
  const filters = getFiltersBasedOnLastLocation(initialFilters, activeFilters, lastLocation);

  useShouldLoadTraffic(result.shouldLoad);

  const isMobile = useMobileDetection();

  useEffect(() => {
    dispatch(setMapDisplay("traffic"));
    dispatch(setFilters(filters));
    dispatch(setLegendaIsCollapsed(true));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <SubHeader />
      <Helmet>
        <title>Actuele verkeersinfo</title>
        <meta
          name="description"
          content="Bekijk alle ontwikkelingen in het verkeer. Rechtstreeks van Rijkswaterstaat. 24 uur per dag, 7 dagen per week actuele informatie. Files, ongelukken en meer."
        />
      </Helmet>
      <HiddenH1>Actuele verkeersinfo</HiddenH1>
      <WeatherAlert />
      <MapWindow onMapFullscreen={props.onMapFullscreen} isFullscreen={props.mapFullscreen} />
      <ObstructionList {...result} />
      <Search route />
      <NewsList />
      <PeakExpectationList />
      <LazyLoad overflow={!isMobile} offset={100} height={100}>
        <RecentTweet />
      </LazyLoad>
      <Disclaimer />
      <Footer titleElement="div" />
    </React.Fragment>
  );
}

export default Home;
