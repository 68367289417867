import React, { useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { breakpoints } from "../common/utils";
import {
  getCategoryOverviewPage,
  loadCategoryOverviewPage,
} from "../components/CategoryOverviewPage/redux";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import NewsListItem from "../components/NewsListItem";
import RichText from "../components/RichText";
import SubHeader from "../components/SubHeader";
import { RWSContext } from "../context";
import { Page, Wrapper } from "./ContactPage";
import { NewsListing } from "./News";
import { Img } from "./NewsArticle";
import colors from "../common/colors";
import { mq } from "../common/utils";
import { ReactComponent as ChevronRight } from "../components/Datepicker/arrow-right.svg";

const CategoryColumns = styled.div`
  display: grid;
  grid-gap: 2rem;
  width: 100%;
  ${mq("desktop")`
    grid-template-columns: 250px 1fr;
  `}
`;

const Title = styled.h1`
  ${mq("desktop")`
    padding-left: calc(250px + 2rem);  /* grid column width + grid gap */
  `}
`;

const SubTitle = styled.h2`
  margin-bottom: 0;
`;

const Nav = styled.nav`
  background: ${colors.gray100};
  padding: 1rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  li {
    display: flex;
    padding-left: 14px;
    position: relative;
    svg {
      width: 10px;
      height: 10px;
      color: ${colors.black};
      position: absolute;
      left: 0;
      top: 7px;
    }
  }
  a {
    font-weight: bold;
    color: ${colors.hemelsblauw};
    text-decoration: none;
    line-height: 1.5rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const CategoryNavWrapper = styled.div`
  ${mq("desktop")`
  padding-top: 80px;
`}
`;

const Link = styled(RouterLink)`
  color: ${colors.hemelsblauw};
  font-weight: bold;
  text-decoration: none;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
  svg {
    width: 12px;
    height: 10px;
    margin-left: 2px;
    display: inline-block;
  }
`;

const CategoryNavigation = ({ categories }) => {
  return (
    <CategoryNavWrapper>
      <Nav id="categorySubmenu">
        <p className="title">Snel naar:</p>
        <ul>
          {categories.map(({ category }) => (
            <li key={category.slug}>
              <ChevronRight />
              <Link to={`/${category.slug}/`}>{category.name}</Link>
            </li>
          ))}
        </ul>
      </Nav>
    </CategoryNavWrapper>
  );
};

const CallToAction = styled.div`
  padding: 0.75rem 0 0.75rem 0.5rem;
  border-bottom: 1px solid ${colors.gray200};
  a {
    color: ${colors.hemelsblauw};
    font-weight: bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

function CategoryOverviewPage() {
  const { loaded, shouldLoad, page } = useSelector(getCategoryOverviewPage);
  const dispatch = useDispatch();
  const { apiUrl } = useContext(RWSContext);

  useEffect(() => {
    if (shouldLoad) {
      dispatch(loadCategoryOverviewPage());
    }
  }, [dispatch, shouldLoad]);

  if (shouldLoad && !loaded) {
    return (
      <Wrapper>
        <Loading fullHeight />
      </Wrapper>
    );
  }

  if (Object.keys(page).length === 0) {
    return null;
  }

  const headerImage = page.header_image || {};
  const { categories } = page;
  return (
    <Page>
      <Helmet>
        <title>{page.meta.seo_title ?? page.title}</title>
        <meta name="description" content={page.meta.search_description} />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.meta.search_description} />
        {Object.keys(headerImage).length > 0 && (
          <meta property="og:image" content={`${apiUrl}${headerImage.header_meta.url}`} />
        )}
      </Helmet>
      <SubHeader />
      {Object.keys(headerImage).length > 0 && (
        <picture>
          <source
            srcSet={`${apiUrl}${headerImage.header_large.url}`}
            media={`(min-width: ${breakpoints.get("desktop")}px)`}
          />
          <Img
            src={`${apiUrl}${headerImage.header_medium.url}`}
            alt={headerImage.header_medium.alt}
          />
        </picture>
      )}
      <Wrapper $large>
        <Title>{page.title}</Title>
        <CategoryColumns>
          <CategoryNavigation categories={categories} />
          <div>
            <RichText content={page.intro} />
            {categories.map(({ category, articles }) => {
              return (
                <div
                  key={category.slug}
                  style={{ marginBottom: "2rem" }}
                  data-testid="category-container"
                >
                  <SubTitle>{category.name}</SubTitle>
                  <RichText content={category.intro} />
                  <NewsListing>
                    {articles.map(article => (
                      <NewsListItem showLead key={article.id} {...article} titleTag="h3" />
                    ))}
                  </NewsListing>
                  <CallToAction>
                    <Link to={`/${category.slug}/`}>
                      Bekijk alle artikelen over{" "}
                      <nobr>
                        '{category.name}'
                        <ChevronRight />
                      </nobr>
                    </Link>
                  </CallToAction>
                </div>
              );
            })}
          </div>
        </CategoryColumns>
      </Wrapper>
      <Footer fullWidth />
    </Page>
  );
}

export default CategoryOverviewPage;
