import React from "react";
import AriaModal from "react-aria-modal";
import styled from "styled-components";
import colors from "../common/colors";
import { mq } from "../common/utils";
import { ReactComponent as CloseIcon } from "../common/icons/close_filter.svg";

const ModalWrapper = styled.div`
  background-color: ${colors.white};
  padding: 27px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100vw;
  animation-fill-mode: forwards;
  opacity: 1;
  transform: translateY(100%);
  transition: transform 0.25s ease-out;
  &.has-entered {
    transform: translateY(0);
    transition: transform 0.25s ease-out;
  }

  ${mq("tablet")`
    width: auto;
    min-width: 650px;
    animation: none;
    border-radius: 8px;
    position: relative;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    transform: none;
    &.has-entered {
      animation: none;
      opacity: 1;
    }
  `}
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModalCloseButton = styled.button`
  border: 0;
  background: transparent;
  width: 20px;
  height: 20px;
  padding: 0;
  color: ${colors.hemelsblauw};
  svg {
    width: 14px;
  }
  &:hover {
    cursor: pointer;
  }
`;

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
    };

    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);
    this.onModalEnter = this.onModalEnter.bind(this);
  }

  activateModal = () => {
    this.setState({ modalActive: true });
  };

  deactivateModal = () => {
    this.setState(
      {
        modalHasEntered: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            modalActive: false,
          });
          this.props.exit();
        }, 300);
      },
    );
  };

  onModalEnter = () => {
    this.setState({ modalHasEntered: true });
  };

  render() {
    let dialogContentClass = "";
    let underlayStyle = {
      background: "rgba(0,0,0,0)",
      transition: "background 0.25s ease-in-out",
    };
    if (this.state.modalHasEntered) {
      dialogContentClass += " has-entered";
      underlayStyle = { background: "rgba(0,0,0,0.5)" };
    }
    return (
      <AriaModal
        onEnter={this.onModalEnter}
        onExit={this.deactivateModal}
        titleText={this.props.title}
        underlayStyle={underlayStyle}
        verticallyCenter={true}
      >
        <ModalWrapper className={dialogContentClass}>
          <ModalHeader>
            <h2>{this.props.title}</h2>
            <ModalCloseButton aria-label="Sluit venster" onClick={this.deactivateModal}>
              <CloseIcon />
            </ModalCloseButton>
          </ModalHeader>
          {this.props.content}
        </ModalWrapper>
      </AriaModal>
    );
  }
}

export default Modal;
