import axios from "axios";
import * as Sentry from "@sentry/browser";

const createEndpointUrl = url => {
  let endpoint = url[0] === "/" ? url.slice(1) : url;
  return endpoint.startsWith("api/") ? endpoint : `api/${endpoint}`;
};

const apiMiddleware = () => next => action => {
  // If its not an core-app async action, pass next.
  if (typeof action.types === "undefined") {
    return next(action);
  }

  const [pendingType, successType, errorType] = action.types;

  // construct the request
  const {
    url,
    method = "GET",
    success = () => {},
    error = () => {},
    payload,
    types,
    ...rest
  } = action;

  const errorCallback = error;

  const headers = {
    "X-Requested-With": "XMLHttpRequest",
  };
  let requestUrl = url;
  const { eov: { API: baseURL } = {} } = window;
  const request = axios({
    baseURL,
    url: createEndpointUrl(requestUrl),
    method,
    [method === "GET" ? "params" : "data"]: payload,
    headers,
    responseType: "json",
  });

  // Dispatch the pending action
  next({
    type: pendingType,
    requestData: { requestUrl: url },
  });

  // fire the request, when done pass next() the succes action type
  request
    .then(response => {
      next({
        type: successType,
        payload: response.data,
        headers: response.headers,
        requestData: { ...payload, requestUrl: url }, // to access these props later on
        ...rest,
      });
      success(response.data, response.headers); // fire any additional success callback
    })
    .catch(error => {
      let errorString = `${error.message}: ${url}`;
      next({
        type: errorType,
        error: error,
        requestData: {
          ...payload,
        },
      });
      if (error && error.response && error.response.status !== 404) {
        Sentry.configureScope(function(scope) {
          scope.setExtra("requestUrl", error?.request?.responseURL);
          scope.setExtra("responseData", JSON.stringify(error?.response?.data || {}));
        });
        Sentry.captureException(error);
      }
      errorCallback(errorString);
    });
};

export default apiMiddleware;
