const actionTypes = {
  LOAD: "categoryoverviewpage/load",
  LOAD_SUCCESS: "categoryoverviewpage/load_success",
  LOAD_FAILED: "categoryoverviewpage/load_failed",
};

const initialState = {
  loading: false,
  loaded: false,
  page: {},
  error: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        error: {},
        loading: true,
        loaded: false,
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        error: {},
        loading: false,
        loaded: true,
        page: {
          ...state.page,
          ...(action.payload.items?.[0] ?? {}),
        },
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export const getCategoryOverviewPage = state => {
  const { categoryoverviewpage: { loading, loaded, page, error = {} } = {} } = state;

  const shouldLoad = !loading && !loaded && Object.keys(error).length === 0;
  const result = {
    page,
    loading,
    loaded,
    shouldLoad,
    error,
  };
  return result;
};

export const loadCategoryOverviewPage = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: "/api/v2/pages/",
  payload: {
    type: "website.CategoryOverviewPage",
    fields: ["_", "show_in_menus", "title", "slug", "categories"].join(","),
  },
});
