function rewriteTargetToFrontendURL(targetUrl) {
  /* Rewrite the road/route target URL to use the frontend domain instead of the api domain.

  This is useful only for the widget, which is configured to use the api domain (api.rwsverkeersinfo.nl).

  We found that we could solve the CORS issues by setting data-base-url="https://api.rwsverkeersinfo.nl"
  in widget.html.
  However, this caused sending the form to lead to https://api.rwsverkeersinfo.nl/wegnummer/A1/2023-12-21/.
  That does not work. So for PRD, we rewrite the URL to use the www subdomain.
  */
  let rewrittenTargetURL;

  if (targetUrl.includes("api.rwsverkeersinfo.nl")) {
    // production environment
    rewrittenTargetURL = targetUrl.replace("api.rwsverkeersinfo.nl", "www.rwsverkeersinfo.nl");
  } else if (targetUrl.includes("eov-api.acceptatie.rws.nl")) {
    // acceptance environment
    rewrittenTargetURL = targetUrl.replace("eov-api.acceptatie.rws.nl", "eov.acceptatie.rws.nl");
  } else if (targetUrl.includes("localhost:8000")) {
    // development environment
    rewrittenTargetURL = targetUrl.replace("localhost:8000", "localhost:3000");
  } else {
    rewrittenTargetURL = targetUrl;
  }
  return rewrittenTargetURL;
}

export { rewriteTargetToFrontendURL };
