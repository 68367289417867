import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import colors from "../common/colors";
import { breakpoints, mq } from "../common/utils";
import ErrorBoundary from "./ErrorBoundary";
import Loading from "./Loading";
import { ReactComponent as ChevronRight } from "./Datepicker/arrow-right.svg";

const Wrapper = styled.div`
  border-bottom: 1px solid ${colors.gray200};
  background: ${colors.white};
  ${props => (props.newsList ? `padding: 2rem 1rem 0.5rem;` : `padding: 2rem 1rem;`)}
  ${mq("tablet")`
    padding: 2rem 1rem;
  `}
  ${props =>
    props.desktopGrid &&
    mq("desktop")`
      padding-bottom: 1rem;
  `}
`;

const List = styled.div`
  display: flex;
  padding-left: 1rem;
  flex-wrap: wrap;
`;

const ListWrapper = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row;
  flex-wrap: wrap;
  ${mq("tablet")`
    flex-direction: row;
    align-items: center;
  `}
  margin-bottom: 1.5rem;
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 400;
`;

const CardWrapper = styled.div`
  padding-right: 1rem;
  padding-bottom: 1rem;
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  @media (max-width: ${breakpoints.get("tablet")}px) {
    padding-bottom: 0.75rem;
  }
  ${props =>
    props.mobileGrid &&
    `
      width: 50%;
    `}
  ${mq("desktop")`
    width: 100%;
  `}
  ${props =>
    props.tabletGrid &&
    mq("tablet")`
      width: 50%;
      display: flex;
      max-width: none;
    `}
  ${props =>
    props.desktopGrid
      ? mq("desktop")`
      width: 50%;
      display: flex;
    `
      : mq("desktop")`
      width: 100%;
    `}
`;

const Link = styled(RouterLink)`
  color: ${colors.hemelsblauw};
  white-space: nowrap;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  padding-right: 16px;
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
  &:hover {
    text-decoration: underline;
  }
  svg {
    width: 12px;
    height: 12px;
    margin-left: 4px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

function CardList(props) {
  const Card = props.card;
  const { desktopGrid, tabletGrid, mobileGrid, newsList, items = [] } = props;

  const styleProps = {
    desktopGrid,
    tabletGrid,
    mobileGrid,
    newsList,
  };

  return (
    <Wrapper {...styleProps}>
      <Header>
        <Title>{props.title}</Title>
        <Link to={props.link || ""}>
          {props.linkText}
          <ChevronRight />
        </Link>
      </Header>
      {props.loading ? (
        <Loading text="Bezig met laden..." />
      ) : (
        <ErrorBoundary>
          <ListWrapper {...styleProps} tabIndex="0">
            <List>
              {items.map(item => (
                <CardWrapper key={item.id} {...styleProps}>
                  <Card {...item} />
                </CardWrapper>
              ))}
              {items.length === 0 && <p>Geen resultaten gevonden.</p>}
            </List>
          </ListWrapper>
        </ErrorBoundary>
      )}
    </Wrapper>
  );
}

CardList.defaultProps = {
  mobileGrid: true,
};

export default CardList;
