import React from "react";
import styled from "styled-components";
import colors from "../../common/colors";

const LegendList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
`;

const LegendItem = styled.li`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: ${colors.gray900};
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    background: ${props => props.color};
    margin-right: 6px;
  }
  & + & {
    margin-left: 12px;
  }
`;

const labels = {
  expected: "Verwacht",
  average: "Gemiddeld",
};

const renderLegend = props => {
  const { payload = [] } = props;
  return (
    <LegendList>
      {payload.reverse().map(x => (
        <LegendItem key={x.dataKey} color={x.color}>
          {labels[x.dataKey]}
        </LegendItem>
      ))}
    </LegendList>
  );
};

export default renderLegend;
