const actionTypes = {
  LOAD: "disclaimer/load",
  LOAD_SUCCESS: "disclaimer/load_success",
  LOAD_FAILED: "disclaimer/load_failed",
};

export function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        loaded: true,
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export const loadDisclaimer = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: `/disclaimer/`,
});

export const getDisclaimer = state => {
  const {
    disclaimer: { loading, loaded, disclaimer, error = {} },
  } = state;
  const shouldLoad = !loading && !loaded && Object.keys(error).length === 0;

  return {
    loading,
    shouldLoad,
    disclaimer,
  };
};
