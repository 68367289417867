import { processPayload } from "./processPayload";

export const makeDefaultReducer = actions => {
  const initialState = {
    loading: false,
    loaded: false,
    items: {},
    error: {},
  };

  return function reducer(state = initialState, action) {
    switch (action.type) {
      case actions.LOAD:
        return {
          ...state,
          loading: true,
          loaded: false,
          error: {},
        };
      case actions.LOAD_SUCCESS:
        return {
          ...state,
          error: {},
          items: {
            ...state.items,
            ...processPayload(action.payload),
          },
          count: action.payload.count,
          loading: false,
          loaded: true,
        };
      case actions.LOAD_FAILED:
        return {
          ...state,
          error: action.error,
          loading: false,
          loaded: false,
        };
      case actions.LOAD_ITEM:
        return {
          ...state,
          loading: true,
          loaded: false,
          error: {},
        };
      case actions.LOAD_ITEM_SUCCESS:
        return {
          ...state,
          loading: false,
          loaded: false,
          error: {},
          items: {
            ...state.items,
            [action.payload.id]: {
              ...state.items[action.payload.id],
              ...action.payload,
              loaded: true,
            },
          },
        };
      case actions.LOAD_ITEM_FAILED:
        return {
          ...state,
          loading: false,
          loaded: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
};
