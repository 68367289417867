import React from "react";
import styled, { css } from "styled-components";
import colors from "../common/colors";

const MatrixBoardHolder = styled.div`
  display: flex;
  justify-content: center;
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 33%,
    ${colors.gray300} 33%,
    ${colors.gray300} 66%,
    transparent 66%
  );
  background-size: 1px 18px;
  background-repeat: repeat-x;
  background-position: left 50%;
  margin-bottom: 1rem;
`;

const Board = styled.div`
  background: ${colors.gray900};
  border-radius: 3px;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-weight: bold;
  font-size: 18px;
  ${props =>
    props.cross &&
    css`
      color: ${colors.signaalkleuren.rood};
      font-size: 3rem;
    `}
  & + & {
    margin-left: 0.25rem;
  }
`;

const EindeRestricties = styled.span`
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 50%;
  transform: rotate(45deg);
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 3px;
    box-shadow: inset 0 0 0 32px;
  }
`;

const RoodKruis = styled.span`
  width: 20px;
  height: 20px;
  position: relative;
  transform: rotate(45deg);
  &::before,
  &::after {
    content: "";
    height: 3px;
    width: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${colors.signaalkleuren.rood};
  }
  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;

const Verdrijvingspijl = styled.span`
  width: 20px;
  height: 20px;
  position: relative;
  transform: scale(0.9) ${props => props.left && "scaleX(-1)"};
  color: #fff;
  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border-bottom: 3px solid currentColor;
    border-right: 3px solid currentColor;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &::after {
    content: "";
    width: 16px;
    height: 3px;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(45deg) translateX(-9px);
    transform-origin: 100% 0;
    background: currentColor;
  }
`;

const VallendeGroenePijl = styled(Verdrijvingspijl)`
  color: ${colors.signaalkleuren.groen};
  &::before {
    transform: rotate(45deg);
    left: -2px;
    bottom: -2px;
    transform-origin: bottom right;
  }
  &::after {
    width: 3px;
    height: 13px;
    position: absolute;
    bottom: 8px;
    right: 50%;
    transform: translateX(50%);
  }
`;

export function MatrixBoards(props) {
  const { signs = "" } = props;
  const lanes = signs.split("|");
  if (lanes.length === 0) {
    return null;
  }

  function laneDisplay(lane) {
    if (["-x-", "X"].includes(lane)) {
      // Rood kruis
      return <RoodKruis />;
    } else if (lane === "BLK") {
      // Blank
      return "";
    } else if (lane === "000") {
      // Overruling Blank
      return "";
    } else if (lane === "<--") {
      // Verdrijvingspijl links
      return <Verdrijvingspijl left />;
    } else if (lane === "-->") {
      // Verdrijvingspijl rechts
      return <Verdrijvingspijl right />;
    } else if (lane === "VGP") {
      // Vallende groene pijl
      return <VallendeGroenePijl />;
    } else if (lane === "ERE") {
      // Einde restricties
      return <EindeRestricties />;
    } else {
      return parseInt(lane, 10).toString(); // 030, 050, 060, 070, 080, 090, 100, 120
    }
  }

  return (
    <MatrixBoardHolder>
      {lanes.map((lane, index) => {
        return (
          <Board key={index} cross={lane === "-x-"}>
            {laneDisplay(lane)}
          </Board>
        );
      })}
    </MatrixBoardHolder>
  );
}
