import React from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";

export const makeIcon = letter => {
  return L.divIcon({
    className: "letter-marker",
    html: `<span>${letter}</span>`,
    iconSize: [25, 25],
    iconAnchor: [25, 12],
  });
};

function RouteMarker(props) {
  const { letter } = props;
  const { latitude = 0, longitude = 0 } = props || {};

  const icon = makeIcon(letter);

  return <Marker position={[latitude, longitude]} icon={icon} interactive={false} />;
}

export default RouteMarker;
