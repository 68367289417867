import uniqBy from "lodash.uniqby";

const PAGE_SIZE = 5;

const actionTypes = {
  LOAD: "news/load",
  LOAD_SUCCESS: "news/load_success",
  LOAD_FAILED: "news/load_failed",
  LOAD_ITEM: "news/load_item",
  LOAD_ITEM_SUCCESS: "news/load_item_success",
  LOAD_ITEM_FAILED: "news/load_item_failed",
};

const initialState = {
  loading: false,
  loaded: false,
  items: [], // list
  articles: {}, // full articles
  error: {},
};
export const reducer = function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        error: {},
        items: uniqBy([...state.items, ...action.payload.items], "id"),
        count: action.payload.meta.total_count,
        loading: false,
        loaded: true,
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case actionTypes.LOAD_ITEM:
      return {
        ...state,
        error: {},
        articles: {
          ...state.articles,
          [action.requestData.slug]: {
            ...state.articles[action.requestData.slug],
            loading: true,
            loaded: false,
          },
        },
      };
    case actionTypes.LOAD_ITEM_SUCCESS:
      return {
        ...state,
        error: {},
        articles: {
          ...state.articles,
          [action.requestData.slug]: {
            ...state.articles[action.requestData.slug],
            loading: false,
            loaded: true,
            ...action.payload.items[0],
          },
        },
      };
    case actionTypes.LOAD_ITEM_FAILED:
      return {
        ...state,
        error: action.error,
        ...state.articles,
        [action.requestData.slug]: {
          ...state.articles[action.requestData.slug],
          loading: false,
          loaded: false,
        },
      };
    default:
      return state;
  }
};

export const loadNews = (page = 1, categoryId = "") => {
  const limit = PAGE_SIZE;
  const offset = (page - 1) * PAGE_SIZE;
  return {
    types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
    method: "GET",
    url: "/api/v2/pages/",
    payload: {
      type: "website.NewsArticlePage",
      fields: ["date", "intro", "list_image", "categories(title)"].join(","),
      limit,
      offset,
      order: "-date",
      categories: categoryId ? categoryId : undefined,
    },
  };
};

export const getHomeNews = state => {
  const result = getNewsList(state, 1);
  return {
    ...result,
    items: result.items.slice(0, 4),
  };
};

export const getNewsList = (state, page = 1, category = null) => {
  const numItems = page * PAGE_SIZE;
  const {
    news: { items, loading, loaded, error = {}, count },
  } = state;
  const sortByDate = (a, b) => {
    if (a.date < b.date) {
      return 1;
    }
    if (a.date === b.date) {
      // with the same date, sort by Pk
      return a.id < b.id ? 1 : -1;
    }
    return -1;
  };

  const filterFunction = item =>
    category ? (item.categories || []).some(x => x?.id === category) : true;
  const visibleItems = items
    .sort(sortByDate)
    .filter(filterFunction)
    .slice(0, numItems); // take the first numItems items

  const shouldLoad = !loading && !loaded && Object.keys(error).length === 0;
  return {
    items: visibleItems,
    loading,
    loaded,
    error,
    count,
    shouldLoad,
  };
};

export const getNewsItem = (state, slug) => {
  const {
    news: { articles, error },
  } = state;
  const item = articles[slug] || {};
  const { loading, loaded } = item;

  const shouldLoad =
    !loading && !Object.keys(item).includes("body") && Object.keys(error).length === 0;
  const result = {
    item,
    loading,
    loaded,
    shouldLoad,
    error,
  };
  return result;
};

export const loadArticle = slug => ({
  types: [actionTypes.LOAD_ITEM, actionTypes.LOAD_ITEM_SUCCESS, actionTypes.LOAD_ITEM_FAILED],
  method: "GET",
  url: `/api/v2/pages/`,
  payload: {
    slug: slug,
    type: "website.NewsArticlePage",
    fields: [
      "date",
      "intro",
      "header_image",
      "list_image",
      "body",
      "related_news",
      "last_modified",
      "categories(title,slug)",
    ].join(","),
  },
});
