import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { loadNews, getNewsList } from "../components/News/redux";
import colors from "../common/colors";
import ErrorBoundary from "../components/ErrorBoundary";
import NewsListItem from "../components/NewsListItem";
import Footer from "../components/Footer";
import SubHeader from "../components/SubHeader";
import { Helmet } from "react-helmet-async";
import NewsArticle from "./NewsArticle";
import Button from "../components/Button";
import Loading from "../components/Loading";
import ScrollToTop from "../components/ScrollToTop";
import RichText from "../components/RichText";

const Page = styled.div`
  position: relative;
  width: 100%;
`;

export const NewsListing = styled.ul`
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${colors.gray200};
  list-style: none;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 1rem 2rem;
`;

const Title = styled.h1`
  margin-top: 2rem;
`;

const ReadMoreWrapper = styled.div`
  padding-top: 1.5rem;
`;

export function NewsList({ categoryId, pageTitle, description, intro }) {
  const [page, setPage] = useState(1);
  const result = useSelector(state => getNewsList(state, page, categoryId));
  const dispatch = useDispatch();
  const [focusTargetIndex, setFocusTargetIndex] = useState(-1);

  const focusRef = useRef();

  useEffect(() => {
    dispatch(loadNews(page, categoryId));
  }, [dispatch, page, categoryId]);

  const onReadMoreClick = evt => {
    evt.preventDefault();
    // The current number of items is the index of the item to be focused.
    // So store that number, before loading more items:
    setFocusTargetIndex(result.items.length);
    setPage(page => page + 1);
  };

  // If the number of items changes, focus on the focusRef item,
  // _after_ the DOM has been rendered:
  useLayoutEffect(() => {
    if (focusRef && focusRef.current) {
      focusRef.current.focus();
    }
  }, [result.items.length]);

  return (
    <Inner>
      <Helmet>
        <title>{pageTitle || "Nieuws over het verkeer in Nederland"}</title>
        <meta
          name="description"
          content={
            description ||
            "Lees hier het nieuws over het verkeer in Nederland. Van ongelukken op de snelweg tot wegwerkzaamheden."
          }
        />
      </Helmet>
      <Title style={intro ? { marginBottom: "1rem" } : {}}>
        {pageTitle || "Nieuws: alles over verkeer"}
      </Title>
      {intro && <RichText content={intro} />}
      {result.loading && result.items.length === 0 ? (
        <Loading />
      ) : (
        <React.Fragment>
          <NewsListing>
            {result.items.map((item, index) => (
              <NewsListItem
                showLead
                key={item.id}
                {...item}
                // only assign a ref if the index matches the focus target index.
                ref={index === focusTargetIndex ? focusRef : undefined}
              />
            ))}
          </NewsListing>
          {result.items.length < result.count && (
            <ReadMoreWrapper>
              <Button block outline onClick={onReadMoreClick}>
                + Toon meer
              </Button>
            </ReadMoreWrapper>
          )}
        </React.Fragment>
      )}
    </Inner>
  );
}

function News(props) {
  return (
    <Page>
      <ScrollToTop />
      <SubHeader />
      <ErrorBoundary>
        <Route path={`${props.match.url}/:slug/`} component={NewsArticle} />
        <Route path={`${props.match.url}/`} exact component={NewsList} />
      </ErrorBoundary>
      <Footer fullWidth />
    </Page>
  );
}

export default News;
