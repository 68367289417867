import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import RoadField from "../Road/RoadField";
import Datepicker from "../Datepicker";
import Button from "../Button";
import { format } from "date-fns";
import { rewriteTargetToFrontendURL } from "./RewriteWidgetUrl";

export const makeSearchUrl = (road, dateObj = new Date()) => {
  const date = format(dateObj, "yyyy-MM-dd");
  return `/wegnummer/${encodeURIComponent(road)}/${date}`;
};

function RoadNumberForm(props) {
  const { loading, error = {} } = useSelector(state => state.route);
  const { items: roads = [] } = useSelector(state => state.roads);
  const [road, setRoad] = useState(props.road);
  const [date, setDate] = useState(props.date || new Date());
  const [submitting, toggleSubmitting] = useState(loading);
  const [roadError, setRoadError] = useState(error.road);

  const handleSubmit = evt => {
    evt.preventDefault();
    if (!road) {
      setRoadError({ error: "Er is geen wegnummer ingevuld." });
    } else if (!roads.map(x => x.number.toLowerCase()).includes(road.toLowerCase())) {
      setRoadError({ error: "Deze weg is niet in beheer van Rijkswaterstaat." });
    } else {
      const searchUrl = makeSearchUrl(road, date);
      if (props.target) {
        const targetUrl = `${props.target}${searchUrl}`;

        // Rewrite the road's target URL to use the frontend domain instead of the api domain.
        let rewrittenTargetURL = rewriteTargetToFrontendURL(targetUrl);

        if (props.newWindow) {
          window.open(rewrittenTargetURL);
        } else {
          window.location.href = rewrittenTargetURL;
        }
      } else {
        props.history.push(searchUrl);
      }
    }
  };

  useEffect(() => {
    if (road) {
      setRoadError({ error: "" });
    }
  }, [road]);

  useEffect(() => {
    toggleSubmitting(loading);
  }, [loading]);

  return (
    <form onSubmit={handleSubmit}>
      <RoadField
        label="Wegnummer"
        value={road}
        error={roadError}
        onChange={setRoad}
        disabled={submitting}
        wideLabel
      />
      <Datepicker label="Datum" onChange={setDate} value={date} />
      <Button large type="submit" style={{ marginTop: "24px" }} disabled={submitting}>
        {submitting ? "Bezig met zoeken..." : "Zoeken"}
      </Button>
    </form>
  );
}

export default withRouter(RoadNumberForm);
