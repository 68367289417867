import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubHeader from "../components/SubHeader";
import styled from "styled-components";
import ObstructionList from "../components/ObstructionList";
import MapWindow from "../components/Map/MapWindow";
import Footer from "../components/Footer";
import Disclaimer from "../components/Disclaimer";
import { Helmet } from "react-helmet-async";
import { getRoadworks, getRoadworksDate, setRoadworksDate } from "../components/Roadworks/redux";
import { useShouldLoadRoadworks } from "../components/Roadworks/hooks";
import {
  setFilters,
  getFilters,
  setMapDisplay,
  setLegendaIsCollapsed,
} from "../components/Map/redux";
import Search from "../components/Search";
import Datepicker from "../components/Datepicker";
import { useMobileDetection } from "../utils/hooks";
import { useLastLocation } from "react-router-last-location";
import { getFiltersBasedOnLastLocation } from "../utils/filters";

const Wrapper = styled.div`
  padding: 2rem 1rem 0;
  background: #fff;
`;
const Title = styled.h1`
  margin-bottom: 1rem;
`;

const Intro = styled.p`
  margin-bottom: 0;
  padding-bottom: 1.5rem;
  font-size: 18px;
`;

function RoadWorks(props) {
  const isMobile = useMobileDetection();
  const dispatch = useDispatch();
  const date = useSelector(getRoadworksDate);
  const result = useSelector(getRoadworks);
  const initialFilters = ["roadworks"];
  const activeFilters = useSelector(getFilters);
  const lastLocation = useLastLocation();
  const filters = getFiltersBasedOnLastLocation(initialFilters, activeFilters, lastLocation);

  useShouldLoadRoadworks(result.shouldLoad);

  const setDate = value => {
    if (value !== date) {
      dispatch(setRoadworksDate(value));
      dispatch(setLegendaIsCollapsed(true));
    }
  };

  useEffect(() => {
    dispatch(setMapDisplay("roadworks"));
    dispatch(setFilters(filters));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Helmet>
        <title>Zijn er wegwerkzaamheden?</title>
        <meta
          name="description"
          content="Bekijk hier alle wegwerkzaamheden van Rijkswaterstaat. 24 uur per dag, 7 dagen per week actuele informatie. Beter geïnformeerd op weg."
        />
      </Helmet>
      <SubHeader />
      <Wrapper>
        <Title>Wegwerkzaamheden</Title>
        <Intro>
          Ontdek waar we aan de rijkswegen werken. Bekijk het volledige overzicht of vul hieronder
          direct je route in.
        </Intro>
      </Wrapper>
      <Search route />
      {isMobile && <MapWindow />}
      <Wrapper>
        <Datepicker label="Datum" onChange={setDate} value={date} id="roadworks_datepicker" />
      </Wrapper>
      <ObstructionList {...result} limited={false} />
      <Disclaimer />
      <Footer />
    </>
  );
}

export default RoadWorks;
