import React, { useEffect, useRef, useState } from "react";
import FocusTrap from "focus-trap-react";
import { useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import colors from "../common/colors";
import { getMenuItems } from "../components/MenuItems/redux";
import slugify from "../utils/slugify";
import { ReactComponent as ChevronRight } from "./Datepicker/arrow-right.svg";
import SecondaryMenu from "./SecondaryMenu";
import { HamburgerButton } from "../components/Header";

const linkStyling = `
text-decoration: none;
display: block;
padding-bottom: 12px;
padding-top: 12px;
padding-left: 1rem;
transition: background 0.25s ease-in-out;
color: ${colors.logoblauw};
&.active {
  color: ${colors.hemelsblauw};
}
&:hover {
  background: ${colors.gray100};
}
`;

export const MenuLink = styled(NavLink)`
  ${linkStyling}
`;

const CollapseButton = styled.button.attrs(() => ({
  type: "button",
})).withConfig({
  shouldForwardProp: (prop) => !['toggled'].includes(prop),
})`
  background: transparent;
  border: none;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 1rem;
  ${linkStyling}
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 7px;
    margin-left: 0.5rem;
    transform: rotate(90deg);
  }
  ${props =>
    props.toggled &&
    `
    svg {
      transform: rotate(270deg);
    }
  `}
`;

const SubMenuLink = styled(NavLink)`
  padding-left: 2rem;
  text-decoration: none;
  padding-bottom: 12px;
  padding-top: 12px;
  display: block;
  color: ${colors.logoblauw};
  &:hover {
    background: ${colors.gray100};
  }
  &.active {
    color: ${colors.hemelsblauw};
  }
`;

export const Navigation = styled.nav.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})`
  position: fixed;
  transition: transform 0.25s ease-in-out;
  max-width: 320px;
  width: 100%;
  height: 100vh;
  background: ${colors.white};
  top: 0;
  bottom: 0;
  z-index: 1002; /* Set z-index higher than map and overlay */
  transform: translateX(${props => (props.isOpen ? "0%" : "-100%")});
  will-change: transform;
`;

const MenuHeader = styled.div`
  background-color: ${colors.geel};
  height: 65px;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 12px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  list-style: none;
`;

const Hr = styled.hr`
  border: solid 1px ${colors.gray100};
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 1rem;
`;

function ContentMenuItems(props) {
  const result = useSelector(getMenuItems);
  const { loading, results } = result;

  if (loading) {
    return null;
  }
  if (!results) {
    return null;
  }

  return results.map((item, i) => {
    const slug = `/${item?.meta?.slug || item?.slug}/`;
    return (
      <ListItem key={`custom${i}`}>
        <SubMenuLink
          tabIndex={!props.isOpen ? "-1" : undefined}
          activeClassName="active"
          to={slug}
        >
          {item.title}
        </SubMenuLink>
      </ListItem>
    );
  });
}

function Menu(props) {
  const {
    location: { pathname },
    hideNavigation,
    items = [],
    isOpen,
  } = props;

  // hide menu when pathname changes
  useEffect(() => {
    if (hideNavigation) {
      hideNavigation();
    }
  }, [pathname, hideNavigation]);

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const onKeyPress = evt => {
      if (evt.key === "Escape") {
        hideNavigation();
      }
    };
    if (isOpen) {
      document.addEventListener("keydown", onKeyPress);
    }
    return () => {
      document.removeEventListener("keydown", onKeyPress);
    };
  }, [isOpen, hideNavigation]);

  const menuRef = useRef();

  return (
    <FocusTrap
      active={props.isOpen}
      focusTrapOptions={{
        initialFocus: menuRef.current,
      }}
    >
      <Navigation aria-labelledby="menu-title" isOpen={props.isOpen} ref={menuRef} tabIndex="-1">
        <MenuHeader>
          <HamburgerButton
            open
            onClick={props.hideNavigation}
            tabIndex={props.isOpen ? undefined : "-1"}
          />
          <div id="menu-title">Verkeersinfo</div>
        </MenuHeader>
        <List>
          {items.map((item, i) => (
            <React.Fragment key={i}>
              {i === 3 && (
                <ListItem>
                  <CollapseButton
                    toggled={toggle}
                    onClick={() => setToggle(!toggle)}
                    aria-pressed={toggle ? "true" : "false"}
                    aria-label={toggle ? "Inklappen" : "Uitklappen"}
                  >
                    Achtergrond <ChevronRight aria-hidden="true" />
                  </CollapseButton>
                  {toggle && (
                    <List>
                      <ContentMenuItems {...props} />
                    </List>
                  )}
                </ListItem>
              )}
              <ListItem>
                <MenuLink
                  tabIndex={props.isOpen ? undefined : "-1"}
                  exact={item.exact}
                  activeClassName="active"
                  to={Boolean(item.url) ? item.url : `/${slugify(item.title)}/`}
                  onClick={props.hideNavigation}
                >
                  {item.title}
                </MenuLink>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
        <Hr />
        <SecondaryMenu mobileMenu disableKeyboardAccess={!props.isOpen} />
      </Navigation>
    </FocusTrap>
  );
}

export default withRouter(Menu);
