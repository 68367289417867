const actionTypes = {
  SET_EXPANDED: "list/set_expanded",
};

export function reducer(state = { expanded: false }, action) {
  switch (action.type) {
    case actionTypes.SET_EXPANDED:
      return {
        ...state,
        expanded: action.expanded,
      };
    default:
      return state;
  }
}

export const getListIsExpanded = state => {
  const { list: { expanded } = {} } = state;
  return expanded;
};

export const setListExpanded = expanded => ({
  type: actionTypes.SET_EXPANDED,
  expanded,
});
