import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "../components/Sidebar";
import WidgetRedirect from "../components/WidgetRedirect";
import Camera from "../pages/Camera";
import CameraList from "../pages/CameraList";
import Home from "../pages/Home";
import Obstruction from "../pages/Obstruction";
import PageNotFound from "../pages/PageNotFound";
import RoadResults from "../pages/RoadResults";
import RoadWorks from "../pages/RoadWorks";
import RouteResults from "../pages/RouteResults";
import Search from "../pages/Search";
import TrafficInformation from "../pages/TrafficInformation";

const Page = styled.div`
  width: 100%;
  position: relative;
`;
function SidebarWithMap(sidebarProps) {
  return (
    <Page>
      <Sidebar onToggle={sidebarProps.onToggle} open={sidebarProps.isOpen}>
        <Switch>
          <Route path="/" exact render={() => <Home {...sidebarProps} />} />
          <Route path="/home.aspx" render={props => <WidgetRedirect {...props} />} />
          <Route path="/hinder/:id/" component={Obstruction} />
          <Redirect exact strict path="/cameras" to="/cameras/" />
          <Route
            path="/cameras/"
            render={props => {
              return (
                <React.Fragment>
                  <Route path={`${props.match.url}/:id/:slug/`} component={Camera} />
                  <Route
                    path={`${props.match.url}`}
                    exact
                    render={() => <CameraList {...sidebarProps} />}
                  />
                </React.Fragment>
              );
            }}
          />
          <Route
            path="/route/:from/:to/:via/:date/"
            render={props => <RouteResults {...sidebarProps} {...props} />}
          />
          <Route
            path="/wegnummer/:road/:date/"
            render={props => <RoadResults {...sidebarProps} {...props} />}
          />
          <Redirect from="/actuele-verkeersinfo/" to="/files/" />
          <Redirect exact strict path="/files" to="/files/" />
          <Route
            strict
            path="/files/"
            render={props => <TrafficInformation {...sidebarProps} {...props} />}
          />
          <Redirect exact strict path="/wegwerkzaamheden" to="/wegwerkzaamheden/" />
          <Route
            strict
            path="/wegwerkzaamheden/"
            render={props => <RoadWorks {...sidebarProps} {...props} />}
          />
          <Route
            path="/hinder-op-je-route/"
            render={props => <Search {...sidebarProps} {...props} />}
          />
          <Route component={PageNotFound} />
        </Switch>
      </Sidebar>
    </Page>
  );
}

export default SidebarWithMap;
