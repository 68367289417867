import React, { useContext } from "react";
import styled from "styled-components";
import colors from "../../common/colors";
import { format, parseISO } from "date-fns";
import { nl } from "date-fns/locale/nl";
import { Link } from "react-router-dom";
import { RWSContext } from "../../context";
import { breakpoints } from "../../common/utils";

const Card = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  text-decoration: none;
  @media (max-width: ${breakpoints.get("tablet")}px) {
    flex-direction: row-reverse;
    border-top: 1px solid ${colors.gray200};
    padding-top: 0.75rem;
    img {
      border: 0;
    }
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 160px;
  margin: 0;
  display: block;
  z-index: 2;
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0;
  @media (max-width: ${breakpoints.get("tablet")}px) {
    width: 100px;
    height: 75px;
    border-radius: 0;
  }
`;

const CardContent = styled.div`
  position: relative;
  z-index: 1;
  border: 1px solid ${colors.gray300};
  border-radius: 0 0 0.5rem 0.5rem;
  margin-top: -1px;
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  @media (max-width: ${breakpoints.get("tablet")}px) {
    border: 0;
    border-radius: 0;
    padding: 0.5rem;
  }
`;

const Time = styled.time`
  font-size: 13px;
  color: ${colors.gray700};
  margin-bottom: 0.25rem;
  display: block;
`;

const Title = styled.h3`
  color: ${colors.hemelsblauw};
  font-size: 16px;
  margin: 0;
`;

function NewsCard({ meta, list_image, date, title }) {
  const theDate = date ? parseISO(date) : new Date();
  const { slug } = meta;
  const path = `/nieuws/${slug}`;
  const { thumbnail2x: { url: thumbnailUrl } = {}, thumbnail: { alt: altText } = {} } =
    list_image || {};
  const { apiUrl } = useContext(RWSContext);
  return (
    <Card to={path}>
      <CardContent>
        <Title>{title}</Title>
        <Time datetime={date}>{date && format(theDate, "d LLLL yyyy", { locale: nl })}</Time>
      </CardContent>
      <Image src={`${apiUrl}${thumbnailUrl}`} alt={altText} />
    </Card>
  );
}

export default NewsCard;
