import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ChevronRight } from "../Datepicker/arrow-right.svg";
import colors from "../../common/colors";
import { ReactComponent as ClosedRoad } from "../../common/icons/closed_road.svg";
import { useSelector, useDispatch } from "react-redux";
import { getLegendaIsCollapsed, setLegendaIsCollapsed } from "./redux";
import { mq } from "../../common/utils";

const LegendButton = styled.button`
  padding: 0rem;
  background: ${colors.white};
  font-size: 17px;
  position: relative;
  font-weight: bold;
  color: ${colors.logoblauw};
  border: 0;
  width: 100%;
  text-align: left;
  &:hover {
    cursor: pointer;
  }
  svg {
    width: 0.5rem;
    color: ${colors.gray600};
    position: absolute;
    right: 0;
    top: 0.2rem;
  }
`;

const ChevronRightIcon = styled(ChevronRight)`
  ${props =>
    props.collapsed &&
    css`
      transform: rotate(90deg);
    `}
`;

const LegendWrapper = styled.div`
  background: ${colors.white};
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  z-index: 1002;
  right: 0;
  bottom: 0;
  min-width: 12rem;
  padding: 0.5rem;
  min-height: 2rem;
  font-size: 0.875rem;
  ${mq("tablet")`
    right: 1.5rem;
    bottom: 8.5rem;
    font-size: 1rem;
  `}
`;

const Disclaimer = styled.div`
  font-style: italic;
  border-top: 1px solid ${colors.gray400};
  padding-top: 2px;
`;

const LegendContent = styled.div`
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid ${colors.gray400};
`;

const RoadBar = styled.div`
  height: 6px;
  margin-top: 6px;
  border-radius: 3px;
  ${props =>
    props.color === "donkergeel" &&
    css`
      background-color: ${colors.signaalkleuren.donkergeel};
    `}
  ${props =>
    props.color === "rood" &&
    css`
      background-color: ${colors.signaalkleuren.rood};
    `}
  ${props =>
    props.color === "hemelsblauw" &&
    css`
      background-color: ${colors.hemelsblauw};
    `}
`;

const RoadBarWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  ${RoadBar} {
    flex: 1;
    margin-left: 0.25rem;
  }
  ${mq("tablet")`
    margin-bottom: 16px;
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  ${mq("tablet")`
    margin-bottom: 1rem;
  `}
  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

function RoadLegenda(props) {
  return (
    <RoadBarWrapper>
      <span>{props.text}</span>
      <RoadBar color={props.color} />
    </RoadBarWrapper>
  );
}

function Legend(props) {
  const dispatch = useDispatch();
  let legendaIsCollapsed = useSelector(getLegendaIsCollapsed);

  function toggleLegend() {
    if (legendaIsCollapsed) {
      dispatch(setLegendaIsCollapsed(false));
    } else {
      dispatch(setLegendaIsCollapsed(true));
    }
  }

  return (
    <LegendWrapper>
      <LegendButton
        type="button"
        onClick={toggleLegend}
        aria-expanded={!legendaIsCollapsed ? "true" : "false"}
      >
        Legenda <ChevronRightIcon collapsed={!legendaIsCollapsed ? "collapsed" : null} />
      </LegendButton>
      {!legendaIsCollapsed ? (
        <LegendContent>
          <IconWrapper>
            <ClosedRoad /> <div>Afsluiting</div>
          </IconWrapper>
          <RoadLegenda color="rood" text="File" />
          <RoadLegenda color="donkergeel" text="Wegwerkzaamheden" />
          <RoadLegenda color="hemelsblauw" text="Route" />
          <Disclaimer>Hinder op de rijkswegen (veelal snelwegen)</Disclaimer>
        </LegendContent>
      ) : null}
    </LegendWrapper>
  );
}

export default Legend;
