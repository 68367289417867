import React from "react";
import { withRouter } from "react-router-dom";

// Taken from the React Router documentation https://reactrouter.com/web/guides/scroll-restoration/scroll-to-top
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      prevProps.directScroll === true
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
