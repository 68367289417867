import React from "react";
import styled from "styled-components";
import colors from "../common/colors";
import { ReactComponent as ExternalIcon } from "../common/icons/external-link.svg";

const A = styled.a`
  display: inline-flex;
  align-items: center;
  color: ${colors.hemelsblauw};
  svg {
    margin-left: 4px;
  }
`;

function ExternalLink(props) {
  return (
    <>
      <A href={props.href} target="_blank" rel="noreferrer noopener">
        {props.children} <ExternalIcon />
      </A>
    </>
  );
}

export default ExternalLink;
