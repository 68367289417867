import ReactDOM from 'react-dom/client';
import App from "./layouts/App";
import { Provider } from "react-redux";
import store from "./redux/store";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import PiwikPro from "@piwikpro/react-piwik-pro";
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

export let apiURL = null;

// TODO: Add environment variable SENTRY_ENVIRONMENT to define the environment which is shown in Sentry when an error is caught.
//  Our current setup (getting settings via nginx) makes it difficult to make this change.
function determineSentryEnvironment(apiUrl) {
  return apiUrl.indexOf("acc") > 0
    ? apiUrl.indexOf("gbvi")
      ? "acc-gbvi"
      : "acc-overige"
    : "productie";
}

axios
  .get(`/config.json`)
  .catch(function() {
    console.error("Invalid config.json file.");
  })
  .then(function(response) {
    const config = response.data;
    apiURL = config.apiUrl;
    window.eov = {};
    window.eov.API = config.apiUrl;
    window.eov.Here = {
      ApiKey: config.hereApiKey,
    };
    if (process.env.NODE_ENV === "production" && config.piwikUrl) {
      PiwikPro.initialize(config.piwikSiteId, config.piwikUrl);
    }
    if (process.env.NODE_ENV === "production" && config.sentryDSN) {
      // Temporary disable Sentry to prevent errors until we have done a refactor
      // Sentry.init({
      //   dsn: config.sentryDSN,
      //   environment: determineSentryEnvironment(config.apiUrl),
      //   beforeSend(event, hint) {
      //     const error = hint.originalException;
      //     if (error && error.message && error.message.match(/Deze locatie is onbekend/i)) {
      //       event.fingerprint = ["geocoding"];
      //     }
      //     return event;
      //   },
      // });
    }

    const appRootElement = document.getElementById('root')
    const root = ReactDOM.createRoot(appRootElement);
    root.render(
      <Provider store={store}>
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <App config={config} />
        </StyleSheetManager>
      </Provider>,
    );

    if (appRootElement) {
      appRootElement.setAttribute("api-url", config.apiUrl);
    }
  });
