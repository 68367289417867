import React from "react";
import styled from "styled-components";
import Severity from "./Severity";
import colors from "../../common/colors";
import { format } from "date-fns";
import { nl } from "date-fns/locale/nl";
import Chart from "./Chart";

const Card = styled.div`
  border: 1px solid ${colors.gray300};
  border-radius: 0.5rem;
  padding: 20px;
  width: 100%;
`;

const H3 = styled.h3`
  margin-bottom: 0.75rem;
`;

const Content = styled.div`
  margin-top: 1.5rem;
`;

const SeverityExplanation = styled.div`
  margin-top: 1rem;
  font-style: italic;
`;

const labels = [
  "Vrijwel geen spits",
  "Lichte spits",
  "Normale spits",
  "Zware spits",
  "Zeer zware spits",
  "Extreme spits",
];

const { groen, donkergeel, oranje, rood } = colors.signaalkleuren;

const severityColors = [colors.gray200, groen, donkergeel, oranje, rood, colors.gray900];

function PeakExpectationCard(props) {
  // severity is 1..6
  const severityIndex = Number.isNaN(props.severity) ? 0 : props.severity - 1;
  const label = labels[severityIndex];
  const color = severityColors[severityIndex];
  const timeOfDay = props.daypart === "PM" ? "avond" : "ochtend";
  const date = `${props.date &&
    format(new Date(props.date), "EEEE d LLLL", { locale: nl })} (${timeOfDay})`;

  return (
    <Card>
      {props.date && <H3>{date}</H3>}
      <Severity level={props.severity - 1} label={label} color={color} />
      <div>
        <Chart data={props.moments} color={color} date={date} />
      </div>
      {props.explanation && (
        <>
          <Content>{props.content}</Content>
          <SeverityExplanation>{props.severity_explanation}</SeverityExplanation>
        </>
      )}
    </Card>
  );
}

export default PeakExpectationCard;
