import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadRoadworks } from "./redux";

export const useShouldLoadRoadworks = shouldLoad => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldLoad) {
      dispatch(loadRoadworks());
    }
  }, [shouldLoad, dispatch]);
};
