import React from "react";
import styled from "styled-components";
import colors from "../common/colors";

const Link = styled.a`
  color: ${colors.white};
  background: ${colors.logoblauw};
  text-align: center;
  padding: 1.2rem;
  vertical-align: middle;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  z-index: 1006; /* Display on top of the app */
  margin-left: 3.5rem;
  &:focus {
    transform: translateY(0%);
  }
`;

function Skiplink() {
  return <Link href="#content">Ga direct naar de inhoud</Link>;
}

export default Skiplink;
