const actionTypes = {
  LOAD: "traffic/load",
  LOAD_SUCCESS: "traffic/load_success",
  LOAD_FAILED: "traffic/load_failed",
  RELOAD: "traffic/reload",
  RELOAD_SUCCESS: "traffic/reload_success",
  RELOAD_FAILED: "traffic/reload_failed",
};

const initialState = {
  loading: false,
  loaded: false,
  error: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case actionTypes.LOAD_SUCCESS:
    case actionTypes.RELOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        loaded: true,
        dateLoaded: new Date(),
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case actionTypes.RELOAD:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

export const loadTraffic = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: `/api/traffic/`,
});

export const reloadTraffic = () => ({
  types: [actionTypes.RELOAD, actionTypes.RELOAD_SUCCESS, actionTypes.RELOAD_FAILED],
  method: "GET",
  url: `/api/traffic/`,
});

const getSortKey = ({ roadNumber }) => {
  if (!roadNumber) {
    return "";
  }
  const roadType = roadNumber[0];
  let roadInteger = parseInt(roadNumber.substr(1), 10);
  if (isNaN(roadNumber)) {
    try {
      roadInteger = parseInt(roadNumber.substr(1).split(" ")[0], 10);
    } catch {
      roadInteger = 0;
    }
  }
  return `${roadType}${roadInteger.toString().padStart(4, "0")}`;
};

export const getCurrentObstructions = (state, showAll = false) => {
  const {
    traffic: { obstructions = [], loading, loaded, dateLoaded, error = {} },
  } = state;

  const {
    map: { filters },
  } = state;

  const filteredObstructions = filterObstructions(obstructions, filters);

  const visibleItems = showAll ? filteredObstructions : filteredObstructions.slice(0, 5);
  const shouldLoad = !loaded && !loading && Object.keys(error).length === 0;

  return {
    items: visibleItems,
    loading,
    loaded,
    error,
    count: filteredObstructions.length,
    shouldLoad,
    dateLoaded,
  };
};

export const getTrafficInfo = state => {
  const {
    traffic: {
      numberOfJams,
      totalLengthOfJams,
      loading,
      loaded,
      dateLoaded,
      error = {},
      obstructions = [],
    } = {},
    map: { filters = [] } = {},
  } = state;

  const obstructionTypesToshow = filters.map(convertFilterToObstructionType).flat();

  const items = obstructions.filter(x => obstructionTypesToshow.includes(x.obstructionType));
  items.sort((a, b) => {
    if (a.obstructionType === 4) {
      return getSortKey(a) < getSortKey(b) ? -1 : 1;
    }
    return 1;
  });

  const shouldLoad = !loading && !loaded && Object.keys(error).length === 0;

  return {
    numberOfJams,
    km: Math.round(totalLengthOfJams / 1000),
    loading,
    loaded,
    shouldLoad,
    error,
    obstructions: items,
    dateLoaded,
  };
};

export const convertFilterToObstructionType = filter => {
  if (filter === "roadworks") {
    return [1];
  }
  if (filter === "traffic") {
    return [4];
  }
  if (filter === "laneclosed") {
    return [7];
  }
  if (filter === "bridge") {
    return [23];
  }
  if (filter === "cameras") {
    return [99];
  }
  const allNumbers = Array.from({ length: 25 }, (_, i) => i + 1);
  return allNumbers.filter(num => ![1, 4, 7, 23, 99].includes(num));
};

export const filterObstructions = (obstructions, filters) => {
  if (filters.length > 0) {
    const allowedNumbers = filters
      .map(convertFilterToObstructionType)
      .reduce((acc, val) => acc.concat(val), []);
    return obstructions.filter(item => {
      return allowedNumbers.includes(item.obstructionType);
    });
  }
  return [];
};

export const getTrafficMarkers = state => {
  return getCurrentObstructions(state, true);
};
