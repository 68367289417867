import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";

import { nl } from "date-fns/locale/nl";
import {
  addDays,
  addYears,
  isToday,
  format,
  parse,
  isBefore,
  isValid,
} from "date-fns";

import colors from "../../common/colors";
import { dutchFormat } from "../../common/formatPeriod";
import { Input, Label } from "../Input";
import { Error } from "../Autocomplete";
import Wrapper from "./Wrapper";
import { ReactComponent as IconArrowLeft } from "./arrow-left.svg";
import { ReactComponent as IconArrowRight } from "./arrow-right.svg";

const Arrows = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`;

export const Arrow = styled.button`
  background: transparent;
  border: 0;
  box-shadow: 0;
  width: 20px;
  display: flex;
  justify-content: center;
  padding: 0;
  color: ${colors.hemelsblauw};
  cursor: pointer;
  &:hover {
    color: ${colors.logoblauw};
  }
  svg {
    width: 8px;
    height: 14px;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: default;
      pointer-events: none;
      color: ${colors.gray300};
    `}
`;

const DateInput = styled(Input)`
  margin-left: 10px;
  appearance: textfield;
  &::-webkit-clear-button {
    appearance: none;
    margin: 0;
  }
  &::-webkit-date-and-time-value {
    color: ${colors.gray900};
    text-align: left;
  }
  flex: 1;
  -webkit-appearance: textfield;
`;

const StyledDatePicker = styled(DatePicker)`
  padding-left: 10px;
`;

const ScreenReaderInstruction = styled.div`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

function Datepicker(props) {
  const [value, setValue] = useState(props.value || new Date());
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
  const [errorMessage, setErrorMessage] = useState("");

  const { onChange } = props;

  const onChangeNative = evt => {
    const { value } = evt.target;
    if (!value) {
      setValue(null);
      return;
    }
    // <input type="date" /> value always returns value as 2019-12-25.
    // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#Value
    const parsedValue = parse(value, "yyyy-MM-dd", new Date());

    // prevent stepping further back than today
    if (isBefore(parsedValue, addDays(new Date(), -1))) {
      return;
    }
    setValue(parsedValue);
  };

  const onBlurNative = () => {
    if (!value) {
      setValue(new Date());
    }
  };

  const gotoPreviousDay = evt => {
    evt.preventDefault();
    if (isToday(value)) {
      return;
    }
    setValue(addDays(value, -1));
  };

  const gotoNextDay = evt => {
    evt.preventDefault();
    setValue(addDays(value, 1));
  };

  const setAriaLabel = number => {
    return `selecteer ${dutchFormat(addDays(value, number), "d LLLL yyyy")}`;
  };

  const handleChangeRaw = date => {
    // when selecting in the calender, return undefined to make the onChange handle it
    if (!date) {
      return undefined;
    }
    const parsedDate = parse(date, "dd-MM-y", new Date());
    if (isValid(parsedDate)) {
      if (isBefore(parsedDate, addDays(new Date(), -1))) {
        setErrorMessage("Datum mag niet in het verleden liggen");
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage(
        "U heeft geen geldige datum ingevuld. Een datum heeft het volgende formaat: dd-mm-jjjj",
      );
    }
  };

  const resetErrorMessage = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  const id = props.id || props.label;

  return (
    <>
      <Wrapper error={errorMessage} className="eov-search-date-field" aria-hidden="true">
        <Label htmlFor={id}>{props.label}</Label>
        {isMobile ? (
          <DateInput
            type="date"
            onChange={onChangeNative}
            onBlur={onBlurNative}
            aria-label="Datum"
            id={id}
            placeholder="Selecteer een datum"
            value={value ? format(value, "yyyy-MM-dd") : ""}
            min={format(new Date(), "yyyy-MM-dd")}
            max={format(addYears(new Date(), 10), "yyyy-MM-dd")}
          />
        ) : (
          <StyledDatePicker
            selected={value}
            onChange={date => {
              setValue(date);
              resetErrorMessage();
            }}
            minDate={new Date()}
            placeholderText="Selecteer een datum"
            locale={nl}
            dateFormat="dd-MM-yyyy"
            onChangeRaw={event => handleChangeRaw(event.target.value)}
            customInput={
              <Input
                identifier={id}
                aria-label="Datum"
                type="text"
                ariaLabelledby="screen-reader-instruction"
              />
            }
            previousMonthButtonLabel={<IconArrowLeft title="1 maand terug" />}
            nextMonthButtonLabel={<IconArrowRight title="1 maand vooruit" />}
            showPopperArrow={false}
            // popperModifiers={[{
            //   offset: {
            //     enabled: true,
            //     offset: "44px, 0px",
            //   },
            // }]}
          />
        )}
        {value && (
          <Arrows>
            <Arrow
              aria-label={setAriaLabel(-1)}
              onClick={gotoPreviousDay}
              disabled={isToday(value)}
              type="button"
            >
              <IconArrowLeft title="1 dag terug" />
            </Arrow>
            <Arrow aria-label={setAriaLabel(1)} onClick={gotoNextDay} type="button">
              <IconArrowRight title="1 dag vooruit" />
            </Arrow>
          </Arrows>
        )}
        <ScreenReaderInstruction id="screen-reader-instruction">
          Voer een datum in het volgende formaat: dd-mm-jjjj
        </ScreenReaderInstruction>
      </Wrapper>
      {errorMessage && <Error role="alert">{errorMessage}</Error>}
    </>
  );
}

export default Datepicker;

Datepicker.defaultProps = {
  onChange: () => {},
};
