import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getCameras } from "../components/Camera/redux";
import { useLoadCameras } from "../components/Camera/hooks";
import CameraCard from "../components/Camera/Card";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import List from "../components/List";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet-async";
import MapWindow from "../components/Map/MapWindow";
import { SidebarContext } from "../components/Sidebar";
import {
  getMapDisplay,
  setMapDisplay,
  setFilters,
  getFilters,
  setLegendaIsCollapsed,
} from "../components/Map/redux";
import { useLastLocation } from "react-router-last-location";
import { getFiltersBasedOnLastLocation } from "../utils/filters";

const Wrapper = styled.div`
  padding: 2rem 1rem;
  background: #fff;
`;

function CameraList(props) {
  const dispatch = useDispatch();
  const { items = [], loading, shouldLoad } = useSelector(store => getCameras(store));
  useLoadCameras(shouldLoad);

  const initialFilters = ["cameras"];
  const activeFilters = useSelector(getFilters);
  const lastLocation = useLastLocation();
  const filters = getFiltersBasedOnLastLocation(initialFilters, activeFilters, lastLocation);

  const currentMapDisplay = useSelector(getMapDisplay);

  useEffect(() => {
    if (currentMapDisplay !== "traffic") {
      dispatch(setMapDisplay("traffic"));
    }
    dispatch(setFilters(filters));
    dispatch(setLegendaIsCollapsed(true));
  }, [currentMapDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

  const { detectScrollTop } = useContext(SidebarContext);

  return (
    <React.Fragment>
      <Helmet>
        <title>Verkeerscamera's langs de snelweg bekijken?</title>
        <meta
          name="description"
          content="Bekijk hier de verkeerscamera's langs de snelwegen. Zo zie je direct live beelden van het verkeer in Nederland."
        />
      </Helmet>
      <SubHeader />
      <MapWindow onMapFullscreen={props.onMapFullscreen} isFullscreen={props.mapFullscreen} />
      <Wrapper>
        {loading ? (
          <Loading text="Bezig met laden..." />
        ) : (
          <React.Fragment>
            <h1>Verkeerscamera's</h1>
            <h2>{items.length} camera's</h2>
            <List>
              {items.map(camera => (
                <CameraCard key={camera.id} {...camera} onClick={detectScrollTop} />
              ))}
            </List>
          </React.Fragment>
        )}
      </Wrapper>
      <Footer />
    </React.Fragment>
  );
}

export default CameraList;
