import styled, { css } from "styled-components";
import colors from "../common/colors";

const Overlay = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})`
  width: 100vw;
  height: 100%;
  background-color: ${colors.overlay};
  position: fixed;
  z-index: 1001; /* Give overlay higher z-index than map component */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  ${props =>
    props.isOpen &&
    css`
      pointer-events: auto;
      opacity: 1;
      &:hover {
        cursor: pointer;
      }
    `}
`;

export default Overlay;
