import React from "react";
import styled from "styled-components";
import { ReactComponent as ChevronRight } from "../Datepicker/arrow-right.svg";
import colors from "../../common/colors";
import Badge from "../Badge";
import { Link as RouterLink } from "react-router-dom";

const Card = styled.li`
  border: 1px solid ${colors.gray300};
  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  & + & {
    border-top: 0;
  }
`;

const Icon = styled.div`
  width: 3rem;
  height: 2.25rem;
  display: grid;
  place-items: center;
  color: ${colors.logoblauw};
  margin-right: 1rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 0;
`;

const Link = styled(RouterLink)`
  display: flex;
  color: ${colors.logoblauw};
  text-decoration: none;
  transition: background 0.18s ease-in-out;
  padding: 20px 16px;
  &:hover {
    background: ${colors.gray100};
  }
`;

const ArrowWrapper = styled.div`
  display: grid;
  place-items: center;
  color: ${colors.gray600};
  svg {
    width: 10px;
    height: 16px;
  }
`;

const Content = styled.div`
  flex: 1;
  padding-right: 8px;
`;

const IconWrapper = styled.div`
  order: -1;
`;

function ListCard(props) {
  return (
    <Card>
      <Link to={props.to} onClick={props.onClick} ref={props.forwardedRef}>
        <Content>
          <Title>{props.title}</Title>
          {props.children}
        </Content>

        <IconWrapper>
          <Icon>{props.icon}</Icon>
          {props.roadNumber && (
            <Badge road color={(props.roadNumber || "").indexOf("A") === 0 ? "red" : "yellow"}>
              {props.roadNumber}
            </Badge>
          )}
        </IconWrapper>

        <ArrowWrapper>
          <ChevronRight />
        </ArrowWrapper>
      </Link>
    </Card>
  );
}

export default React.forwardRef((props, ref) => <ListCard {...props} forwardedRef={ref} />);
