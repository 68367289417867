import React from "react";
import styled from "styled-components";
import { ReactComponent as IconCar } from "../../common/icons/car.svg";
import colors from "../../common/colors";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1rem;
  svg {
    & + svg {
      margin-left: 0.5rem;
    }
  }
`;
const Label = styled.span`
  font-weight: bold;
  color: ${colors.logoblauw};
  margin-left: 0.75rem;
`;

const getCarColor = (index, level, color) => {
  if (index < level) {
    return color;
  }
  return colors.gray200;
};

function Severity(props) {
  return (
    <Wrapper>
      {Array.from({ length: 4 }, (_, i) => (
        <IconCar key={i} style={{ color: getCarColor(i, props.level, props.color) }} />
      ))}
      <Label>{props.label}</Label>
    </Wrapper>
  );
}
export default Severity;
