import {
  format,
  parseISO,
  isSameDay,
  isFuture,
  differenceInCalendarMonths,
  isPast,
  isToday,
} from "date-fns";
import { nl } from "date-fns/locale/nl";

export const dutchFormat = (date, formatString) => format(date, formatString, { locale: nl });

const formatDate = date => {
  if (differenceInCalendarMonths(date, new Date()) > 12) {
    return dutchFormat(date, "LLLL yyyy");
  }
  if (differenceInCalendarMonths(date, new Date()) > 2) {
    if (new Date().getFullYear() !== date.getFullYear()) {
      // if it's not this year, mention the year.
      return dutchFormat(date, "iiii d LLLL yyyy");
    }
    return dutchFormat(date, "iiii d LLLL");
  }
  return `${dutchFormat(date, "iiii d LLLL HH:mm")} uur`;
};

const formatPeriod = (timeStart, timeEnd) => {
  if (!timeStart && !timeEnd) {
    return "";
  }

  if (timeStart && !timeEnd) {
    const dateStart = parseISO(timeStart);
    if (isToday(dateStart)) {
      return `Vandaag ${dutchFormat(dateStart, "HH:mm")} uur`;
    }
    return formatDate(dateStart);
  }

  if (!timeStart && timeEnd) {
    return `tot ${formatDate(parseISO(timeEnd))}`;
  }

  if (timeStart && timeEnd) {
    const dateStart = parseISO(timeStart);
    const dateEnd = parseISO(timeEnd);
    if (isSameDay(dateStart, dateEnd)) {
      return `${dutchFormat(dateStart, "iiii d LLLL")} van ${dutchFormat(
        dateStart,
        "HH:mm",
      )} tot ${dutchFormat(dateEnd, "HH:mm")} uur`;
    }
    if (isPast(dateStart) && isFuture(dateEnd)) {
      return `tot ${formatDate(dateEnd)}`;
    }
    return `van ${formatDate(dateStart)} tot ${formatDate(dateEnd)}`;
  }
};

export default formatPeriod;
