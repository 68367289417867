const formatDirection = obstruction => {
  const { directionText = "", obstructionType = 0 } = obstruction;
  const cities = directionText.split(" - ");
  if (cities.length === 2) {
    if (obstructionType === 23) {
      return cities.join(" ↔ ");
    }
    return cities.join(" → ");
  }
  return directionText;
};

export default formatDirection;
