import React from "react";
import styled from "styled-components";
import * as Sentry from "@sentry/browser";

const ErrorString = styled.div`
  font-size: 15px;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: !!props.error };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.hasError) {
      return { hasError: !!props.error };
    }
    return {};
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.noSentry) {
      return;
    }
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorString>
          {this.props.errorString ||
            "Er is helaas iets foutgegaan. Herlaad de pagina en probeer het opnieuw."}
        </ErrorString>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
