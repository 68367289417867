import {
  isSameDay,
  parseISO,
  isWithinInterval,
} from "date-fns";

const actionTypes = {
  LOAD: "roadworks/load",
  LOAD_SUCCESS: "roadworks/load_success",
  LOAD_FAILED: "roadworks/load_failed",
  SET_DATE: "roadworks/set_date",
};

const initialState = {
  loading: false,
  loaded: false,
  items: [],
  error: {},
  date: new Date(),
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
        loaded: true,
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case actionTypes.SET_DATE:
      return {
        ...state,
        date: action.date,
      };
    default:
      return state;
  }
}

export const loadRoadworks = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: `/api/roadworks/`,
});

export const getRoadworks = state => {
  const {
    roadworks: { items = [], loading, loaded, error = {}, date = new Date() },
  } = state;

  const filteredItems = items.filter(item => {
    const { timeStart, timeEnd } = item;
    const parsedStart = parseISO(timeStart);
    const parsedEnd = parseISO(timeEnd);
    // return also roadworks that will start today, even if that's later than now.
    if (isSameDay(parsedStart, date)) {
      return true;
    }
    const interval =
      parsedStart < parsedEnd
        ? {
            start: parsedStart,
            end: parsedEnd,
          }
        : {
            start: parsedEnd,
            end: parsedStart,
          };

    if (isWithinInterval(date, interval)) {
      return true;
    }
    return false;
  });

  const shouldLoad = !loading && !loaded && Object.keys(error).length === 0;

  return {
    items: filteredItems,
    loading,
    loaded,
    error,
    count: filteredItems.length,
    shouldLoad,
  };
};

export const getRoadworksDate = state => {
  const {
    roadworks: { date = new Date() },
  } = state;
  return date;
};

export const setRoadworksDate = date => ({
  type: actionTypes.SET_DATE,
  date,
});
