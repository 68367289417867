import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useShouldLoadTraffic } from "../components/Traffic/hooks";
import ObstructionList from "../components/ObstructionList";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import Disclaimer from "../components/Disclaimer";
import Gauge from "../components/Traffic/Gauge";
import { getTrafficInfo } from "../components/Traffic/redux";
import { Helmet } from "react-helmet-async";
import MapWindow from "../components/Map/MapWindow";
import {
  getMapDisplay,
  setMapDisplay,
  setFilters,
  getFilters,
  setLegendaIsCollapsed,
} from "../components/Map/redux";
import { mq } from "../common/utils";
import { useLastLocation } from "react-router-last-location";
import { getFiltersBasedOnLastLocation } from "../utils/filters";

const Wrapper = styled.div`
  background: #fff;
  padding: 2rem 1rem 2.5rem 1rem;
  ${mq("desktop")`
    padding: 2rem 1rem 0rem 1rem;
  `}
`;

const Results = styled.div`
  padding: 2.5rem 1rem 1rem;
  background: #fff;
`;

const H1 = styled.h1`
  margin-bottom: 1rem;
`;

const H2 = styled.h2`
  font-size: 24px;
  font-weight: normal;
  margin: 0;
`;

function TrafficInformation(props) {
  const dispatch = useDispatch();
  const {
    numberOfJams,
    km = 0,
    shouldLoad,
    loaded,
    dateLoaded,
    obstructions,
    error,
  } = useSelector(getTrafficInfo);
  useShouldLoadTraffic(shouldLoad);

  const currentMapDisplay = useSelector(getMapDisplay);
  const initialFilters = ["traffic"];
  const activeFilters = useSelector(getFilters);
  const lastLocation = useLastLocation();
  const filters = getFiltersBasedOnLastLocation(initialFilters, activeFilters, lastLocation);

  useEffect(() => {
    if (currentMapDisplay !== "traffic") {
      dispatch(setMapDisplay("traffic"));
    }
    dispatch(setFilters(filters));
    dispatch(setLegendaIsCollapsed(true));
  }, [currentMapDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

  const obstructionListProps = {
    loaded,
    error,
    limited: false,
    items: obstructions,
  };

  const resultCount = obstructionListProps.items.length;

  return (
    <React.Fragment>
      <Helmet>
        <title>Files</title>
      </Helmet>
      <SubHeader />
      <Wrapper>
        <H1>Files</H1>
        <Gauge length={km || 0} count={numberOfJams || 0} date={dateLoaded} />
      </Wrapper>
      <MapWindow onMapFullscreen={props.onMapFullscreen} isFullscreen={props.mapFullscreen} />
      <Results>
        <H2>
          {resultCount} {resultCount === 1 ? "resultaat" : "resultaten"} gevonden
        </H2>
      </Results>
      <ObstructionList {...obstructionListProps} style={{ paddingTop: 0 }} />
      <Disclaimer />
      <Footer />
    </React.Fragment>
  );
}

export default TrafficInformation;
