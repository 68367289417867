import colors from "../common/colors";
import styled, { css } from "styled-components";

const Tooltip = styled.div`
  background: ${colors.logoblauw};
  color: ${colors.white};
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(8px, -50%);
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  transition: all 0.15s ease-out 0.2s;
  &::after {
    content: "";
    display: block;
    border-right: 6px solid ${colors.logoblauw};
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    position: absolute;
    top: 50%;
    left: -6px;
    transform: translateY(-50%);
  }
  ${props =>
    props.vertical &&
    css`
      left: 50%;
      top: -8px;
      ${props.visible && `opacity: 1;`}
      transform: translate(-50%, -100%);
      &::after {
        top: auto;
        left: 50%;
        transform: translateX(-50%) rotate(-90deg);
      }
    `}
`;

export default Tooltip;
