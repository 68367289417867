import React from "react";
import { Helmet } from "react-helmet-async";
import ObstructionList from "../ObstructionList";
import styled from "styled-components";
import Search from ".";
import Disclaimer from "../Disclaimer";
import SubHeader from "../SubHeader";
import Footer from "../Footer";
import ShareModal from "../ShareModal";
import MapWindow from "../Map/MapWindow";

const Wrapper = styled.div`
  padding: 0 16px 32px;
  background: #fff;
`;

function Results(props) {
  const { location: { pathname = "" } = {}, payload, obstructions = {}, forwardedRef } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <SubHeader />
      <Search route={pathname.startsWith("/route/")} {...payload} ref={forwardedRef} />
      <MapWindow onMapFullscreen={props.onMapFullscreen} isFullscreen={props.mapFullscreen} />
      <ObstructionList {...obstructions} />
      {obstructions.loaded && (
        <Wrapper>
          <ShareModal url={window.location.href} title="Deel zoekopdracht" />
        </Wrapper>
      )}
      <Disclaimer />
      <Footer titleElement="h1" />
    </React.Fragment>
  );
}

export default React.forwardRef((props, ref) => {
  return <Results {...props} forwardedRef={ref} />;
});
