import React from "react";
import styled, { css } from "styled-components";
import colors from "../../common/colors";
import { mq } from "../../common/utils";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as Cars } from "../../common/icons/cars.svg";
import { ReactComponent as Incident } from "../../common/icons/incident.svg";
import { ReactComponent as Bridge } from "../../common/icons/bridge.svg";
import { ReactComponent as ClosedRoad } from "../../common/icons/closed_road.svg";
import { ReactComponent as RoadWork } from "../../common/icons/roadwork.svg";
import { ReactComponent as Camera } from "../../common/icons/camera.svg";
import { ReactComponent as CloseFilterIcon } from "../../common/icons/close_filter.svg";

import { addFilter, removeFilter, getFilters } from "./redux";

const Wrapper = styled.div`
  position: relative;
  border-bottom: 1px solid ${colors.gray200};
  border-top: 1px solid ${colors.gray200};
  background: #fff;
  ${mq("desktop")`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 0;
    background: transparent;
    z-index: 1002;
  `}
`;

const FilterItems = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
  padding: 0.75rem 1rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::after {
    content: "";
    display: block;
    width: 1rem;
    flex-shrink: 0;
  }
`;

const FilterItem = styled.button.withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 12px;
  border: 1px solid ${colors.gray300};
  border-radius: 999px;
  background: ${colors.white};
  white-space: nowrap;
  font-size: 13px;
  line-height: 1;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @media (hover: hover) {
    &:hover {
      background: ${colors.gray100};
    }
  }
  & + & {
    margin-left: 0.5rem;
  }
  ${props =>
    props.active &&
    css`
      color: ${colors.white};
      background: ${colors.hemelsblauw};
      border-color: ${colors.hemelsblauw};
      font-size: 14px;
      font-weight: 700;
      @media (hover: hover) {
        &:hover {
          background: ${colors.hemelsblauw};
        }
      }
    `}
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

const CloseIcon = styled(CloseFilterIcon)`
  max-width: 11px;
  margin-left: 10px;
  margin-right: 0;
`;

function FilterButton(props) {
  const Icon = props.icon;
  const { active } = props;
  return (
    <FilterItem
      active={props.active}
      onClick={props.onClick}
      aria-pressed={props.active ? "true" : "false"}
    >
      <Icon aria-hidden="true" />
      {props.text}
      {active && <CloseIcon title={`Niet meer filteren op ${props.text}`} />}
    </FilterItem>
  );
}

function Filters(props) {
  const activeFilters = useSelector(getFilters);
  const dispatch = useDispatch();

  const filters = [
    { icon: Cars, text: "Files", key: "traffic" },
    { icon: Incident, text: "Incidenten", key: "incidents" },
    { icon: RoadWork, text: "Werkzaamheden", key: "roadworks" },
    { icon: ClosedRoad, text: "Afsluitingen", key: "laneclosed" },
    { icon: Camera, text: "Camera's", key: "cameras" },
  ];

  const toggleFilter = filter => {
    if (activeFilters.includes(filter)) {
      dispatch(removeFilter(filter));
    } else {
      dispatch(addFilter(filter));
    }
  };

  return (
    <Wrapper>
      <FilterItems>
        {filters.map(filter => (
          <FilterButton
            {...filter}
            active={activeFilters.includes(filter.key)}
            onClick={() => toggleFilter(filter.key)}
          />
        ))}
      </FilterItems>
    </Wrapper>
  );
}

export default Filters;
