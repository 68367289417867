import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../common/colors";
import { mq } from "../common/utils";
import {
  format,
  parseISO,
} from "date-fns";
import { nl } from "date-fns/locale/nl";
import { RWSContext } from "../context";

const NewsWrapper = styled.li`
  border-top: 1px solid ${colors.gray200};
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-end;
`;

const HeadLine = styled.h2`
  font-weight: bold;
  color: ${colors.hemelsblauw};
  font-size: 1rem;
  line-height: 1.3;
  ${mq("tablet")`
    font-size: 20px;
    margin-bottom: 4px;
  `}
`;

const Time = styled.time`
  font-size: 13px;
  color: ${colors.gray700};
  margin-bottom: 4px;
  display: block;
  order: -1;
  ${mq("tablet")`
    line-height: 1.3;
    font-size: 14px;
  `}
`;

const Content = styled.div`
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  ${mq("tablet")`
    padding-left: 1.25rem;
  `}
`;

const Lead = styled.div`
  font-size: 1rem;
  line-height: 1.6;
  color: ${colors.gray900};
  display: none;
  a {
    color: ${colors.gray900};
  }
  ${mq("tablet")`
    display: block;
    margin-top: 8px;
  `}
`;

const NewsLink = styled(Link)`
  text-decoration: none;
`;

const Image = styled.img`
  width: 90px;
  height: 67.5px;
  object-fit: cover;
  display: block;
  ${mq("tablet")`
    width: 160px;
    height: 120px;
  `}
`;

const EmptyImage = styled.div`
  min-width: 90px;
  ${mq("tablet")`
    min-width: 160px;
  `}
`;

function Thumbnail(props) {
  const { thumbnail, thumbnail2x } = props.img || {};
  const { apiUrl } = useContext(RWSContext);
  return (
    <picture>
      <source srcSet={`${apiUrl}${thumbnail2x.url} 2x, ${apiUrl}${thumbnail.url} 1x`} />
      <Image src={`${apiUrl}${thumbnail.url}`} alt={props.alt} />
    </picture>
  );
}

function NewsListItem(props) {
  const theDate = props.date ? parseISO(props.date) : new Date();
  const baseSlug = props.baseSlug ? props.baseSlug : "nieuws";
  const { slug = props.slug } = props?.meta || {};
  return (
    <NewsWrapper>
      <Content>
        <NewsLink title={props.title} to={`/${baseSlug}/${slug || ""}/`} ref={props.forwardedRef}>
          <HeadLine as={props.titleTag || "h2"}>{props.title}</HeadLine>
        </NewsLink>
        <Time dateTime={props.date}>
          {props.date && format(theDate, "d LLLL yyyy", { locale: nl })}
        </Time>
        {props.showLead && <Lead dangerouslySetInnerHTML={{ __html: props.intro }} />}
      </Content>

      <div>
        <NewsLink title={props.title} to={`/${baseSlug}/${slug}/`} tabIndex="-1">
          {props.list_image && Object.keys(props.list_image).length > 0 ? (
            <Thumbnail img={props.list_image} alt={props.title} />
          ) : (
            <EmptyImage />
          )}
        </NewsLink>
      </div>
    </NewsWrapper>
  );
}

export default React.forwardRef((props, ref) => <NewsListItem {...props} forwardedRef={ref} />);
