import React from "react";
import styled from "styled-components";
import Button from "./Button";
import BackButton from "./BackButton";
import { mq } from "../common/utils";

const Wrapper = styled.div`
  margin-top: 5rem;
  ${mq("desktop")`
    margin-top: 0;
  `}
`;

const TrafficInfoButton = styled(Button)`
  text-decoration: none;
`;

const H2 = styled.h2`
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: 700;
  &::first-letter {
    text-transform: capitalize;
  }
`;

function NotFoundMessage(props) {
  return (
    <Wrapper>
      {props.backButton && <BackButton link="/" />}
      <H2 as="h1">{props.title}</H2>
      <p>{props.message}</p>
      <TrafficInfoButton as="a" href="/">
        Bekijk actuele verkeersinfo
      </TrafficInfoButton>
    </Wrapper>
  );
}

export default NotFoundMessage;
