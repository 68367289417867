import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import colors from "../common/colors";
import { ReactComponent as Incident } from "../common/icons/incident.svg";
import { loadWeatherAlerts, getWeatherAlert } from "./WeatherAlert/redux";
import ExternalLink from "../components/ExternalLink";

const WeatherAlertsWrapper = styled.div`
  border-bottom: 1px solid ${colors.gray200};
  padding: 1rem;
  background: ${colors.white};
`;

const AlertBar = styled.div`
  width: 40px;
  color: ${colors.white};
  border-radius: 5px 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  ${props =>
    css`
      background: ${colors.weeralarm[props.code]};
    `}
  ${props =>
    props.code === "geel" &&
    css`
      color: ${colors.gray900};
    `}
`;

const AlertText = styled.div`
  border: 1px solid ${colors.gray300};
  border-left: 0;
  background: ${colors.gray100};
  padding: 10px;
  border-radius: 0 5px 5px 0;
  flex-grow: 1;
  p {
    margin: 0;
    padding: 0;
  }
  a {
    color: ${colors.hemelsblauw};
  }
`;

const WeatherAlertBox = styled.div`
  display: flex;
  & + & {
    margin-top: 1rem;
  }
`;

const AlertIcon = styled(Incident)`
  width: 24px;
  height: 24px;
  margin: 0 auto;
`;

function WeatherAlertList(props) {
  const { items = [] } = props;
  if (props.loading) {
    return <p>Bezig met laden...</p>;
  }
  return items.map(item => (
    <WeatherAlertBox key={item.id}>
      <AlertBar code={item.code}>
        <AlertIcon />
      </AlertBar>
      <AlertText>
        <strong>Code {item.code}:</strong> {item.message} &nbsp;
        {item.url.length > 0 && (
          <>
            (<ExternalLink href={item.url}>KNMI</ExternalLink>)
          </>
        )}
      </AlertText>
    </WeatherAlertBox>
  ));
}

function WeatherAlert() {
  const result = useSelector(getWeatherAlert);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadWeatherAlerts());
  }, [dispatch]);

  const { items = [] } = result;
  if (items.length === 0) {
    return null;
  }
  return (
    <WeatherAlertsWrapper>
      <WeatherAlertList {...result} />
    </WeatherAlertsWrapper>
  );
}

export default WeatherAlert;
