import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { getNewsItem, loadArticle } from "../components/News/redux";
import { mq, breakpoints } from "../common/utils";
import colors from "../common/colors";
import ShareModal from "../components/ShareModal";
import {
  format,
  parseISO,
} from "date-fns";
import { nl } from "date-fns/locale/nl";
import RichText from "../components/RichText";
import { Wrapper } from "./ContactPage";
import RelatedNews from "../components/News/RelatedNews";
import { Helmet } from "react-helmet-async";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { RWSContext } from "../context";

export const Lead = styled.div`
  font-size: 22px;
  color: ${colors.gray900};
  line-height: 35px;
  margin-bottom: 1rem;
  p {
    font-size: 22px;
    color: ${colors.gray900};
    line-height: 35px;
  }
  a {
    color: ${colors.gray900};
  }
`;

export const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 160px;
  ${mq("tablet")`
    height: 280px;
  `}
  ${mq("desktop")`
    height: 400px;
  `}
`;

const PreTitle = styled.p`
  font-size: 18px;
  line-height: 1.3;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
`;

export const NewsLink = styled(Link)`
  color: ${colors.hemelsblauw};
  text-decoration: none;
`;

const ArticleInfo = styled.div`
  font-size: 1rem;
  color: ${colors.gray700};
  border-left: 4px solid ${colors.gray200};
  padding-left: 1rem;
  font-style: italic;
  line-height: 1.5em;
  margin-bottom: 1.5rem;
`;

const Categories = styled.span`
  display: inline;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 400;
  text-transform: none;
  color: ${colors.gray600};
  a {
    color: ${colors.gray700};
    &:hover {
      color: ${colors.hemelsblauw};
    }
  }
`;

function PublicationDate({ date, modified }) {
  return (
    <ArticleInfo>
      {date && (
        <React.Fragment>
          Gepubliceerd op:&nbsp;
          <time dateTime={date}>
            {date && format(parseISO(date), "d LLLL yyyy", { locale: nl })}
          </time>
        </React.Fragment>
      )}
      {modified && (
        <React.Fragment>
          <span> - Laatste update: </span>
          <time dateTime={modified}>
            {modified && format(parseISO(modified), "d LLLL yyyy HH:mm", { locale: nl })} uur
          </time>
        </React.Fragment>
      )}
    </ArticleInfo>
  );
}

function NewsArticle(props) {
  const { slug } = props.match.params;
  const result = useSelector(state => getNewsItem(state, slug));
  const dispatch = useDispatch();
  const { shouldLoad, item, loading } = result;
  const { apiUrl } = useContext(RWSContext) || {};

  useEffect(() => {
    if (shouldLoad) {
      dispatch(loadArticle(slug));
    }
  }, [dispatch, slug, shouldLoad]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  if (loading || shouldLoad) {
    return <Loading fullHeight />;
  }
  if (Object.keys(item).length > 0) {
    const headerImage = item.header_image || {};
    const listImage = item.list_image || {};
    return (
      <React.Fragment>
        <Helmet>
          <title>{item.browser_title ?? item.title}</title>
          <meta name="description" content={item.meta.search_description} />
          <meta property="og:title" content={item.title} />
          <meta property="og:description" content={item.meta.search_description} />
          {Object.keys(listImage).length > 0 && (
            <meta property="og:image" content={`${apiUrl}${listImage.header_meta.url}`} />
          )}
        </Helmet>
        {Object.keys(headerImage).length > 0 && (
          <picture>
            <source
              srcSet={`${apiUrl}${headerImage.header_large.url}`}
              media={`(min-width: ${breakpoints.get("desktop")}px)`}
            />
            <Img
              src={`${apiUrl}${headerImage.header_medium.url}`}
              alt={headerImage.header_medium.alt}
            />
          </picture>
        )}
        <Wrapper>
          <PreTitle>
            <NewsLink to="/nieuws">Nieuwsbericht</NewsLink>
            <Categories>
              {item.categories.length > 0 && <span> in </span>}
              {item.categories.map((category, index) => (
                <React.Fragment key={category.id}>
                  {index > 0 && ", "}
                  <Link to={`/${category.slug}/`}>{category.title}</Link>
                </React.Fragment>
              ))}
            </Categories>
          </PreTitle>
          <h1>{item.title}</h1>
          <PublicationDate
            date={item.date}
            modified={item?.last_modified}
            categories={item.categories}
          />
          <Lead dangerouslySetInnerHTML={{ __html: item.intro }} />
          <RichText content={item.body} />
          <ShareModal title="Deel artikel" url={window.location.href} />
          <RelatedNews news={item.related_news} />
        </Wrapper>
      </React.Fragment>
    );
  }
  return (
    <Wrapper>
      <PreTitle>
        <NewsLink to="/nieuws">Nieuws</NewsLink>
      </PreTitle>
      <h1>Artikel niet gevonden</h1>
      <p>Dit artikel kon niet gevonden worden.</p>
      <NewsLink to="/nieuws">Terug naar het nieuwsoverzicht</NewsLink>
    </Wrapper>
  );
}

export default NewsArticle;
