import React from "react";
import { Wrapper, Page } from "./ContactPage";
import SubHeader from "../components/SubHeader";
import { Helmet } from "react-helmet-async";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import slugify from "../utils/slugify";
import styled from "styled-components";
import colors from "../common/colors";

const Link = styled(NavLink)`
  color: ${colors.logoblauw};
  text-decoration: none;
`;

const List = styled.ul`
  font-size: 20px;
`;

const ListItem = styled.li`
  margin: 0;
`;

const Title = styled.h2`
  margin-bottom: 0;
  font-size: 20px;
`;

function Sitemap(props) {
  const { items = [] } = props;
  return (
    <Page>
      <Helmet>
        <title>Sitemap</title>
      </Helmet>
      <SubHeader />
      <Wrapper>
        <h1>Sitemap</h1>
        <List>
          {items.map((item, i) => (
            <React.Fragment key={i}>
              {i === 3 && (
                <ListItem>
                  <Title>Achtergrond</Title>
                </ListItem>
              )}
              <ListItem>
                <Link
                  exact={item.exact}
                  activeClassName="active"
                  to={Boolean(item.url) ? item.url : `/${slugify(item.title)}`}
                >
                  {item.title}
                </Link>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Wrapper>
      <Footer fullWidth />
    </Page>
  );
}

export default Sitemap;
