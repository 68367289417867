import React from "react";
import styled from "styled-components";
import { ReactComponent as IconFullscreen } from "./icons/fullscreen.svg";

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 1111;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1.5rem;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    z-index: 2;
  }
  &::after {
    transform: rotate(180deg);
    bottom: 0;
    top: auto;
  }
`;

const FullscreenButton = styled.button`
  background: #fff;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: 0;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

function Overlay(props) {
  return (
    <Layer>
      <FullscreenButton aria-label="Maak kaart groter" type="button" onClick={props.onClick}>
        <IconFullscreen title="Maak kaart groter" />
      </FullscreenButton>
    </Layer>
  );
}

export default Overlay;
