const actionTypes = {
  SET_FILTERS: "map/set_filters",
  ADD_FILTER: "map/add_filter",
  REMOVE_FILTER: "map/remove_filter",
  SET_DISPLAY: "map/set_display",
  SET_LEGENDA: "map/set_legenda",
};

const initialState = {
  filters: ["traffic", "incidents"],
  display: "traffic",
  legenda: true,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case actionTypes.ADD_FILTER:
      return {
        ...state,
        filters: [...state.filters, action.filter],
      };
    case actionTypes.REMOVE_FILTER:
      return {
        ...state,
        filters: [...state.filters.filter(x => x !== action.filter)],
      };
    case actionTypes.SET_DISPLAY:
      return {
        ...state,
        display: action.display,
      };
    case actionTypes.SET_LEGENDA:
      return {
        ...state,
        legenda: action.legenda,
      };

    default:
      return state;
  }
}

export const getFilters = state => {
  const { filters } = state.map;
  return filters || [];
};

export const addFilter = filter => ({
  type: actionTypes.ADD_FILTER,
  filter,
});

export const removeFilter = filter => ({
  type: actionTypes.REMOVE_FILTER,
  filter,
});

export const setFilters = filters => ({
  type: actionTypes.SET_FILTERS,
  filters,
});

export const setMapDisplay = display => ({
  type: actionTypes.SET_DISPLAY,
  display,
});

export const getMapDisplay = state => {
  return state.map.display;
};

export const getLegendaIsCollapsed = state => {
  return state.map.legenda;
};

export const setLegendaIsCollapsed = legenda => ({
  type: actionTypes.SET_LEGENDA,
  legenda,
});
