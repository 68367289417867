import React, { useState, useEffect } from "react";
import Results from "../components/Search/Results";
import { useDispatch, useSelector } from "react-redux";
import { useDeepEffect } from "../utils/hooks";
import { loadRoadObstructions, getObstructionsOnRoad } from "../components/Road/redux";
import { setMapDisplay } from "../components/Map/redux";

const getPayloadFromUrl = params => {
  const { road, date } = params;
  return {
    road: decodeURIComponent(road),
    date: new Date(`${date}T00:00:00.000Z`),
  };
};

function RoadResults(props) {
  const {
    match: { params },
  } = props;
  const [payload, setPayload] = useState(getPayloadFromUrl(params));

  const dispatch = useDispatch();

  useDeepEffect(() => {
    setPayload(getPayloadFromUrl(params));
  }, [params, setPayload]);

  useEffect(() => {
    dispatch(loadRoadObstructions(payload));
    dispatch(setMapDisplay("road"));
  }, [dispatch, payload]);

  const obstructions = useSelector(getObstructionsOnRoad);

  return (
    <Results
      {...props}
      payload={payload}
      obstructions={obstructions}
      title={`Hinder op de ${payload.road}`}
    />
  );
}

export default RoadResults;
