import React, { useContext } from "react";
import ErrorBoundary from "../components/ErrorBoundary";
import Footer from "../components/Footer";
import ScrollToTop from "../components/ScrollToTop";
import SubHeader from "../components/SubHeader";
import { Page } from "./ContactPage";
import { NewsList } from "./News";
import { Helmet } from "react-helmet-async";
import { RWSContext } from "../context";
import styled from "styled-components";
import colors from "../common/colors";
import { ReactComponent as Chevron } from "../common/icons/chevron.svg";

const Link = styled.a`
  background: ${colors.white};
  color: ${colors.hemelsblauw};
  border-width: 0;
  text-align: center;
  text-decoration: none;
  padding: 10px 0;
  font-weight: bold;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  &:hover {
    color: ${colors.hemelsblauw};
  }
  svg {
    margin-right: 7px;
  }
`;

const BackLinkContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem 1rem 0rem;
`;

function CategoryPage({ category, categoryOverviewSlug }) {
  const listImage = category.feed_image || {};
  const { apiUrl } = useContext(RWSContext) || {};
  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content={category.search_description} />
        <meta property="og:title" content={category.seo_title ?? category.title} />
        <meta property="og:description" content={category.search_description} />
        {Object.keys(listImage).length > 0 && (
          <meta property="og:image" content={`${apiUrl}${listImage.header_meta.url}`} />
        )}
      </Helmet>
      <Page>
        <ScrollToTop />
        <SubHeader />
        <ErrorBoundary>
          <BackLinkContainer>
            <Link href={categoryOverviewSlug}>
              <Chevron />
              Terug naar het overzicht
            </Link>
          </BackLinkContainer>
          <NewsList
            categoryId={category.id}
            pageTitle={category.seo_title ?? category.title}
            intro={category.short_description}
            description={category.search_description}
          />
        </ErrorBoundary>
        <Footer fullWidth />
      </Page>
    </React.Fragment>
  );
}

export default CategoryPage;
