import { processPayload } from "../../common/utils";

const actionTypes = {
  LOAD: "obstructions/load",
  LOAD_SUCCESS: "obstructions/load_success",
  LOAD_FAILED: "obstructions/load_failed",
  LOAD_ITEM: "obstructions/load_item",
  LOAD_ITEM_SUCCESS: "obstructions/load_item_success",
  LOAD_ITEM_FAILED: "obstructions/load_item_failed",
};

const initialState = {
  loading: false,
  loaded: false,
  items: {},
  error: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        error: {},
        items: {
          ...state.items,
          ...processPayload(action.payload),
        },
        count: action.payload.count,
        loading: false,
        loaded: true,
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case actionTypes.LOAD_ITEM:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
      };
    case actionTypes.LOAD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: {},
        items: {
          ...state.items,
          [action.payload.id]: {
            ...state.items[action.payload.id],
            ...action.payload,
            dateLoaded: new Date().getTime(),
          },
        },
      };
    case actionTypes.LOAD_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        loaded: false,
        [action.requestData.id]: {
          ...state.items[action.requestData.id],
          error: action.error,
        },
      };
    default:
      return state;
  }
}

export const loadObstructions = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: `/obstructions/`,
});

export const loadObstruction = id => ({
  types: [actionTypes.LOAD_ITEM, actionTypes.LOAD_ITEM_SUCCESS, actionTypes.LOAD_ITEM_FAILED],
  method: "GET",
  url: `/obstructions/${id}/`,
  payload: {
    id,
  },
});

export const getObstructionList = state => {
  const {
    obstructions: { items, loading, loaded, error, count },
  } = state;

  const visibleItems = Object.keys(items) // get all ids from object
    .map(key => items[key]); // convert it to an array of objects
  return {
    items: visibleItems,
    loading,
    loaded,
    error,
    count,
  };
};

export const getObstruction = (state, id) => {
  const {
    obstructions: { items, loading },
  } = state;
  const item = items[id];
  const { error = {} } = item || {};

  const needsLoading = !(Boolean(item) && item.dateLoaded + 1000 * 60 < new Date().getTime());
  return {
    item,
    loading,
    error,
    loaded: Boolean(item),
    needsLoading,
  };
};
