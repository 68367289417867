import React from "react";
import styled, { css } from "styled-components";
import colors from "../common/colors";

const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => !['large', 'outline', 'block'].includes(prop),
})`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  box-shadow: none;
  background: ${colors.geel};
  border: 0;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  color: ${colors.gray900};
  cursor: pointer;
  ${props =>
    props.large &&
    css`
      height: 48px;
      width: 100%;
    `}
  &:hover {
    background: ${colors.donkergeel};
  }
  ${props =>
    props.outline &&
    css`
      background: ${colors.white};
      color: ${colors.hemelsblauw};
      border: 1px solid currentColor;
      &:hover {
        background: rgba(217, 235, 247, 0.5);
      }
    `}
  ${props =>
    props.block &&
    css`
      width: 100%;
    `}
  ${props =>
    props.disabled &&
    css`
      color: ${colors.gray700};
      cursor: not-allowed;
      &:hover {
        background: ${colors.geel};
      }
    `}
`;

const Switcher = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})`
  color: ${colors.gray900};
  border: 1px solid ${colors.gray400};
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

const Switch = styled.div`
  width: 40px;
  height: 24px;
  border-radius: 9999px;
  background-color: ${colors.gray200};
  margin-left: 8px;
  ${props =>
    props.active &&
    css`
      background-color: ${colors.hemelsblauw};
    `}
  &::before {
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    margin-top: 2px;
    margin-left: 2px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.3);
    transition: all 0.1s ease-in-out;
    ${props =>
      props.active &&
      css`
        transform: translateX(15px);
      `}
  }
`;
const ButtonText = styled.div`
  &::before {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
    background-color: ${colors.hemelsblauw};
    content: "";
    margin-right: 6px;
  }
`;

export function SwitchButton(props) {
  return (
    <Switcher
      aria-pressed={props.active ? "true" : "false"}
      aria-label={props.active ? "Live stream aan" : "Live stream uit"}
      outline
      {...props}
    >
      <ButtonText>Live stream</ButtonText>
      <Switch active={props.active} />
    </Switcher>
  );
}

export default Button;

export const RoundButton = styled.button`
  z-index: 1005; /* otherwise roads will lay over this button */
  border-radius: 9999px;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  color: ${colors.hemelsblauw};
  svg {
    width: 16px;
    height: 16px;
  }
`;
