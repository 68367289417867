import React from "react";
import { useSelector } from "react-redux";
import ResultPanel from "../layouts/ResultPanel";
import { getCamera } from "../components/Camera/redux";
import CameraStream from "../components/Camera";
import styled from "styled-components";
import ShareModal from "../components/ShareModal";
import { useLoadCameras } from "../components/Camera/hooks";
import CameraMarker from "../components/Map/CameraMarker";
import colors from "../common/colors";
import Minimap from "../components/Map/Minimap";
import { Helmet } from "react-helmet-async";

const Content = styled.div`
  padding-top: 1.5rem;
`;

const Span = styled.span`
  a {
    color: ${colors.hemelsblauw};
  }
`;

function Camera(props) {
  const { id } = props.match.params;
  const { camera = {}, loading, shouldLoad } = useSelector(store => getCamera(store, id));
  const cameraSubtitle = `${camera.road} t.h.v. ${camera.near}`;
  useLoadCameras(shouldLoad);

  return (
    <ResultPanel
      {...props}
      title="Verkeerscamera"
      subtitle={cameraSubtitle}
      subsubtitle={camera.location_description}
      road={camera.road}
      loading={loading}
      map={
        <Minimap
          latitude={camera.latitude}
          longitude={camera.longitude}
          marker={<CameraMarker {...camera} clickable={false} />}
          zoomLevel={8}
        />
      }
    >
      <Helmet>
        <title>{`Verkeerscamera ${cameraSubtitle}`}</title>
      </Helmet>
      <CameraStream staticImage={camera.static_url} video={camera.stream_url} title={`Verkeerscamera ${cameraSubtitle}`} />
      <Content>
        {camera.description && <p>{camera.description}</p>}
        {camera.attribution && (
          <p>
            <strong>Met dank aan: </strong>
            <Span dangerouslySetInnerHTML={{ __html: camera.attribution }} />
          </p>
        )}
      </Content>
      <ShareModal url={window.location.href} title="Deel verkeerscamera" />
    </ResultPanel>
  );
}

export default Camera;
