import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import styled from "styled-components";
import colors from "../common/colors";
import { mq } from "../common/utils";
import Tooltip from "./Tooltip";
import { ReactComponent as Share } from "../common/icons/share.svg";
import { ReactComponent as Facebook } from "../common/icons/facebook.svg";
import { ReactComponent as LinkedIn } from "../common/icons/linkedin.svg";
import { ReactComponent as Whatsapp } from "../common/icons/whatsapp.svg";
import { ReactComponent as Twitter } from "../common/icons/twitter.svg";
import { ReactComponent as Email } from "../common/icons/email.svg";
import { ReactComponent as Check } from "../common/icons/check.svg";
import Button from "./Button";

const FacebookIcon = styled(Facebook)`
  color: #3c5a99;
`;

const LinkedInIcon = styled(LinkedIn)`
  color: #007cad;
`;

const WhatsappIcon = styled(Whatsapp)`
  color: #34af23;
`;

const TwitterIcon = styled(Twitter)`
  color: #1da1f2;
`;

const EmailIcon = styled(Email)`
  color: ${colors.gray900};
  width: 24px;
`;

const ShareIcon = styled(Share)`
  margin-right: 10px;
`;

const ShareButton = styled(Button)`
  margin-top: 2rem;
  margin-left: auto;
`;

const SocialButton = styled(Button).attrs(props => ({
  outline: true,
  target: "_blank",
  rel: "noreferrer nofollow",
  as: "a",
}))`
  border: 1px solid ${colors.gray400};
  color: ${colors.gray900};
  text-decoration: none;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

const CopyButton = styled(Button)`
  margin-left: auto;
  min-width: 88px;
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-columns: 100%;
  ${mq("tablet")`
    grid-template-columns: 1fr 1fr;
  `}
`;

const InputWrapper = styled.div`
  background-color: ${colors.gray100};
  height: 56px;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  padding: 0 0.75rem 0 0;
  align-items: center;
`;

const Url = styled.input`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  flex: 1;
  height: 100%;
  padding-left: 0.75rem;
`;

const ButtonWrapper = styled.div`
  position: relative;
`;

function ShareModal(props) {
  const [modalOpen, toggleModal] = useState(false);
  const [copied, setCopied] = useState(false);

  let timer;
  const copy = evt => {
    evt.preventDefault();
    setCopied(true);

    timer = setTimeout(() => {
      setCopied(false);
    }, 5000);
    document.getElementById("url").select();
    document.execCommand("copy");
  };

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: props.newsTitle,
          url: props.url,
        })
        .catch(() => {});
    } else {
      toggleModal(true);
    }
  };

  useEffect(() => {
    clearTimeout(timer);
  }, [modalOpen, timer]);

  const modal = modalOpen ? (
    <Modal
      title={props.title}
      exit={() => toggleModal(false)}
      content={
        <React.Fragment>
          <ButtonGrid>
            <SocialButton href={`https://www.facebook.com/sharer/sharer.php?u=${props.url}`}>
              <FacebookIcon />
              Facebook
            </SocialButton>
            <SocialButton
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${props.url}&title=${props.newsTitle}`}
            >
              <LinkedInIcon />
              LinkedIn
            </SocialButton>
            <SocialButton href={`https://wa.me/?text=${props.url}`}>
              <WhatsappIcon />
              Whatsapp
            </SocialButton>
            <SocialButton href={`http://twitter.com/share?&url=${props.url}`}>
              <TwitterIcon />
              Twitter
            </SocialButton>
            <SocialButton href={`mailto:?body=${encodeURIComponent(props.url)}`}>
              <EmailIcon />
              E-Mail
            </SocialButton>
          </ButtonGrid>
          <InputWrapper>
            <Url value={props.url} readOnly id="url" />
            <ButtonWrapper>
              {copied && (
                <Tooltip vertical visible role="alert">
                  Gekopieerd
                </Tooltip>
              )}
              <CopyButton aria-label={copied ? "Gekopieerd" : "Kopieer"} outline onClick={copy}>
                {copied ? <Check /> : "Kopieer"}
              </CopyButton>
            </ButtonWrapper>
          </InputWrapper>
        </React.Fragment>
      }
    />
  ) : null;
  return (
    <React.Fragment>
      <ShareButton outline onClick={share}>
        <ShareIcon /> {props.title}
      </ShareButton>
      {modal}
    </React.Fragment>
  );
}

export default ShareModal;
