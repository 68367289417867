const actionTypes = {
  LOAD: "menuitems/load",
  LOAD_SUCCESS: "menuitems/load_success",
  LOAD_FAILED: "menuitems/load_failed",
};

export function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        loaded: true,
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export const getMenuItems = state => {
  const {
    categories: { loading, loaded, categories = [], error = {} },
    categoryoverviewpage: { page: categoryOverviewPage },
  } = state;
  const shouldLoad = !loading && !loaded && Object.keys(error).length === 0;

  let results = categories.filter(x => x.show_in_menu);
  // add menuitem if category page has show_in_menus turned on.
  if (categoryOverviewPage?.meta?.show_in_menus) {
    results = [
      {
        menu_icon: "newspaper",
        ...categoryOverviewPage,
      },
      ...results,
    ];
  }

  return {
    loading,
    shouldLoad,
    results,
  };
};
