export const processPayload = (payload, key = "results") => {
  if (payload.hasOwnProperty(key) && Array.isArray(payload[key])) {
    // let's check if first item in array is an object
    const firstItem = payload[key][0];
    if (typeof firstItem === "object" && firstItem.hasOwnProperty("id")) {
      // convert [{ id: 1, key: 'prop'}, { id: 2, key: 'tjop'}]
      // into { 1: { id: 1, key: 'prop'}, 2: { id: 2, key: 'tjop'} }
      // disable eslint no-sequences rule because prettier removes the extra parentheses.
      // eslint-disable-next-line
      return payload[key].reduce((a, b) => ((a[b.id] = b), a), {});
    } else {
      // eslint-disable-next-line
      return payload[key].reduce((a, b) => ((a[b] = b), a), {});
    }
  }
  // eslint-disable-next-line
  return payload.reduce((a, b) => ((a[b] = b), a), {});
};
