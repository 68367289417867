import React from "react";
import styled, { css } from "styled-components";
import colors from "../common/colors";

const Wrapper = styled.div.attrs(() => ({ className: "eov-search-field" }))`
  position: relative;
  border: 2px solid transparent;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  padding-left: 16px;
  box-shadow: 0 0 0 1px ${colors.gray700};
  &:focus-within {
    border: 2px solid ${colors.hemelsblauw};
    box-shadow: 0 0 0 0 ${colors.gray400};
  }
  background: ${colors.white};
  margin-top: 8px;
  ${props =>
    props.disabled &&
    css`
      background: ${colors.gray100};
      label {
        opacity: 0.7;
      }
    `}
  ${props =>
    props.error &&
    css`
      border: 2px solid ${colors.signaalkleuren.rood};
      box-shadow: 0 0 0 0 ${colors.gray400};
    `}
`;

const Input = styled.input.attrs(props => ({
  className: "eov-search-input",
  "aria-labelledby": props.ariaLabelledby,
  id: props.identifier || props.id,
}))`
  width: 100%;
  border: 0;
  line-height: 44px;
  font-size: 16px;
  border-radius: 4px;
  padding: 0;
  &:focus {
    outline: 0;
  }
  &[disabled] {
    background: transparent;
  }
  ::placeholder {
    color: ${colors.gray800};
  }
`;

const Label = styled.label.attrs(() => ({ className: "eov-search-fieldlabel" }))`
  width: 50px;
  display: flex;
  align-items: center;
  font-weight: bold;
  ${props =>
    props.wideLabel &&
    css`
      width: auto;
      padding-right: 1rem;
    `}
`;

function InputComponent(props) {
  return (
    <Wrapper>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Input
        type={props.type || "text"}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
      />
    </Wrapper>
  );
}

export default InputComponent;

export { Wrapper, Input, Label };
