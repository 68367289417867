import React from "react";
import { Map, TileLayer, Polyline } from "react-leaflet";
import L, { CRS } from "leaflet";
import "leaflet-rd";
import decodePolyline from "decode-google-map-polyline";
import colors from "../../common/colors";

function Minimap(props) {
  const { latitude = 0, longitude = 0, zoomLevel, marker, polyline, obstruction } = props;
  if (latitude === 0 && longitude === 0) {
    return null;
  }
  const extraProps = {};
  if (polyline && Object.keys(props.bounds).length === 2) {
    extraProps.bounds = L.latLngBounds(
      L.latLng(...props.bounds.start),
      L.latLng(...props.bounds.end),
    ).pad(1);
  } else {
    extraProps.zoom = props.zoomLevel;
  }
  return (
    <Map
      center={[latitude, longitude]}
      crs={CRS.RD}
      attributionControl={false}
      zoomControl={false}
      style={{ backgroundColor: "#d6f1e8", height: "100%" }}
      trackResize
      dragging={false}
      doubleClickZoom={false}
      boxZoom={false}
      scrollWheelZoom={false}
      touchZoom={false}
      tap={false}
      {...extraProps}
    >
      <TileLayer
        url="https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:28992/{z}/{x}/{y}.png"
        attribution=""
        minZoom={zoomLevel}
        maxZoom={zoomLevel}
        continuousWorld={true}
        reuseTiles={true}
        unloadInvisibleTiles={false}
        updateWhenIdle={true}
        maxClusterRadius={40}
      />
      {polyline && (
        <Polyline
          opacity={1}
          weight={6}
          color={
            obstruction.obstructionType === 4
              ? colors.signaalkleuren.rood
              : colors.signaalkleuren.donkergeel
          }
          positions={decodePolyline(polyline)}
        />
      )}
      {marker}
    </Map>
  );
}

export default Minimap;
