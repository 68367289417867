import React from "react";
import styled, { css } from "styled-components";
import colors from "../../common/colors";
import { Wrapper, Input, Label } from "../Input";
import Geolocator from "./Geolocator";
import Highlight from "./Highlight";
import { ReactComponent as SwitchIcon } from "./switch.svg";

const FieldWrapper = styled.div.attrs(() => ({ className: "eov-search-fieldwrapper" }))``;

const Menu = styled.ul`
  position: absolute;
  left: -3px;
  right: -3px;
  top: calc(100% + 2px);
  background: ${colors.white};
  list-style: none;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  margin: 0;
  padding: 0;
  border: 1px solid ${colors.gray300};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.gray100};
  }
  ${props =>
    props.highlighted &&
    css`
      background-color: ${colors.gray100};
    `}
`;

const SwitchButton = styled.button.attrs(() => ({
  type: "button",
  className: "eov-search-switchbutton",
}))`
  background: ${colors.white};
  border: 0;
  box-shadow: none;
  padding: 0;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    color: ${colors.hemelsblauw};
    width: 16px;
    height: 16px;
  }
`;

export const Error = styled.p.attrs(() => ({ className: "eov-search-fielderror" }))`
  color: ${colors.signaalkleuren.rood};
  font-size: 13px;
  padding-top: 4px;
  margin: 0;
`;

class Autocomplete extends React.Component {
  static defaultProps = {
    items: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      focused: false,
      highlightedOption: -1,
    };

    this.geocodedValues = {};

    this.input = React.createRef();
    this.wrapper = React.createRef();
    this.geolocator = React.createRef();

    // bind 'this' to methods
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.onGeolocatorKeydown = this.onGeolocatorKeydown.bind(this);
    this.onLocationFound = this.onLocationFound.bind(this);
    this.getItems = this.getItems.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.mounted = true;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.onClickOutside, false);
    if (this.props.focusOnMount) {
      this.input.current.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onClickOutside, false);
    this.mounted = false;
  }

  onClickOutside(evt = {}) {
    if (this.wrapper.current.contains(evt.target)) {
      return;
    }
    this.setState({ focused: false });
  }

  onBlur(evt = {}) {
    if (evt.relatedTarget && !this.wrapper.current.contains(evt.relatedTarget)) {
      this.setState({ focused: false });
    }
  }

  onFocus() {
    this.setState({ focused: true });
  }

  onChange(evt) {
    this.props.onChange(evt.target.value);
    this.setState({ highlightedOption: 0 });
    this.getItems(evt.target.value);
  }

  onKeyDown(event) {
    switch (event.key) {
      case "ArrowDown":
        // select geolocator button when pressing arrow down
        if (this.props.showCurrentLocation && this.props.value.length === 0) {
          this.geolocator.current.focus();
          event.preventDefault();
          break;
        }

        // get index of last item in autocomplete suggestions
        const largestItemIndex = this.state.items.length - 1 < 0 ? 0 : this.state.items.length - 1;
        this.setState(state => ({
          highlightedOption:
            state.highlightedOption === largestItemIndex
              ? largestItemIndex
              : state.highlightedOption + 1,
        }));
        break;
      case "ArrowUp":
        this.setState(state => ({
          highlightedOption: state.highlightedOption === 0 ? 0 : state.highlightedOption - 1,
        }));
        break;
      case "Enter":
        if (this.state.items && this.state.items.length > 0 && this.state.focused) {
          this.selectOption(this.state.highlightedOption);
          event.preventDefault();
        }
        break;
      case "Tab":
        this.setState({ focused: false });
        break;
      default:
        this.setState({ focused: true });
        break;
    }
  }

  onGeolocatorKeydown(event) {
    switch (event.key) {
      case "ArrowUp":
        // bring focus back to input field
        this.input.current.focus();
        break;
      case "ArrowDown":
        // this prevents scrolling of the whole page.
        event.preventDefault();
        break;
      case "Tab":
        // remove focus from this element and go to the next one.
        this.setState({ focused: false });
        break;
      default:
        break;
    }
  }

  onLocationFound(location) {
    this.props.onChange(location);
    this.input.current.focus();
  }

  async getItems(query = "") {
    // search for places matching the query
    if (query.length < this.props.minChars) {
      this.setState({ items: [] });
      return;
    }
    try {
      const response = await this.props.dataSource(query);
      if (this.mounted) {
        this.setState({ items: response });
      }
    } catch (error) {
      this.setState({ error });
    }
  }

  selectOption(index) {
    if (index >= 0) {
      const value = this.state.items[index] || "";
      this.props.onChange(value);
    }
    this.setState({ focused: false });
  }

  render() {
    const { wideLabel, label, showCurrentLocation, value = "", onSwitch } = this.props;
    const { focused, highlightedOption, items } = this.state;
    const error = this.props.error ? this.props.error.error : this.state.error;
    const showMenu = focused;

    return (
      <FieldWrapper>
        <Wrapper ref={this.wrapper} error={Boolean(error)}>
          <Label htmlFor={label} wideLabel={wideLabel}>
            {label}
          </Label>
          <Input
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            value={value}
            id={label}
            autoComplete="off"
            ref={this.input}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          />
          {onSwitch && (
            <SwitchButton aria-label="Wissel 'van' en 'naar' om" onClick={onSwitch}>
              <SwitchIcon title="Wissel 'van' en 'naar' om" />
            </SwitchButton>
          )}
          {showMenu && items && (
            <Menu>
              {items.map((item, index) => (
                <MenuItem
                  key={`${index}`}
                  highlighted={index === highlightedOption}
                  onClick={() => this.selectOption(index)}
                >
                  <Highlight str={item} highlight={value} />
                </MenuItem>
              ))}
              {showCurrentLocation && value.length === 0 && (
                <MenuItem>
                  <Geolocator
                    onLocationFound={this.onLocationFound}
                    ref={this.geolocator}
                    onKeyDown={this.onGeolocatorKeydown}
                  />
                </MenuItem>
              )}
            </Menu>
          )}
        </Wrapper>
        <Error aria-live="polite">{error?.toString()}</Error>
      </FieldWrapper>
    );
  }
}

Autocomplete.defaultProps = {
  onChange: () => {},
  value: "",
  minChars: 3,
};

export default Autocomplete;
