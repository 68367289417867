import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useInterval } from "./hooks";
import colors from "../../common/colors";
import { SwitchButton } from "../Button";

const Wrapper = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
`;

export const Video = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: absolute;
  top: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const VideoSubText = styled.div`
  color: ${colors.gray700};
  margin-top: 0.5rem;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
`;

function Camera(props) {
  const [stream, toggleStream] = useState(false);
  const { video, staticImage, title } = props;
  const [timestamp, setTimestamp] = useState(new Date().getTime());
  const [failCount, setFailCount] = useState(0);
  const [delay, setDelay] = useState(2000);

  useInterval(() => {
    setTimestamp(timestamp + delay);
  }, delay);

  useEffect(() => {
    if (failCount > 2) {
      setDelay(null);
    }
  }, [failCount, setDelay]);

  const imageLoadingError = () => {
    setFailCount(count => (count += 1));
  };

  return (
    <React.Fragment>
      <Wrapper>
        {stream ? (
          <Video>
            <iframe
              src={`${video}?h=200&w=325&autoplay=1`}
              allow="autoplay; fullscreen"
              title={`${video}?h=200&w=325&autoplay=1`}
              allowfullscreen
            />
          </Video>
        ) : (
          <Image src={`${staticImage}?${timestamp}`} alt={title} onError={imageLoadingError} />
        )}
      </Wrapper>

      {failCount < 3 ? (
        <VideoSubText>Ververst automatisch iedere 2 seconden.</VideoSubText>
      ) : (
        <VideoSubText>De stream is niet beschikbaar.</VideoSubText>
      )}

      <SwitchButton onClick={() => toggleStream(!stream)} active={stream} />
    </React.Fragment>
  );
}

export default Camera;
