import React from "react";
import axios from "axios";
import styled from "styled-components";
import Here from "../../api/here";
import { ReactComponent as IconLocation } from "../../common/icons/location.svg";
import colors from "../../common/colors";

const Button = styled.button.attrs(props => ({
  role: props.role,
}))`
  color: ${props => (props.error ? colors.signaalkleuren.rood : colors.hemelsblauw)};
  font-weight: bold;
  cursor: pointer;
  border: 0;
  background: transparent;
  box-shadow: 0;
  font-size: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  height: 32px;
  svg {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }
`;

export class Geolocator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
    };

    this.getLocalityFromCoords = this.getLocalityFromCoords.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.getCurrentLocation = this.getCurrentLocation.bind(this);
  }

  componentDidMount() {
    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();
  }

  componentWillUnmount() {
    // Cancel pending axios request
    this.source.cancel("Operation canceled by unmounting component.");
    // Cancel watching geolocation
    navigator.geolocation.clearWatch(this.watcher);
  }

  async getLocalityFromCoords(lat, lng) {
    // Convert lat/long pair to name using here api
    const url = Here.ReverseGeocodeUrl(lat, lng);
    try {
      const response = await axios.get(url, { cancelToken: this.source.token });
      this.setState({ loading: false });

      // get display_name from response
      const {
        data: { items },
      } = response;
      const { display_name } = items?.[0];

      // invoke callback
      this.props.onLocationFound(`${display_name}`);
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  onSuccess(position) {
    navigator.geolocation.clearWatch(this.watcher);
    const {
      coords: { latitude, longitude },
    } = position;
    this.getLocalityFromCoords(latitude, longitude);
  }

  onError(error) {
    let errorMessage = "Locatie kon niet opgehaald worden.";
    if (error.code === 1) {
      errorMessage = "Locatiegegevens niet toegestaan.";
    }
    this.setState({ loading: false, error: errorMessage });
  }

  getCurrentLocation(evt) {
    evt.preventDefault();
    this.setState({ loading: true, error: "" });
    this.watcher = navigator.geolocation.watchPosition(this.onSuccess, this.onError, {
      timeout: 5000,
      maximumAge: 10 * 60 * 1000, // 10 minutes.
    });
  }

  render() {
    const { loading, error } = this.state;
    const { forwardedRef } = this.props;

    return (
      <Button
        type="button"
        onClick={loading ? null : this.getCurrentLocation}
        error={!!this.state.error}
        ref={forwardedRef}
        onKeyDown={this.props.onKeyDown}
        role={loading ? "status" : error ? "alert" : null}
      >
        <IconLocation /> {loading ? "Bezig..." : error ? error : "Huidige locatie"}
      </Button>
    );
  }
}

export default React.forwardRef((props, ref) => {
  return <Geolocator {...props} forwardedRef={ref} />;
});
