import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PeakExpectationCard from "./PeakExpectation/Card";
import CardList from "./CardList";
import { loadPeakExpectationCards, getPeakExpectation } from "./PeakExpectation/redux";

function PeakExpectationList() {
  const result = useSelector(getPeakExpectation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPeakExpectationCards());
  }, [dispatch]);

  return (
    <CardList
      title="Spitsverwachting"
      link="/spitsverwachting/"
      linkText="Meer over de verwachting"
      card={PeakExpectationCard}
      tabletGrid
      mobileGrid={false}
      {...result}
    />
  );
}

export default PeakExpectationList;
