import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import RichText from "../components/RichText";
import styled, { css } from "styled-components";
import { mq } from "../common/utils";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { getContactPage, loadContactPage } from "../components/ContactPage/redux";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  word-break: break-word;
  ${mq("tablet")`
    padding-left: 0;
    padding-right: 0;
  `}
  h1 {
    font-size: 33px;
    line-height: 42px;
    margin-top: 0;
    margin-bottom: 18px;
    hyphens: auto;
    @media (min-width: 450px) {
      hyphens: none;
    }
    ${mq("tablet")`
      font-size: 45px;
      line-height: 58px;
    `}
  }
  ${props =>
    props.$large &&
    css`
      max-width: 996px;
      ${mq("tablet")`
        padding-left: 1rem;
        padding-right: 1rem;
      `}
    `}
`;

export const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

function ContactPage(props) {
  const dispatch = useDispatch();
  const { page, shouldLoad, loading } = useSelector(getContactPage);

  useEffect(() => {
    if (shouldLoad) {
      dispatch(loadContactPage());
    }
  }, [dispatch, shouldLoad]);

  if (loading || shouldLoad) {
    return (
      <Wrapper>
        <Loading fullHeight />
      </Wrapper>
    );
  }

  return (
    <Page>
      <Helmet>
        <title>{page.meta.seo_title ?? page.title}</title>
        <meta name="description" content={page.meta.search_description} />
      </Helmet>
      <SubHeader />
      <Wrapper>
        <h1>{page.title}</h1>
        <RichText content={page.body} />
      </Wrapper>
      <Footer fullWidth />
    </Page>
  );
}

export default ContactPage;
