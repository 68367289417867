import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "../components/PeakExpectation/Card";
import { Video as VideoDiv } from "../components/Camera";
import Footer from "../components/Footer";
import { Card as Disclaimer } from "../components/Disclaimer";
import {
  loadPeakExpectationPage,
  getPeakExpectationPage,
} from "../components/PeakExpectation/redux";
import styled from "styled-components";
import { mq } from "../common/utils";
import { Helmet } from "react-helmet-async";
import SubHeader from "../components/SubHeader";

const Video = styled(VideoDiv)`
  margin-bottom: 2rem;
  position: relative;
`;

const CardWrapper = styled.div`
  padding-bottom: 1rem;
  flex: 1 0 auto;
  display: flex;
  width: 100%;
  ${mq("tablet")`
    width: 50%;
    max-width: 50%;
    &:nth-child(odd) {
      padding-right: .5rem;
    }
    &:nth-child(even) {
      padding-left: .5rem;
    }
  `}
`;

const Grid = styled.div`
  margin-bottom: 1.5rem;
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  ${mq("tablet")`
    padding-left: 0;
    padding-right: 0;
  `}
`;

const Page = styled.div`
  width: 100%;
`;

const H1 = styled.h1`
  margin-top: 2rem;
`;

function YoutubeEmbed(props) {
  const { src = "" } = props;
  const youtubeRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
  const match = src.match(youtubeRegex);
  if (!match || match.length < 2) {
    return null;
  }
  const videoId = match[1];
  const cleanSrc = `https://www.youtube-nocookie.com/embed/${videoId}`;

  return (
    <Video>
      <iframe
        src={cleanSrc}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Video"
      />
    </Video>
  );
}

function PeakExpectation(props) {
  const result = useSelector(getPeakExpectationPage);
  const { loading, page, items, shouldLoad } = result;
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldLoad) {
      dispatch(loadPeakExpectationPage());
    }
  }, [dispatch, shouldLoad]);

  return (
    <Page>
      <SubHeader />
      <Wrapper>
        {page.loaded && (
          <>
            <Helmet>
              <title>{page.meta.seo_title ?? "Spitsverwachting van vandaag"}</title>
              <meta name="description" content={page.meta.search_description} />
            </Helmet>
            <H1>{page.title}</H1>
          </>
        )}
        {loading ? (
          <p>Bezig met laden...</p>
        ) : (
          <>
            <YoutubeEmbed src={page.youtube_embed} />
            <Grid>
              {items.map(item => (
                <CardWrapper key={item.id}>
                  <Card {...item} explanation />
                </CardWrapper>
              ))}
            </Grid>
          </>
        )}
        <Disclaimer dangerouslySetInnerHTML={{ __html: page.disclaimer }} />
      </Wrapper>
      <Footer fullWidth />
    </Page>
  );
}

export default PeakExpectation;
