import React, { useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import L from "leaflet";
import { Polyline, Tooltip, FeatureGroup, useLeaflet } from "react-leaflet";
import { selectRoute } from "../Route/redux";
import { useDispatch } from "react-redux";
import ObstructionMarker from "./ObstructionMarker";
import RouteMarker from "./RouteMarker";
import decodePolyline from "decode-google-map-polyline";
import colors from "../../common/colors";
import Color from "color";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { createClusterIcon } from "../Map";
import lineOverlap from "@turf/line-overlap";
import lineLength from "@turf/length";

const polylineToGeoJSON = polyline => {
  const coords = decodePolyline(polyline);
  const obj = {};
  obj.type = "LineString";
  obj.coordinates = coords.map(x => [x.lng, x.lat]);
  return obj;
};

const convertNullifiedPointsArrayToListOfFeatures = points => {
  const starts = [];
  const ends = [];
  let lookingForEnd = points[0] !== null;
  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    if (i === 0 && lookingForEnd) {
      starts.push(i);
    }
    if (lookingForEnd) {
      if (point === null) {
        ends.push(i);
        lookingForEnd = false;
      }
    } else {
      if (point !== null) {
        starts.push(i);
        lookingForEnd = true;
      }
    }
  }
  const segments = starts.map((start, index) => points.slice(start, ends[index]));
  const features = segments.map(coordinates => ({
    type: "Feature",
    geometry: {
      coordinates,
      type: "LineString",
    },
  }));
  return features;
};

const getLongestPointsSegment = features => {
  const lengths = features.map(feature => lineLength(feature.geometry));
  const index = lengths.indexOf(Math.max.apply(Math, lengths));
  return features[index];
};

const getTooltipPolyline = (thisRoute, activeRoute) => {
  if (!activeRoute) {
    return;
  }
  const thisRouteGeoJson = polylineToGeoJSON(thisRoute);
  const { features } = lineOverlap(thisRouteGeoJson, polylineToGeoJSON(activeRoute));
  const { coordinates } = thisRouteGeoJson;

  features.forEach(feature => {
    const {
      geometry: { coordinates: featureCoordinates },
    } = feature;
    featureCoordinates.forEach(featureCoordinate => {
      const index = coordinates.findIndex(c => {
        if (c) {
          return c[0] === featureCoordinate[0] && c[1] === featureCoordinate[1];
        }
        return false;
      });
      coordinates[index] = null;
    });
  });
  const segments = convertNullifiedPointsArrayToListOfFeatures(coordinates);
  const longestSegment = getLongestPointsSegment(segments);
  return longestSegment.geometry.coordinates.map(coord => ({ lat: coord[1], lng: coord[0] }));
};

export function Route(props) {
  const routeRef = useRef();
  const { selected, selectedLineString } = props;
  const routeLineString = props.Polyline?.lineString ?? "";
  const positions = useMemo(() => decodePolyline(routeLineString), [routeLineString]);
  const polylineProps = {
    color: selected ? colors.hemelsblauw : colors.gray600,
    positions,
  };

  const tooltipPolyline = useMemo(
    () => (selected ? null : getTooltipPolyline(routeLineString, selectedLineString)),
    [routeLineString, selectedLineString, selected],
  );

  useEffect(() => {
    if (selected && routeRef.current) {
      routeRef.current.leafletElement.bringToFront();
    }
  }, [selected]);

  const diff = (props.FreeFlowTime - props.selectedLength) / 60;
  const minutes = Math.sign(diff) * Math.round(Math.abs(diff));

  if (!props.Polyline) {
    return null;
  }

  return (
    <FeatureGroup ref={routeRef}>
      <Polyline
        {...polylineProps}
        opacity={1}
        weight={6}
        color={Color(polylineProps.color).darken(0.15)}
      />
      <Polyline {...polylineProps} opacity={1} weight={3} />
      {/*{!selected && (*/}
      {/*  <Polyline*/}
      {/*    positions={tooltipPolyline}*/}
      {/*    opacity={0.01}*/}
      {/*    weight={20}*/}
      {/*    color={"#000"}*/}
      {/*    onClick={props.onClick}*/}
      {/*  >*/}
      {/*    <Tooltip permanent interactive>*/}
      {/*      {Math.abs(minutes) === 0*/}
      {/*        ? "Vergelijkbare reistijd"*/}
      {/*        : `${Math.abs(minutes)} min ${minutes < 0 ? "korter" : "langer"}`}*/}
      {/*    </Tooltip>*/}
      {/*  </Polyline>*/}
      {/*)}*/}
      <Polyline {...polylineProps} opacity={0.01} weight={20} onClick={props.onClick} />
      {selected && (
        <React.Fragment>
          {props.obstructions
            .filter(x => x.polyline)
            .map(obstruction => (
              <Polyline
                key={`polyline_${obstruction.obstructionId}`}
                opacity={1}
                weight={6}
                color={
                  obstruction.obstructionType === 4
                    ? colors.signaalkleuren.rood
                    : colors.signaalkleuren.donkergeel
                }
                positions={decodePolyline(obstruction.polyline)}
              />
            ))}
          <MarkerClusterGroup iconCreateFunction={createClusterIcon} showCoverageOnHover={false}>
            {props.obstructions.map(obstruction => (
              <ObstructionMarker
                key={`marker_${obstruction.obstructionId}`}
                obstruction={obstruction}
              />
            ))}
          </MarkerClusterGroup>
        </React.Fragment>
      )}
      <RouteMarker
        letter="A"
        latitude={props.Polyline.StartLocation?.Latitude}
        longitude={props.Polyline.StartLocation?.Longitude}
      />
      <RouteMarker
        letter="B"
        latitude={props.Polyline.EindLocation?.Latitude}
        longitude={props.Polyline.EindLocation?.Longitude}
      />
    </FeatureGroup>
  );
}

Route.propTypes = {
  FreeFlowTime: PropTypes.number,
  Polyline: PropTypes.shape({
    lineString: PropTypes.string.isRequired,
    StartLocation: PropTypes.shape({
      Latitude: PropTypes.number.isRequired,
      Longitude: PropTypes.number.isRequired,
    }),
    EindLocation: PropTypes.shape({
      Latitude: PropTypes.number.isRequired,
      Longitude: PropTypes.number.isRequired,
    }),
  }),
};

function MapRoutes(props) {
  const dispatch = useDispatch();
  const { routes } = props;

  const { map } = useLeaflet();

  useEffect(() => {
    const points = routes.map(route => {
      const start = L.latLng(
        route.Polyline.StartLocation.Latitude,
        route.Polyline.StartLocation.Longitude,
      );
      const end = L.latLng(
        route.Polyline.EindLocation.Latitude,
        route.Polyline.EindLocation.Longitude,
      );
      return [start, end];
    });
    map.fitBounds(points);
  }, [routes, map]);

  const selectedRoute = routes.find(route => route.selected);

  return routes.map((route, index) => (
    <Route
      {...route}
      key={`${route.OriginDescription}_${route.DestinationDescription}_${index}`}
      allOtherRoutes={routes.filter(x => x.Polyline.lineString !== route.Polyline.lineString)}
      selectedLength={selectedRoute.FreeFlowTime}
      selectedLineString={selectedRoute.Polyline.lineString}
      onClick={() => dispatch(selectRoute(index))}
    />
  ));
}

export default MapRoutes;
