import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { loadObstruction, getObstruction } from "../components/Obstruction/redux";
import { useDeepEffect } from "../utils/hooks";
import ShareModal from "../components/ShareModal";
import { ReactComponent as ExternalLink } from "../common/icons/external-link.svg";
import formatPeriod from "../common/formatPeriod";
import Fact from "../components/Obstruction/Fact";
import ResultPanel from "../layouts/ResultPanel";
import Button from "../components/Button";
import ObstructionMarker from "../components/Map/ObstructionMarker";
import NotFoundMessage from "../components/NotFoundMessage";
import Minimap from "../components/Map/Minimap";
import { Helmet } from "react-helmet-async";
import RelatedNews from "../components/News/RelatedNews";
import formatDirection from "../common/formatDirection";
import { MatrixBoards } from "../components/MatrixBoard";
import convertDotSpaceToLineBreak from "../common/convertDotSpaceToLineBreak";

const Facts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
`;

const ExternalLinkIcon = styled(ExternalLink)`
  margin-left: 10px;
`;

const H2 = styled.h2`
  font-size: 18px;
  margin-bottom: 0;
`;

const ProjectButton = styled(Button).attrs(props => ({
  target: "_blank",
  as: "a",
  children: (
    <>
      Projectinformatie <ExternalLinkIcon />
    </>
  ),
}))`
  text-decoration: none;
`;

function ProjectButtonWrapper(props) {
  return (
    <div>
      <ProjectButton href={props.href} />
    </div>
  );
}

export function hindranceToDelay(hindrance) {
  const hindrances = {
    1: "< 10 min",
    2: "10 - 30 min",
    3: "> 30 min",
  };
  return hindrances[hindrance] || "";
}

export const obstructionTypeTitle = type => {
  const obstructionTypes = {
    1: "werkzaamheid",
    4: "file",
    7: "wegafsluiting",
    23: "brugopening",
  };
  return obstructionTypes[type] || "incident";
};

export function DelayInfo(props) {
  const { length, delay } = props;
  let km = length / 1000;
  km = km.toFixed(1);
  km = km.toString().replace(".", ",");

  if (!Boolean(delay || length)) {
    return null;
  }
  return (
    <Facts>
      {delay && <Fact status={props.delayStatus} number={`${delay} min`} label="Vertraging" />}
      {length && <Fact status={props.lengthStatus} number={`${km} km`} label="Lengte" />}
    </Facts>
  );
}

export function BodyText(props) {
  const obstructionTitle = props.obstructionTitle ? props.obstructionTitle : "Oorzaak";
  const periodTitle = props.periodTitle ? props.periodTitle : "Datum";
  const { signs } = props;

  return (
    <>
      {signs && (
        <>
          <H2>Rijstroken</H2>
          <MatrixBoards signs={signs} />
        </>
      )}
      {(props.obstructionText || props.cause) && (
        <>
          <H2>{obstructionTitle}</H2>
          {props.cause && <p>{convertDotSpaceToLineBreak(props.cause)}</p>}
          {props.obstructionText && <p>{convertDotSpaceToLineBreak(props.obstructionText)}</p>}
        </>
      )}
      {!!props.reasonText && (
        <>
          <H2>Waarom</H2>
          <p>{convertDotSpaceToLineBreak(props.reasonText)}</p>
        </>
      )}
      {(props.timeStart || props.timeEnd) && (
        <>
          <H2>{periodTitle}</H2>
          <p>{formatPeriod(props.timeStart, props.timeEnd, props.incident)}</p>
        </>
      )}
    </>
  );
}

function Obstruction(props) {
  const { id } = props.match.params;
  const dispatch = useDispatch();
  const result = useSelector(state => getObstruction(state, id));
  const { item = {}, loading, error, needsLoading } = result;
  const { obstructionType } = item;

  useDeepEffect(() => {
    if (needsLoading) {
      dispatch(loadObstruction(id));
    }
  }, [dispatch, id, needsLoading]);

  if (Object.keys(error).length) {
    return (
      <ResultPanel map={false}>
        <Helmet>
          <title>Pagina niet gevonden</title>
        </Helmet>
        <NotFoundMessage
          backButton
          title={`${obstructionTypeTitle(item.obstructionType)} kon niet worden gevonden`}
        />
      </ResultPanel>
    );
  }

  const { plStartLat, plStartLon, plEndLat, plEndLon } = item;
  const bounds = {};
  if (plStartLat && plStartLon && plEndLat && plEndLon) {
    bounds.start = [plStartLat, plStartLon];
    bounds.end = [plEndLat, plEndLon];
  }

  return (
    <ResultPanel
      {...props}
      title={item.title}
      subtitle={formatDirection(item)}
      subsubtitle={item.obstructionType === 3 ? item.cause : item.locationText}
      road={item.roadNumber}
      hmFrom={item.hmFrom}
      hmTo={item.hmTo}
      loading={loading}
      map={
        <Minimap
          latitude={item.latitude}
          longitude={item.longitude}
          marker={<ObstructionMarker clickable={false} obstruction={item} />}
          zoomLevel={item.obstructionType === 3 ? 2 : 7}
          polyline={item.polyline}
          bounds={bounds}
          obstruction={item}
        />
      }
    >
      <Helmet>
        <title>{`${item.title} op de ${item.roadNumber} (${formatDirection(item)}) ${
          item.locationText
        }`}</title>
      </Helmet>
      {obstructionType === 1 ? (
        <Facts>
          <Fact number={hindranceToDelay(item.hindrance)} label="Verwachte hinder" />
        </Facts>
      ) : (
        <DelayInfo {...item} />
      )}

      {obstructionType === 1 && (
        <React.Fragment>
          <BodyText obstructionTitle="Welke hinder" periodTitle="Periode" {...item} />
          {item.linkUrl && <ProjectButtonWrapper href={item.linkUrl} />}
        </React.Fragment>
      )}
      {obstructionType === 4 && <BodyText {...item} incident={true} periodTitle="Datum" />}
      {obstructionType === 7 && (
        <>
          <BodyText {...item} periodTitle="Datum" />
          {item.linkUrl && <ProjectButtonWrapper href={item.linkUrl} />}
        </>
      )}
      {obstructionType === 23 && <BodyText {...item} incident={true} />}
      {![1, 4, 7, 23].includes(item.obstructionType) && <BodyText {...item} incident={true} />}
      <RelatedNews news={item.related_news} title="Gerelateerd nieuws" />

      <ShareModal
        title={`Deel ${obstructionTypeTitle(item.obstructionType)}`}
        url={window.location.href}
      />
    </ResultPanel>
  );
}

export default withRouter(Obstruction);
