import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import L from "leaflet";
import MapLayout from "./MapLayout";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";
import { getTrafficMarkers } from "../Traffic/redux";
import { useShouldLoadTraffic } from "../Traffic/hooks";
import { getObstructionsOnRoad } from "../Road/redux";
import { getRouteSearchResults } from "../Route/redux";
import { getRoadworks } from "../Roadworks/redux";
import { getMapDisplay, getFilters } from "./redux";
import { useSelector } from "react-redux";
import ObstructionMarker from "./ObstructionMarker";
import CameraMarker from "./CameraMarker";
import MapRoutes from "./MapRoutes";
import { loadCameras, getCameras } from "../Camera/redux";
import colors from "../../common/colors";

// Amersfoort is used as the absolute center of The Netherlands.
const amersfoort = [52.15, 5.3833]; // Amersfoort

export const createClusterIcon = cluster => {
  let style = "yellow";
  const count = cluster.getChildCount();
  const markerColors = cluster.getAllChildMarkers().map(x => x.options.color);
  if (markerColors.includes(colors.signaalkleuren.rood)) {
    style = "red";
  } else if (markerColors.includes(colors.signaalkleuren.oranje)) {
    style = "orange";
  }
  const className = `markercluster markercluster--${style}`;

  return L.divIcon({
    html: `<div class="number">${count}</div>`,
    className,
    iconSize: L.point(32, 32),
  });
};

const createCameraClusterIcon = cluster => {
  const count = cluster.getChildCount();
  return L.divIcon({
    html: `<div class="camera">${count} <svg viewBox="0 0 15 9" xmlns="http://www.w3.org/2000/svg"><path d="M13.8 2.56l-1.387 1.316c0 .936-.163 1.814-.413 1.814V2.444a.466.466 0 0 0-.478-.453h-1.36L8.239.15A.519.519 0 0 0 7.873 0H3.75a.439.439 0 0 0-.45.427c0 .235.201.426.45.426h3.917l.383 1.138H2.578a.466.466 0 0 0-.478.453v.116H.896a.313.313 0 0 0-.318-.284H.322a.313.313 0 0 0-.318.284H0V4.551h.004a.313.313 0 0 0 .318.285h.256c.17 0 .306-.126.318-.284H2.1v3.245c0 .25.214.453.478.453h8.944A.466.466 0 0 0 12 7.797V6.543h.6l1.2.854H15V2.56h-1.2zm-6 4.552H3.6V4.836h4.2v2.276z" fill="#FFF" fill-rule="nonzero"/></svg></div>`,
    className: "markercluster markercluster--camera",
    iconSize: L.point(32, 32),
  });
};

function Map(props) {
  const dispatch = useDispatch();
  const routes = useSelector(getRouteSearchResults);
  const { items: markers, shouldLoad: shouldLoadTraffic } = useSelector(getTrafficMarkers);
  const { items: roadMarkers } = useSelector(getObstructionsOnRoad);
  const { items: roadworkMarkers } = useSelector(getRoadworks);
  const { items: cameras, shouldLoad: shouldLoadCams } = useSelector(getCameras);
  const display = useSelector(getMapDisplay);
  const activeFilters = useSelector(getFilters);
  const [camerasVisible, toggleCameras] = useState(false);

  useEffect(() => {
    if (activeFilters.includes("cameras") && shouldLoadCams) {
      dispatch(loadCameras());
    }
    toggleCameras(activeFilters.includes("cameras"));
  }, [activeFilters, shouldLoadCams, dispatch]);

  useShouldLoadTraffic(shouldLoadTraffic);

  const mapProps = {
    ...props,
    center: amersfoort,
    zoomLevel: 3,
  };

  let mapMarkers = markers;
  if (display === "road") {
    mapMarkers = roadMarkers;
  }
  if (display === "roadworks") {
    mapMarkers = roadworkMarkers;
  }

  return (
    <MapLayout {...mapProps}>
      {display === "route" && routes.length > 0 && <MapRoutes routes={routes} />}
      {["traffic", "road", "roadworks"].includes(display) && mapMarkers.length > 0 && (
        <MarkerClusterGroup iconCreateFunction={createClusterIcon} showCoverageOnHover={false}>
          {mapMarkers.map(marker => (
            <ObstructionMarker key={marker.obstructionId} obstruction={marker} />
          ))}
        </MarkerClusterGroup>
      )}
      {camerasVisible && cameras.length > 0 && (
        <MarkerClusterGroup
          iconCreateFunction={createCameraClusterIcon}
          showCoverageOnHover={false}
        >
          {cameras.map(marker => (
            <CameraMarker key={marker.id} {...marker} />
          ))}
        </MarkerClusterGroup>
      )}
    </MapLayout>
  );
}

export default Map;
