import React from "react";
import Highlighter from "react-highlight-words";

const HighlightedWord = ({ children }) => <strong>{children}</strong>;

function Highlight(props) {
  const { str = "", highlight } = props;
  return (
    <Highlighter
      highlightTag={HighlightedWord}
      searchWords={[highlight]}
      autoEscape={true}
      textToHighlight={str}
    />
  );
}

export default Highlight;
