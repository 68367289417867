import React from "react";
import { ReactComponent as Arrow } from "../common/icons/arrow-back.svg";
import { ReactComponent as Cross } from "../common/icons/close_filter.svg";
import { useLastLocation } from "react-router-last-location";
import { RoundButton } from "../components/Button";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { mq } from "../common/utils";

const Button = styled(RoundButton)`
  position: absolute;
  top: 16px;
  left: 16px;
  pointer-events: auto;
  ${mq("desktop")`
    left: auto;
    right: 16px;
  `}
  .arrow {
    ${mq("desktop")`
      display: none;
    `}
  }
  .cross {
    display: none;
    ${mq("desktop")`
      display: block;
    `}
  }
`;

function BackButton(props) {
  const lastLocation = useLastLocation();

  function navigate() {
    const link = props.link;
    if (link) {
      props.history.push(link);
    } else if (!lastLocation) {
      props.history.push("/");
    } else {
      props.history.goBack();
    }
  }

  return (
    <Button
      history={props.history}
      title="Terug"
      onClick={() => {
        navigate();
      }}
    >
      <Arrow className="arrow" />
      <Cross className="cross" />
    </Button>
  );
}

export default withRouter(BackButton);
