import React, { useEffect } from "react";
import styled from "styled-components";
import colors from "../common/colors";
import { useDispatch, useSelector } from "react-redux";
import { getDisclaimer, loadDisclaimer } from "./Disclaimer/redux";

const Container = styled.div`
  padding: 2rem 1rem;
  background: ${colors.white};
`;

export const Card = styled.div`
  border: 2px solid ${colors.hemelsblauw};
  border-radius: 5px;
  padding: 1rem;
  *:last-child {
    margin-bottom: 0;
  }
  p {
    font-size: 15px;
    line-height: 1.5;
    a {
      color: ${colors.hemelsblauw};
    }
  }
  ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    li {
      font-size: 15px;
      line-height: 1.5;
      display: flex;
      padding-left: 16px;
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: currentColor;
        display: inline-block;
        margin-right: 8px;
        list-style: none;
        transform: translateY(9px);
        flex: 0 0 auto;
      }
    }
  }
  h2 {
    margin-bottom: 0.5rem;
    font-size: 15px;
  }
`;

function Disclaimer() {
  const result = useSelector(getDisclaimer);
  const { loading, disclaimer, shouldLoad } = result;
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldLoad) {
      dispatch(loadDisclaimer());
    }
  }, [dispatch, shouldLoad]);

  return (
    <Container>
      {loading ? (
        <Card>Bezig met laden...</Card>
      ) : (
        <Card dangerouslySetInnerHTML={{ __html: disclaimer }} />
      )}
    </Container>
  );
}

export default Disclaimer;
