import styled, { css } from "styled-components";
import colors from "../common/colors";
import { mq } from "../common/utils";

const Badge = styled.span.withConfig({
  shouldForwardProp: (prop) => !['color', 'road'].includes(prop),
})`
  color: ${colors.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 0 4px;
  height: 20px;
  font-size: 14px;
  min-width: 48px;
  font-weight: 700;
  & + & {
    margin-left: 4px;
  }
  ${props =>
    props.color === "red" &&
    css`
      background: ${colors.signaalkleuren.rood};
    `}
  ${props =>
    props.color === "orange" &&
    css`
      background: ${colors.signaalkleuren.lichtoranje};
      color: ${colors.gray900};
    `}
  ${props =>
    props.color === "yellow" &&
    css`
      background: ${colors.signaalkleuren.donkergeel};
      color: ${colors.gray900};
    `}
  ${props =>
    props.color === "green" &&
    css`
      background: ${colors.signaalkleuren.groen};
    `}
  ${props =>
    props.color === "white" &&
    css`
      background: ${colors.white};
      color: ${colors.gray900};
      border: 1px solid ${colors.gray900};
    `}

`;

export const DelayBadge = styled(Badge)`
  ${mq("tablet")`
    font-size: 12px;
    height: 16px;
    min-width: 35px;
  `}
`;

export default Badge;
