const actionTypes = {
  LOAD: "categories/load",
  LOAD_SUCCESS: "categories/load_success",
  LOAD_FAILED: "categories/load_failed",
};

const initialState = {
  loading: false,
  loaded: false,
  categories: [],
  error: {},
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        error: {},
        loading: true,
        loaded: false,
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        error: {},
        loading: false,
        loaded: true,
        categories: action.payload.items,
      };
    case actionTypes.LOAD_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export const getCategories = state => {
  const { categories: { loading, loaded, categories, error = {} } = {} } = state;

  const shouldLoad = !loading && !loaded && Object.keys(error).length === 0;
  const result = {
    categories,
    loading,
    loaded,
    shouldLoad,
    error,
  };
  return result;
};

export const loadCategories = () => ({
  types: [actionTypes.LOAD, actionTypes.LOAD_SUCCESS, actionTypes.LOAD_FAILED],
  method: "GET",
  url: "/api/v2/categories/",
  payload: {
    fields: [
      "_",
      "id",
      "title",
      "slug",
      "short_description",
      "seo_title",
      "search_description",
      "feed_image",
      "menu_icon",
      "show_in_menu",
    ].join(","),
  },
});
