import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "../Autocomplete";
import { getRoads, loadRoads } from "./redux";

export const getItems = (dataset, query) => {
  if (!query) {
    return [];
  }
  // return max 8 results.
  const results = Array.from(new Set(dataset))
    .filter(road => road.toLowerCase().indexOf(query.toLowerCase()) >= 0)
    .slice(0, 8);
  return results;
};

function RoadField(props) {
  const dispatch = useDispatch();
  // use roads from json file as fallback.
  const { shouldLoad, roads } = useSelector(getRoads);

  useEffect(() => {
    if (shouldLoad) {
      dispatch(loadRoads());
    }
  }, [dispatch, shouldLoad]);

  return <Autocomplete {...props} minChars={1} dataSource={query => getItems(roads, query)} />;
}

export default RoadField;
